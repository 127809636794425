<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen] = "true"></ngx-spinner>

<div class="container-fluid">
  <app-pagetitle title="Product Setup" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">
    <div class="row">
      <div class="col-lg-12">
        <div class="card o">
          <div class="card-body o">
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="ITEM_NAME">Item Name</label>
                  <input class="form-control" formControlName="ITEM_NAME" id="ITEM_NAME"
                    [ngClass]="{'is-invalid': submit && form.ITEM_NAME.errors}">
                  <div *ngIf="submit && form.ITEM_NAME.errors" class="invalid-feedback">
                    Please provide Item Name
                  </div>
                </div>
              </div>
             
              <div class="col-md-2">
                <div class="form-group">
                  <label for="IT_CODE">Item Code</label>
                  <input class="form-control" readonly disabled formControlName="IT_CODE" id="IT_CODE"
                    [ngClass]="{'is-invalid': submit && form.IT_CODE.errors}">
                  <div *ngIf="submit && form.IT_CODE.errors" class="invalid-feedback">
                    Please provide Item Code
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="PRODUCT_CODE">Product Assign Code</label>
                  <input  class="form-control" formControlName="PRODUCT_CODE" id="PRODUCT_CODE"
                    [ngClass]="{'is-invalid': submit && form.PRODUCT_CODE.errors}">
                  <div *ngIf="submit && form.PRODUCT_CODE.errors" class="invalid-feedback">
                    Please provide Product Assign Code
                  </div>
                </div>
              </div>
              <hr>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="Item_Alias">Item Alias</label>
                  <input class="form-control" formControlName="Item_Alias" id="Item_Alias"
                    [ngClass]="{'is-invalid': submit && form.Item_Alias.errors}">
                  <div *ngIf="submit && form.Item_Alias.errors" class="invalid-feedback">
                    Please provide Item Alias
                  </div>
                </div>
              </div>
             
              <div class="col-md-4">
                <div class="form-group">
                  <label for="SHORT_NAME">Short Name</label>
                  <input  class="form-control" formControlName="SHORT_NAME" id="SHORT_NAME"
                    [ngClass]="{'is-invalid': submit && form.SHORT_NAME.errors}">
                  <div *ngIf="submit && form.SHORT_NAME.errors" class="invalid-feedback">
                    Please provide Short Name
                  </div>
                </div>
              </div>
            
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="form-group">
                  <label for="GR_CODE">Item Group</label>
                  <ng-select formControlName="GR_CODE" name="GR_CODE" id="GR_CODE"
                  [items]="GROUP_NAMEList"  bindLabel="Name" bindValue="Code"[(ngModel)]="GROUP_NAMECode">
                  </ng-select>
                  <div class="invalidValidate" *ngIf="checkGroupType()">
                    Please provide Group
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="size_name">Size</label>
                  <input class="form-control" type="number" formControlName="size_name" id="size_name"
                    [ngClass]="{'is-invalid': submit && form.size_name.errors}">
                  <div *ngIf="submit && form.size_name.errors" class="invalid-feedback">
                    Please provide Opening Size
                  </div>
               </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="imOpnStk">Opening Stock</label>
                  <input class="form-control" type="number" formControlName="imOpnStk" id="imOpnStk"
                    [ngClass]="{'is-invalid': submit && form.imOpnStk.errors}">
                  <div *ngIf="submit && form.imOpnStk.errors" class="invalid-feedback">
                    Please provide Opening Stock
                  </div>
                </div>
              </div>
              

            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="UNIT">Unit</label>
                  <ng-select formControlName="UNIT" name="UNIT" id="UNIT"
                  [items]="UNITList"  bindLabel="Name" bindValue="Code"[(ngModel)]="UNITCode">
                  </ng-select>
                  <div class="invalidValidate" *ngIf="checkUnitType()">
                    Please provide Unit
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="Weight">Weight</label>
                  <input class="form-control" type="number" formControlName="Weight" id="Weight"
                    [ngClass]="{'is-invalid': submit && form.Weight.errors}">
                  <div *ngIf="submit && form.Weight.errors" class="invalid-feedback">
                    Please provide Weight
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="PackingPerBox">Packing Per Box</label>
                  <input class="form-control" type="number" formControlName="PackingPerBox" id="PackingPerBox"
                    [ngClass]="{'is-invalid': submit && form.PackingPerBox.errors}">
                  <div *ngIf="submit && form.PackingPerBox.errors" class="invalid-feedback">
                    Please provide Packing Per Box
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="ImGstCode">Tax Category</label>
                  <ng-select formControlName="ImGstCode" name="ImGstCode" id="ImGstCode" 
                  [items]="ImGstCodeList"
                    bindLabel="Name" bindValue="Code" ></ng-select>
                </div>
              </div>
              
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label for="ModelCode">MODEL Name</label>
                  <ng-select formControlName="ModelCode" name="ModelCode" id="ModelCode" [items]="MODEL_NameList"
                    bindLabel="Name" bindValue="Code" ></ng-select>
                  
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="Pur_Rate">Purchase Rate</label>
                  <input class="form-control" type="number" formControlName="Pur_Rate" id="Pur_Rate"
                    [ngClass]="{'is-invalid': submit && form.Pur_Rate.errors}">
                  <div *ngIf="submit && form.Pur_Rate.errors" class="invalid-feedback">
                    Please provide Purchase Rate
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="Sale_Rate">Sale Rate</label>
                  <input class="form-control" type="number" formControlName="Sale_Rate" id="Sale_Rate"
                    [ngClass]="{'is-invalid': submit && form.Sale_Rate.errors}">
                </div>
              </div>

             
              <div class="col-md-4">
                <div class="form-group">
                  <label for="imHSN_Code">HSN/SAC Code for GST</label>
                  <input class="form-control" formControlName="imHSN_Code" id="imHSN_Code"
                    [ngClass]="{'is-invalid': submit && form.imHSN_Code.errors}">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="MinOrder">Min Order</label>
                  <input class="form-control" type="number" formControlName="MinOrder" id="MinOrder"
                    [ngClass]="{'is-invalid': submit && form.MinOrder.errors}">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="MaxOrder">Max Order</label>
                  <input class="form-control" type="number" formControlName="MaxOrder" id="MaxOrder"
                    [ngClass]="{'is-invalid': submit && form.MaxOrder.errors}">
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="REMARKS">Remarks</label>
                  <input class="form-control" formControlName="REMARKS" id="REMARKS"
                    [ngClass]="{'is-invalid': submit && form.REMARKS.errors}">
                
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="BarCodeNo">Bar Code</label>
                  <input class="form-control" type="number" formControlName="BarCodeNo" id="BarCodeNo">
                  </div>
              </div>
              <!-- <div class="col-md-2">
                <div class="form-group">
                  <label for="Bar_Code_Rate">Bar Code Rate</label>
                  <input class="form-control" type="number" formControlName="Bar_Code_Rate" id="Bar_Code_Rate"
                    [ngClass]="{'is-invalid': submit && form.Bar_Code_Rate.errors}">
                  <div *ngIf="submit && form.Bar_Code_Rate.errors" class="invalid-feedback">
                    Please provide Bar Code Rate
                  </div>
                </div>
              </div> -->
            </div>
            <div class="row">
              <div class="col-md-12 d-flex justify-content-end">
                <button type="submit" class="btn btn-primary tblebtn w-md">
                  Save
                </button>;
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>   
</div>