import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './accountMst.mustmatch';
import { icons } from './data';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThisReceiver } from '@angular/compiler';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';

@Component({
  selector: 'app-accountMst',
  templateUrl: './accountMst.component.html',
  styleUrls: ['./accountMst.component.scss']
})

/**
 * Forms Validation component
 */
export class accountMstComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form
  searchform: FormGroup; // bootstrap validation form
  searchselectedTypeID: string;
  ActiveTagCode: string;
  selectedTypeID: string;
  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private session:SessionCheck,
    private _router: Router
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;
  tableData: any[];
  table: any[];
  worklist: any[];
  searchlist: any[];
  work: any[];
  pager: any = {};
  searchString: string;
  // Form submition
  submit: boolean;
  formsubmit: boolean;
  SearchItems: any = [];
  ID: any;
  EntryID:any;
  accountTypes: any[];
  accountTypesSearch: any[];
  ActiveTagList: any[];
  PrintTypeList:any[];
  PrintTypeCode: any[];
  GSTNatureList: any[];
  GSTNatureCode: any[];
  SaleTaxList:any[];
  SaleTaxCode: any[];
  CityList: any[];
  CityCode: any[];
  CountryList: any[];
  Countrycode: any[];
  StateList: any[];
  StateCode: any[];
  DebitList: any[];
  DebitCode: any[];
  SaleCategoryList: any[];
  SaleCategoryCode: any[];

  ActiveTagListSearch: any[];
  ActiveTagCodeSearch: string;
  maxAccCode: string;
  roleName:string;
  ngOnInit() {
   
    this.accountTypesSearch =
      [
        { TypeCode: '1', Name: 'All' },
        { TypeCode: '2', Name: 'Customer' },
        { TypeCode: '3', Name: 'Supplier' },
        { TypeCode: '4', Name: 'Other' }
      ];
    this.ActiveTagList =
      [
        { Code: 'Y', Name: 'Y' },
        { Code: 'N', Name: 'N' }
      ];
      this.PrintTypeList =
      [
        { Code: '1', Name: '1' },
        { Code: '2', Name: '2' },
        { Code: '3', Name: '3' },
        { Code: '4', Name: '4' },
        { Code: '5', Name: '5' },
        { Code: '6', Name: '6' },
        { Code: '7', Name: '7' },
        { Code: '8', Name: '8' }
      ];
     
      this.SaleTaxList =
      [
        { Code: 'Single', Name: 'Single' },
        { Code: 'Multiple', Name: 'Multiple' }
      ];
      
      this.DebitList =
      [
        { Code: 'Dr', Name: 'Dr' },
        { Code: 'Cr', Name: 'Cr' }
      ];
      this.GSTNatureList =
      [
        { Code: 'Input', Name: 'Input' },
        { Code: 'Output', Name: 'Output' },
        { Code: 'CGST', Name: 'CGST' },
        { Code: 'SGST', Name: 'SGST' },
        { Code: 'IGST', Name: 'IGST' }
      ];
    this.ActiveTagListSearch =
      [
        { Code: 'A', Name: 'ALL' },
        { Code: 'Y', Name: 'Y' },
        { Code: 'N', Name: 'N' }
      ];

  this.getDropdownList();
    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Account', active: true }];
    /**
     * Bootstrap validation form data
     */
    this.searchform = this.formBuilder.group({
      AccountType: [''],
      ActiveTagSearch: [''],

    });

    this.validationform = this.formBuilder.group({
      PARTY_NAME: ['', [Validators.required]],
      AC_CODE: [''],
      amGroupCode2: [''],
      ActiveTag: [''],
      Address1: [''],
      Station: [''],
      Pin_Code: [''],
      ST_CODE: [''],
      AmCountryCode: [''],
      Contact_Person: [''],
      Contact_Mobile: [''],
      Email: ['', [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      Mobile_No : [''],
      SaleMargin: [''],
      amCreditDays:[''],
      amDueDay:[''],
      group_code:[''],
      
      AadharCardNo: [''],
      GSTINO: [''],
      PAN_NO: [''],
      T_CODE:[''],
      AccSaleCategory:[''],
      AccSaleTaxSys:[''],
      AmGstNature:[''],
      opening_bal:[''],
      opn_nature:[''],
      Print_Type:[''],
      Private_Mark:[''],
      amDisPer:[''],
      Rate_List:[''],
      SaleCategoryId:['']

    });

this.ActiveTagCode='Y';
this.validationform.patchValue(
  {
    AmCountryCode:'001',
    opn_nature:'Dr'
  }
);
    this.searchselectedTypeID = "1";
    this.ActiveTagCodeSearch = 'A';
   // this.getAccountList();
   this.EntryID = 0;
   this.EntryID = this.route.snapshot.queryParamMap.get('Id') ? this.route.snapshot.queryParamMap.get('Id') : '';
   this.ID = 0;
    this.roleName=this.session.getSessionStorage('Role');
    if(+this.EntryID>0)
    {
      this.editRecord(this.EntryID);
    }
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }

  /**
   * Bootsrap validation form submit method
   */
  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      try {
        this.spinner.show();
        let groupcodelist=this.accountTypes.filter(r=> r.GroupCode2==this.selectedTypeID);
        if(groupcodelist.length>0)
        {
          this.validationform.patchValue(
            {
              group_code : groupcodelist[0].GROUP_CODE
            }
          )
          
        }
        var JsonMaster = (this.validationform.value)
        JsonMaster.S_NO=this.EntryID;
        JsonMaster.IsActive=this.ActiveTagCode;
        JsonMaster.VType = '2001';
        this.setup.AddMasterlist(JSON.stringify(JsonMaster))
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data.Status == 1) {
            this.modalService.dismissAll();
            this.toaster.success(data.ResponseMessage);
            this.getAccountList();
          }
          else {
            this.toaster.error(data.ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }


    }
  }
  formSubmit() {
    this.formsubmit = true;

  }
  checkSupplierType(): boolean {
    if (this.submit && !this.selectedTypeID) {
      return true;
    }
    else {
      return false;
    }
  }
  getAccountList() {
    this._router.navigate(['/master/account'], { });
  }

  editRecord(ID) {
    try {
      this.setup.GetRecordInfo(ID, 'sp_GetRecord','Account')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.fillEditRecordData(data);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  fillEditRecordData(data) {
    data.forEach(element => {
      var keys = Object.keys(element);
      var values = Object.values(element);
      const JsonMaster = (this.validationform.value);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { }
      })
    });

    //this.selectedTypeID = data[0].group_code;
    this.ID = data[0].id;
    this.ActiveTagCode = data[0].ActiveTag;
    this.roleName=data[0].RoleName;
  }
  setPage(page: number) {
    this.pager = this.getPager(this.worklist.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    //  this.pager = this.getPager(this.worklist.length, page);
    this.work = this.worklist.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  searchExistingRecords(event: any) {
    const val = event.target.value.toLowerCase();
    // const columnsName =Object.keys(this.worklist[0]);
    //const val = event.target.value;
    if (val != '') {
      const list = this.worklist.filter(function (d) {
        let ColumnsData = '';
        const columnsName = ['AccCode', 'AccName', 'AccTypeName', 'City', 'State','Country', 'MobileNo', 'CreatedDate','UserName', 'IsActive'];
        // const columnsName =Object.keys(this.worklist);
        columnsName.forEach(col => {
          ColumnsData += ' ' + d[col];
        });
        return ColumnsData.toLowerCase().indexOf(val) !== -1 || !val;
      });
      // Whenever the filter changes, always go back to the first page.
      //this.tableData = list;
      this.table = list;
      this.work = list;
      this.setPageSearch(1);
    } else {
      this.table = this.worklist;
      this.setPageSearch(1);
    }
  }
  setPageSearch(page: number) {
    this.pager = this.getPager(this.table.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    this.work = this.table.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  openModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'xl' });
  }
  resetAndopenModal(largeDataModal: any) {

    // this.validationform.reset();
    // this.ID = 0;
    // this.ActiveTagCode = 'Y';
    // this.openModal(largeDataModal);
    // this.validationform.patchValue(
    //   {
    //     AccCode: this.maxAccCode
    //   }
    // );

  }
  validSearchSubmit() {
    this.getAccountList();
  }
  getDropdownList()
  {
    this.CountryList=[];
    this.StateList=[];
   this.accountTypes=[];
   this.CityList=[];
    this.setup.GetRegularworkList('dbo.sp_GetMasterData',"AccountMst")
  .subscribe({
    next(list) {
      fetchData(list);
    }
  });
const fetchData = (data: any) => {
  this.spinner.hide();
  if (data.ResultSets.length > 0) {
    
 this.CountryList=data.ResultSets[0];
 this.StateList=data.ResultSets[1];
 this.CityList=data.ResultSets[2];
 this.accountTypes=data.ResultSets[3];
 this.SaleCategoryList=data.ResultSets[4];
  }
};
} catch (error) {
this.spinner.hide();
console.log(error);
this.toaster.error(error);
}

}
