import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './SalePackingEntry.mustmatch';
//import { icons } from '../data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-validation',
  templateUrl: './SalePackingEntry.component.html',
  styleUrls: ['./SalePackingEntry.component.scss']
})

/**
 * Forms Validation component
 */
export class SalePackingEntryComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form
  SupplierList: any = [];
  selectedSupplierID: string;
  selectedAccID2:string;
  selectedAccID: string;
  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router: Router
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;
  SearchItems: any = [];
  searchSize: string;
  selectedItemArray: any = [];
  ProductArray: any = [];
  SearchPanelVisible: boolean;
  TotalQty: any;
  TotalAmount: any;
  EntryID: any;
  editMode: boolean;
  grandTotal: any = [];
  productTotal: any = [];
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Packing List', active: true }];
    this.EntryID = 0;
    this.EntryID = this.route.snapshot.queryParamMap.get('Id') ? this.route.snapshot.queryParamMap.get('Id') : '';
    this.editMode = false;
    /**
     * Bootstrap validation form data
     */
    this.validationform = this.formBuilder.group({
      PackingNo: ['', [Validators.required]],
      PackingDate: ['', [Validators.required]],
      AccCode : ['',[Validators.required]],
      BLNo : [''],
      ArrivalDate : ['']
    });
    this.getAccountList();

    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;
    if (+this.EntryID > 0) {
      this.editRecord(this.EntryID);
    }
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }

  /**
   * Bootsrap validation form submit method
   */
  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      if (this.ProductArray.length == 0) {
        this.toaster.error('Please check Product List');
        return;
      }
      try {
        this.SearchPanelVisible = false;
        this.SearchItems = [];
        this.spinner.show();
        const JsonMaster = (this.validationform.value)
        this.setup.addRecord(this.EntryID, JSON.stringify(JsonMaster), JSON.stringify(this.ProductArray), 'sp_addSalePackingData')
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data[0].Status == 1) {
            this.toaster.success(data[0].ResponseMessage);
            this.router.navigate(['/form/salePackingList']);
          }
          else {
            this.toaster.error(data[0].ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }


    }
  }

  /**
   * returns tooltip validation form
   */

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;

  }


  checkSupplier(): boolean {
    if (this.submit && !this.selectedSupplierID) {
      return true;
    }
    else {
      return false;
    }
  }
  addProducts() {
    var arr_prd = this.SearchItems.filter(row => row.Qty > 0);
    if (this.ProductArray.length > 0) {
      for (let i = 0; i <= arr_prd.length-1; i++) {
        var arr_validate = this.ProductArray.filter(row => row.SLInvID == arr_prd[i].SLInvID &&
          row.PrdId == arr_prd[i].PrdId);
        if (arr_validate.length > 0) {
          this.toaster.error('Duplicate Product Entry,Please Verify');
          return;
        }
      }
    }
    arr_prd.forEach(element => {
      element.Volume = (element.Qty / element.PrdContainerCapacity).toFixed(2);
      this.ProductArray.push(element);
    });
    this.SearchItems = [];
    this.searchSize = '';
    this.SearchPanelVisible = false;
    this.calculateTotal();
  }
  calculateTotal() {
    this.grandTotal = [];
    this.grandTotal.InvQty = 0;
    this.grandTotal.PenQty = 0;
    this.grandTotal.Qty = 0;
    this.grandTotal.Volume = 0;
    this.ProductArray.forEach(element => {
      this.grandTotal.InvQty += +element.InvQty;
      this.grandTotal.PenQty += +element.PenQty;
      this.grandTotal.Qty += +element.Qty;
      this.grandTotal.Volume += +element.Volume;
    });
    this.grandTotal.Volume = this.grandTotal.Volume.toFixed(2);
  }
  searchPrdFromDB(item) {
    try {
      if (!this.checkSupplier2()) {
        this.toaster.error('Please select Customer to add products');
        return;
      }
      this.selectedAccID = this.selectedAccID2;
      this.SearchPanelVisible = false;
      this.SearchItems = [];
      this.spinner.show();
      const searchJson = {
        PrdName: this.searchSize,
        AcId: this.selectedAccID2,
        
      }
      this.setup.getSearchList(JSON.stringify(searchJson), 'sp_GetSalePackingPrdSearch')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.SearchItems = data;
          this.SearchPanelVisible = true;

          this.productTotal.InvQty = 0;
          this.productTotal.PackQty = 0;
          this.productTotal.Qty = 0;
          this.productTotal.PenQty = 0;
          this.productTotal.LoadQty = 0;
          this.productTotal.Volume = 0;
          this.SearchItems.forEach(element => {
            this.productTotal.InvQty += +element.InvQty;
            this.productTotal.PackQty += +element.PackQty;
            this.productTotal.Qty += +element.Qty;
            this.productTotal.PenQty += +element.PenQty;
            this.productTotal.LoadQty += +element.LoadQty;
            this.productTotal.Volume += +element.Volume;
          });
          this.productTotal.Volume = this.productTotal.Volume.toFixed(2);

        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  DeletePrdItem(item, iRowIndex) {
    this.ProductArray.splice(iRowIndex, 1);
    this.calculateTotal();
  }
  editRecord(EntryID) {
    try {
      this.setup.GetRecordInfo(this.EntryID, 'dbo.sp_GetSalePackingRecord')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.fillEditRecordData(data);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  getAccountList() {
    try {
      this.setup.GetAccountList('Customer')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.SupplierList = data.ResultSets[0];
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  fillEditRecordData(data) {
    data.forEach(element => {
      var keys = Object.keys(element);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { }
      })
    }); this.editMode = true;
    this.selectedAccID = data[0].AcId;
    this.selectedAccID2 = data[0].AcId;
    this.ProductArray = [];
    data.forEach(element => {
      this.ProductArray.push(
        element
      )
    });
    this.calculateTotal();
  }
  checkAccDisabled() {
    if (this.ProductArray != undefined && this.ProductArray.length > 0) {
      return true;
    }
    return false;
  }
  checkSupplier2(): boolean {
    if (this.selectedAccID2 == undefined || !this.selectedAccID2) {
      return false;
    }
    else {
      return true;
    }
  }
}
