import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './PurchaseCommercialInvoice.mustmatch';
//import { icons } from '../data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-validation',
  templateUrl: './PurchaseCommercialInvoice.component.html',
  styleUrls: ['./PurchaseCommercialInvoice.component.scss']
})

/**
 * Forms Validation component
 */
export class PurchaseCommercialInvoiceComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form
  SupplierList: any = [];
  selectedSupplierID: string;
  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router: Router
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;
  SearchItems: any = [];
  searchSize: string;
  selectedItemArray: any = [];
  ProductArray: any = [];
  SearchPanelVisible: boolean;
  TotalQty: any;
  TotalAmount: any;
  EntryID: any;
  editMode: boolean;
  grandTotal:any=[];
  productTotal:any=[];
  

  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Packing List', active: true }];
    this.EntryID = 0;
    this.EntryID = this.route.snapshot.queryParamMap.get('Id') ? this.route.snapshot.queryParamMap.get('Id') : '';
    this.editMode = false;
    /**
     * Bootstrap validation form data
     */
    this.validationform = this.formBuilder.group({
      PackingNo: ['', [Validators.required]],
      PackingDate: ['', [Validators.required]],
      BLNo: [''],
      ArrivalDate: [''],
      Freight:['0']
    });
    //this.getAccountList();

    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;
    if (+this.EntryID > 0) {
      this.editRecord(this.EntryID);
    }
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }

  /**
   * Bootsrap validation form submit method
   */
  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      if (this.ProductArray.length == 0) {
        this.toaster.error('Please check Product List');
        return;
      }
      try {
        this.SearchPanelVisible = false;
        this.SearchItems = [];
        this.spinner.show();
        const JsonMaster = (this.validationform.value)
        this.setup.addRecord(this.EntryID, JSON.stringify(JsonMaster), JSON.stringify(this.ProductArray),'sp_addPurInvoiceData')
          .subscribe({  
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if(data[0].Status==1){
          this.toaster.success(data[0].ResponseMessage);
          this.router.navigate(['/form/commercialInvoiceList']);
        }
        else
        {
          this.toaster.error(data[0].ResponseMessage);
        }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }


    }
  }

  formSubmit() {
    this.formsubmit = true;
  }
  checkSupplier(): boolean {
    if (this.submit && !this.selectedSupplierID) {
      return true;
    }
    else {
      return false;
    }
  }
  addProducts() {
    var arr_prd = this.SearchItems.filter(row => row.LoadQty > 0);
    arr_prd.forEach(element => {
     element.Volume= (element.LoadQty/element.PrdContainerCapacity).toFixed(2);
      this.ProductArray.push(element);
          });
    this.SearchItems = [];
    this.searchSize = '';
    this.SearchPanelVisible = false;

    this.calculateTotal();
  }
  calculateTotal() {
    this.grandTotal=[];
    this.grandTotal.POQty = 0;  
    this.grandTotal.ReceivedQty = 0;  
    this.grandTotal.ShippedQty = 0;  
    this.grandTotal.PenQty = 0;  
    this.grandTotal.LoadQty = 0;  
    this.grandTotal.Volume = 0;  
    this.ProductArray.forEach(element => {
        this.grandTotal.POQty += +element.POQty;  
        this.grandTotal.ReceivedQty += +element.ReceivedQty;  
        this.grandTotal.ShippedQty += +element.ShippedQty;  
        this.grandTotal.PenQty += +element.PenQty;  
        this.grandTotal.LoadQty += +element.LoadQty;  
        this.grandTotal.Volume += +element.Volume;  
    });
    this.grandTotal.Volume = this.grandTotal.Volume.toFixed(2);  
  }
  searchPrdFromDB(item) {
    try {
      this.SearchPanelVisible = false;
      this.SearchItems = [];
      this.spinner.show();
      this.setup.getSearchList(this.searchSize,'sp_GetPurInvoicePrdSearch')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.SearchItems = data;
          this.SearchPanelVisible = true;
          
          this.productTotal.POQty = 0;  
          this.productTotal.ReceivedQty = 0;  
          this.productTotal.ShippedQty = 0;  
          this.productTotal.PenQty = 0;  
          this.productTotal.LoadQty = 0;  
          this.productTotal.Volume = 0;  
          this.SearchItems.forEach(element => {
              this.productTotal.POQty += +element.POQty;  
              this.productTotal.ReceivedQty += +element.ReceivedQty;  
              this.productTotal.ShippedQty += +element.ShippedQty;  
              this.productTotal.PenQty += +element.PenQty;  
              this.productTotal.LoadQty += +element.LoadQty;  
          });
      

        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  DeletePrdItem(item, iRowIndex) {
    this.ProductArray.splice(iRowIndex,1);
    this.calculateTotal();
  }
  editRecord(EntryID) {
    try {
      this.setup.GetRecordInfo(this.EntryID,'dbo.sp_GetPurInvoiceRecord')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.fillEditRecordData(data);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  getAccountList() {
    try {
      this.setup.GetAccountList()
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.SupplierList = data.ResultSets[0];
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  fillEditRecordData(data) {
    data.forEach(element => {
      var keys = Object.keys(element);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { }
      })
    });    this.editMode = true;
    this.selectedSupplierID = data[0].AccCode;
    this.ProductArray = [];
    data.forEach(element => {
      this.ProductArray.push(
        element
      )
    });
    this.calculateTotal();
  }
}
