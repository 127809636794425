import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountGroupMstListComponent } from './AccountGroupMstList/AccountGroupMstList.component';
import { accountMstComponent } from './accountMst/accountMst.component';
import { accountMstListComponent } from './accountMstList/accountMstList.component';
import { productMstComponent } from './productMst/productMst.component';
import { productMstListComponent } from './productMstList/productMstList.component';
import { TaxHeadCreationComponent } from './TaxHeadCreation/TaxHeadCreation.component';
import { TaxHeadCreationListComponent } from './taxHeadCreationList/TaxHeadCreationList.component';
import { TaxHeadRelationMstListComponent } from './TaxHeadRelationMstList/TaxHeadRelationMstList.component';
 
const routes: Routes = [
    {
        path: 'account',
        component: accountMstListComponent
    },
    {
        path: 'accountSetup',
        component: accountMstComponent
    },
    {
        path: 'accountGroup',
        component: AccountGroupMstListComponent
    },
    {
        path: 'product',
        component: productMstListComponent
    },
    {
        path: 'productSetup',
        component: productMstComponent
    },
    {
        path: 'taxRelation',
        component: TaxHeadRelationMstListComponent
    },
    {
        path: 'taxList',
        component: TaxHeadCreationListComponent
    },
    {
        path: 'taxSetup',
        component: TaxHeadCreationComponent
    },
    
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MasterFormRoutingModule { }
