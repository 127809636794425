<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen] = "true"></ngx-spinner>

<div class="container-fluid">
  <app-pagetitle title="Account Setup" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Purchase Order List</h4> -->
          <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">
            <div class="row">
              <div class="col-lg-12">
                <div class="card o">
                  <div class="card-body o">
                    <div class="row">
                      <div class="col-md-8">
                        <div class="form-group">
                          <label for="PARTY_NAME">Account Name</label>
                          <input class="form-control" formControlName="PARTY_NAME" id="PARTY_NAME"
                            [ngClass]="{'is-invalid': submit && form.PARTY_NAME.errors}">
                          <div *ngIf="submit && form.PARTY_NAME.errors" class="invalid-feedback">
                            Please provide Account Name
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="amGroupCode2">Account Type</label>
                          <ng-select formControlName="amGroupCode2" name="amGroupCode2" id="amGroupCode2"
                          [items]="accountTypes"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="selectedTypeID"></ng-select>
                        <div class="invalidValidate" *ngIf="checkSupplierType()">
                          Please provide Account Type
                        </div>
      
                        </div>
                      </div>
                      <!-- <div class="col-md-2">
                        <div class="form-group">
                          <label for="AccAsgnCode">Account Code</label>
                          <input class="form-control" formControlName="AccAsgnCode" 
                          id="AccAsgnCode"
                            [ngClass]="{'is-invalid': submit && form.AccAsgnCode.errors}">
                          <div *ngIf="submit && form.AccAsgnCode.errors" class="invalid-feedback">
                            Please provide Account Code
                          </div>
                        </div>
                      </div> -->
                      <div class="col-md-1 ">
                        <div class="form-group">
                          <label for="ActiveTag">Active</label>
                          <ng-select formControlName="ActiveTag" name="ActiveTag" id="ActiveTag"
                          [items]="ActiveTagList"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="ActiveTagCode"></ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="Address1">Address</label>
                          <input class="form-control" formControlName="Address1" id="Address1"
                            [ngClass]="{'is-invalid': submit && form.Address1.errors}">
                          <div *ngIf="submit && form.Address1.errors" class="invalid-feedback">
                            Please provide Address
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="Station">City</label>
                          <ng-select formControlName="Station" name="Station" id="Station"
                          [items]="CityList"  bindLabel="Name" bindValue="Code">
                          </ng-select>
                        </div>
                      </div>
      
                      
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="Pin_Code">Pin Code</label>
                          <input class="form-control" formControlName="Pin_Code" id="Pin_Code"
                            [ngClass]="{'is-invalid': submit && form.Pin_Code.errors}">
                          <div *ngIf="submit && form.Pin_Code.errors" class="invalid-feedback">
                            Please provide PinCode
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="State">State</label>
                          <ng-select formControlName="ST_CODE" name="ST_CODE" id="ST_CODE"
                          [items]="StateList"  bindLabel="Name" bindValue="Code">
                          </ng-select>
                        </div>
                      </div>
                    
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="AmCountryCode">Country</label>
                          <ng-select formControlName="AmCountryCode" name="AmCountryCode" id="AmCountryCode"
                          [items]="CountryList"  bindLabel="Name" bindValue="Code">
                          </ng-select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="Contact_Person">Contact Name</label>
                          <input class="form-control" formControlName="Contact_Person" id="Contact_Person">
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="Contact_Mobile">Contact No</label>
                          <input class="form-control" formControlName="Contact_Mobile" id="Contact_Mobile">
                        </div>
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="Email">Email</label>
                          <input class="form-control" formControlName="Email" id="Email"
                            [ngClass]="{'is-invalid': submit && form.Email.errors}">
                          <div *ngIf="submit && form.Email.errors" class="invalid-feedback">
                            Please provide Email
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="Mobile_No">Mobile No</label>
                          <input class="form-control" formControlName="Mobile_No" id="Mobile_No"
                            [ngClass]="{'is-invalid': submit && form.Mobile_No.errors}">
                          <div *ngIf="submit && form.Mobile_No.errors" class="invalid-feedback">
                            Please provide MobileNo
                          </div>
                        </div>

                      </div>
                      
      
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="amCreditDays">Credit Days</label>
                          <input type="number" class="form-control" formControlName="amCreditDays" id="amCreditDays"
                           [readonly] ="roleName != 'Admin'"  [disabled]="roleName != 'Admin'"   
                           [ngClass]="{'is-invalid': submit && form.amCreditDays.errors}">
                            <div *ngIf="submit && form.amCreditDays.errors" class="invalid-feedback">
                            Please provide Credit Days
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="amDueDay">Due Day of Month</label>
                          <input type="number" class="form-control" formControlName="amDueDay" id="DueDayofMonth"
                           [readonly] ="roleName != 'Admin'"  [disabled]="roleName != 'Admin'"  
                            [ngClass]="{'is-invalid': submit && form.amDueDay.errors}">
                            <div *ngIf="submit && form.amDueDay.errors" class="invalid-feedback">
                            Please provide Due Day of Month
                          </div>
                        </div>
                      </div>
                      
                                                                                                
                    </div>
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="AadharCardNo">Aadhar Card No</label>
                          <input class="form-control"  formControlName="AadharCardNo" id="AadharCardNo"
                            [ngClass]="{'is-invalid': submit && form.AadharCardNo.errors}">
                          <div *ngIf="submit && form.AadharCardNo.errors" class="invalid-feedback">
                            Please provide Aadhar Card No
                          </div>
                        
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="GSTINO">GST No</label>
                          <input class="form-control" formControlName="GSTINO" id="GSTINO"
                          [ngClass]="{'is-invalid': submit && form.GSTINO.errors}">
                          <div *ngIf="submit && form.GSTINO.errors" class="invalid-feedback">
                            Please provide GST No
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="PAN_NO">PAN No</label>
                          <input class="form-control" formControlName="PAN_NO" id="PAN_NO"
                           [ngClass]="{'is-invalid': submit && form.PAN_NO.errors}">
                          <div *ngIf="submit && form.PAN_NO.errors" class="invalid-feedback">
                            Please provide PAN No
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="T_CODE">Transport</label>
                          <input class="form-control" formControlName="T_CODE" id="T_CODE"
                            [ngClass]="{'is-invalid': submit && form.T_CODE.errors}">
                          <div *ngIf="submit && form.T_CODE.errors" class="invalid-feedback">
                            Please provide Transport
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="SaleCategoryId">Sale Category</label>
                          <ng-select formControlName="SaleCategoryId" name="SaleCategoryId" id="SaleCategoryId"
                          [items]="SaleCategoryList"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="SaleCategoryCode"></ng-select>
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                        <div class="form-group">
                          <label for="SaleTax">Sale Tax Category</label>
                          <ng-select formControlName="SaleTax" name="SaleTax" id="SaleTax"
                          [items]="SaleTaxList"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="SaleTaxCode"></ng-select>
                        </div>
                      </div> -->
      
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="AmGstNature">GST Nature</label>
                          <ng-select formControlName="AmGstNature" name="AmGstNature" id="AmGstNature"
                          [items]="GSTNatureList"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="GSTNatureCode"></ng-select>
                        </div>  
                      </div>
                    
                    </div>
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="opening_bal">Opening Balance</label>
                          <input class="form-control" formControlName="opening_bal" id="opening_bal"
                            [ngClass]="{'is-invalid': submit && form.opening_bal.errors}">
                          <div *ngIf="submit && form.OpeningBalance.errors" class="invalid-feedback">
                            Please provide Opening Balance
                          </div>
                         
                        </div>
                      </div>
                    
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="opn_nature">Opening Type</label>
                          <ng-select formControlName="opn_nature" name="opn_nature" id="opn_nature"
                          [items]="DebitList"  bindLabel="Name" bindValue="Code"></ng-select>
                        </div>  
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="Private_Mark">Private Mark</label>
                          <input class="form-control" formControlName="Private_Mark" id="Private_Mark"
                            [ngClass]="{'is-invalid': submit && form.PrivateMark.errors}">
                          <div *ngIf="submit && form.Private_Mark.errors" class="invalid-feedback">
                            Please provide Private Mark
                          </div>
                        </div>  
                      </div>
                    
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="Rate_List">Rate List</label>
                          <input class="form-control" formControlName="Rate_List" id="Rate_List"
                            [ngClass]="{'is-invalid': submit && form.Rate_List.errors}">
                          <div *ngIf="submit && form.Rate_List.errors" class="invalid-feedback">
                            Please provide Rate List
                          </div>
                        </div>  
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="amDisPer">Deprication Rate</label>
                          <input class="form-control" formControlName="amDisPer" id="amDisPer"
                            [ngClass]="{'is-invalid': submit && form.amDisPer.errors}">
                          <div *ngIf="submit && form.amDisPer.errors" class="invalid-feedback">
                            Please provide Deprication Rate
                          </div>
                        </div>  
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="Print_Type">Print Type</label>
                          <ng-select formControlName="Print_Type" name="Print_Type" id="Print_Type"
                          [items]="PrintTypeList"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="PrintTypeCode"></ng-select>
                        </div>
                      </div> 
                    </div>
                    <div class="row">
                      <div class="col-md-12 d-flex justify-content-end">
                        <button type="submit" class="btn btn-primary tblebtn w-md">
                          Save
                        </button>&nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
