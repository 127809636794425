<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen] = "true"></ngx-spinner>

<div class="container-fluid">
  <app-pagetitle title="Product Setup" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Purchase Order List</h4> -->
          <form class="needs-validation" (ngSubmit)="validSearchSubmit()" [formGroup]="searchform">

            <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="AccountType">Product Group</label>
                    <ng-select formControlName="AccountType" name="AccountType" id="AccountType"
                    [items]="accountTypesSearch"  bindLabel="Name" bindValue="TypeCode" 
                    [(ngModel)]="searchselectedTypeID"></ng-select>
                  </div>
                </div>

            <div class="col-md-3">
                  <div class="form-group">
                <label for="ActiveTagSearch">Active</label>
                <ng-select formControlName="ActiveTagSearch" name="ActiveTagSearch" id="ActiveTagSearch"
                [items]="ActiveTagListSearch"  bindLabel="Name" bindValue="Code" 
                [(ngModel)]="ActiveTagCodeSearch"></ng-select>
              </div>
            </div>

            <div class="col-md-2">
                  <div class="form-group">
                    <br>
                    <button class="btn btn-rounded  btn-warning " type="submit">
                      <i class="fa fa-search"></i>&nbsp; Search
                          </button>
                        </div>
                      </div>
  
              </div>
  
            </form>          
        
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
                <button type="button"  class="btn btn-primary tblebtn w-md"
                        (click)="resetAndopenModal()"><i class="mdi mdi-plus"></i>Add New</button>
      
              
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div class="row navbar">
                <div class="col-6 d-flex justify-content-left">
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <div class="row">
                        <div>
                            <input class="form-control" [(ngModel)]="searchString" placeholder="Search" id="searchString"
                                name="searchString" (keyup)="searchExistingRecords($event)" />
                        </div>
                        <!-- <div>
                            <button class="btn btn-primary" (click)="searchDBRecords()"><i
                                    class="ti-search m-r-5 m-l-5"></i></button>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table #table class="table table-hover m-t-20 table-bordered table-striped">
              <thead class="bg-info text-white">
                <tr class="">
                  <th>PrdCode</th>
                  <th>Product Name</th>
                  <th>Group Name</th>
                  <th>Asgn Code</th>
                  <th>UOM</th>
                  <th>Created By</th>
                  <th>Active</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody class="border border-info">
                <tr *ngFor="let item of work">

                  <td>{{item.IT_CODE}}</td>
                  <td>{{item.ITEM_NAME}}</td>
                  <td>{{item.GROUP_NAME}}</td>
                  <td>{{item.PRODUCT_CODE}}</td>
                  <td>{{item.UnitName}}</td>
                  <td>{{item.UserName}}</td>
                  <td>{{item.IsActiveF}}</td>
                  <td>
                
                    <a href="javascript:void(0);" (click)="editRecord(item.S_NO)" class="text-primary"
                      ngbTooltip="Edit"><i class="mdi mdi-file-document-edit-outline font-size-22 "></i></a>

                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        <ngb-pagination [collectionSize]="pager.totalItems " [pageSize]="pager.pageSize "
            [(page)]="pager.currentPage " [maxSize]="10 " [rotate]="true "
            (pageChange)="setPage(pager.currentPage) " [boundaryLinks]="true ">
        </ngb-pagination>

        </div>
      </div>
    </div>
  </div>



  <!-- Modal -->

</div>
