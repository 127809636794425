import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

import { UiModule } from '../../shared/ui/ui.module';
import { FormRoutingModule } from './form-routing.module';
import { ValidationComponent } from './validation/validation.component';
import { EditorComponent } from './editor/editor.component';
import { UploadsComponent } from './uploads/uploads.component';
import { WizardComponent } from './wizard/wizard.component';
import { MaskComponent } from './mask/mask.component';
import { AdvancedformComponent } from './advancedform/advancedform.component';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgxSpinnerModule } from 'ngx-spinner';
import { POFormComponent } from './POForm/POForm.component';
import { PoListComponent } from './POList/POList.component';
import { PoListDirective } from './POList/PoList-sortable.directive';
import { CommonPrintComponent } from './CommonPrint/CommonPrint.component';
import { PurchasePackingComponent } from './PurchasePacking/PurchasePacking.component';
import { SaleQuotationListComponent } from './SaleQuotationList/SaleQuotationList.component';
import { SaleQuotationEntryComponent } from './SaleQuotationEntry/SaleQuotationEntry.component';
import { PurchaseCommercialInvoiceComponent } from './PurchaseCommercialInvoice/PurchaseCommercialInvoice.component';
import { PurchaseCommercialInvoiceListComponent } from './PurchaseCommercialInvoiceList/PurchaseCommercialInvoiceList.component';
import { SaleOrderEntryComponent } from './SaleOrderEntry/SaleOrderEntry.component';
import { SaleOrderListComponent } from './SaleOrderList/SaleOrderList.component';
import { sizeMstComponent } from './sizeMst/sizeMst.component';
import { brandMstComponent } from './brandMst/brandMst.component';
import { ChangePasswordComponent } from './changePassword/changePassword.component';
import { SaleCommercialInvoiceListComponent } from './SaleCommercialInvoiceList/SaleCommercialInvoiceList.component';
import { SaleCommercialInvEntryComponent } from './SaleCommercialInvEntry/SaleCommercialInvEntry.component';
import { PendingPaymentsSaleComponent } from './PendingPaymentsSale/PendingPaymentsSale.component';
import { PaymentEntrySaleComponent } from './PaymentEntrySale/PaymentEntrySale.component';
import { PaymentsListSaleComponent } from './PaymentsListSale/PaymentsListSale.component';
import { PaymentsListPurchaseAdvanceComponent } from './PaymentsListPurchaseAdvance/PaymentsListPurchaseAdvance.component';
import { PendingPaymentsPurchaseComponent } from './PendingPaymentsPurchase/PendingPaymentsPurchase.component';
import { StockListComponent } from './StockList/StockList.component';
import { PaymentEntryPurchaseComponent } from './PaymentEntryPurchase/PaymentEntryPurchase.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { UserPermListComponent } from './UserPermList/UserPermList.component';
import { roleMstComponent } from './roleMst/roleMst.component';
import { userMasterComponent } from './userMaster/userMaster.component';
import { UserAccountMappingComponent } from './UserAccountMapping/UserAccountMapping.component';
import { RateListUploadComponent } from './RateListUpload/RateListUpload.component';
import { SaleAdvanceEntryComponent } from './SaleAdvanceEntry/SaleAdvanceEntry.component';
import { SaleAdvanceListomponent } from './SaleAdvanceList/SaleAdvanceList.component';
import { salePackingListComponent } from './salePackingList/salePackingList.component';
import { SalePackingEntryComponent } from './SalePackingEntry/SalePackingEntry.component';
import { AdminSettingsComponent } from './AdminSettings/AdminSettings.component';
import { AccountStatementComponent } from './AccountStatement/AccountStatement.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: '',
  maxFilesize: 50
};
@NgModule({
  // tslint:disable-next-line: max-line-length
    declarations: [POFormComponent,  ValidationComponent,
    EditorComponent, UploadsComponent, WizardComponent, MaskComponent,
    AdvancedformComponent,PoListComponent, PoListDirective,CommonPrintComponent,
    PurchasePackingComponent,
    SaleQuotationListComponent,SaleQuotationEntryComponent,
    PurchaseCommercialInvoiceComponent,PurchaseCommercialInvoiceComponent,
  PurchaseCommercialInvoiceListComponent,SaleOrderEntryComponent,SaleOrderListComponent,
sizeMstComponent,brandMstComponent,ChangePasswordComponent,SaleCommercialInvoiceListComponent,
SaleCommercialInvEntryComponent,
PendingPaymentsSaleComponent,PaymentEntrySaleComponent,PaymentsListSaleComponent,
PaymentsListPurchaseAdvanceComponent,PendingPaymentsPurchaseComponent,
StockListComponent,PaymentEntryPurchaseComponent,
UserPermListComponent ,roleMstComponent,userMasterComponent,
UserAccountMappingComponent,RateListUploadComponent,SaleAdvanceEntryComponent,SaleAdvanceListomponent,
salePackingListComponent,SalePackingEntryComponent,AdminSettingsComponent,AccountStatementComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormRoutingModule,
    UiModule,
    CKEditorModule,
    ArchwizardModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    UiSwitchModule,
    ColorPickerModule,
    NgbDatepickerModule,
    DropzoneModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ]
})
export class FormModule { }
