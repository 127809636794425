import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-statSingle',
  templateUrl: './statSingle.component.html',
  styleUrls: ['./statSingle.component.scss']
})
export class StatSingleComponent implements OnInit {

  @Input() title: string;
  @Input() value: string;
  @Input() icon: string;

  constructor() { }

  ngOnInit(): void {
  }

}
