import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IdleSessionTimeout } from 'idle-session-timeout';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import { SessionCheck } from './sessioncheck.service';


@Injectable({ providedIn: 'root' })

export class CommonService {
	data = [];
	user: User;
	httpLink = ''; _url = '';
	origin = window.location.origin + '/';
	_host = '';
	session = new IdleSessionTimeout(900000);
	constructor(private _sessionCall: SessionCheck, private router: Router,
		private _toastrService: ToastrService,
		private _Http: HttpClient,
		private spinner: NgxSpinnerService) {

		if (this.origin.includes('localhost')) {
			 this.httpLink = 'http://localhost:8052/';
			 this.httpLink = 'http://jshwapi.solutionera.co.in/';
			 this._host = this.httpLink;
		}
	      else {
			this.httpLink = 'http://jshwapi.solutionera.co.in/';
			this._host = this.httpLink;
		}
    this.httpLink = 'https://jshwapi.solutionera.in/';
    this._host = this.httpLink;


    this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				console.log(event)
				const customEvent = {
					id: event.id,
					url: event.url,
					urlAfterRedirects: event.urlAfterRedirects,
					// time:new Date().toJSON("yyyy/MM/dd HH:mm")
				};
				if (this._sessionCall.getSessionStorage('loggEvent')) {
					this.data = JSON.parse(this._sessionCall.getSessionStorage('loggEvent'));
					this.data.push(customEvent);
					_sessionCall.setSessionStorage('loggEvent', JSON.stringify(this.data));
				} else {
					this.data.push(customEvent);
					this._sessionCall.setSessionStorage('loggEvent', JSON.stringify(this.data));
				}
				//this.eventlogService();
			});
	}


	postMethodWithTokenApiCall(sp: any, param: any): Observable<any> {
		try {
			var token = '';
			if (this._sessionCall.getToken()) {
				token = this._sessionCall.getToken();
			}
			if (this._sessionCall.getLoginUserID()) {
				param.UserID = this._sessionCall.getLoginUserID();
			}
			const httpOptions = {
				headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': token }),
				observe: 'response' as 'response'
			};
			this._url = this._host + sp;
			return this._Http.post(this._url, param, httpOptions)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError((error) => {
						this.spinner.hide();
						console.log(error.error);

						return this.handleError_v2(error, this._sessionCall, this._toastrService);
					})
				)
		} catch (error) {
			this.spinner.hide();
			this.handleError(error, this._sessionCall, this._toastrService);

		}
	}
	postMethodWithToken(sp: any, param: any): Observable<any> {
		try {
			let token = '';
			if (this._sessionCall.getToken()) {
				token = this._sessionCall.getToken();
			}
			if (token != '') {
				if (this._sessionCall.getLoginUserID()) {
					param.UserID = this._sessionCall.getLoginUserID();
				}
				sp = this._sessionCall.getEncryptedSPValues(sp);
				sp = sp.replace(/[/]/g, 'sLash');
				sp = sp.replace(/[=]/g, 'equalTo');
				sp = sp.replace(/[+]/g, 'pLus');
				param = JSON.stringify(param);
				param = this._sessionCall.getEncryptedSPValues(param);
				param = param.replace(/[/]/g, 'sLash');
				param = param.replace(/[=]/g, 'equalTo');
				param = param.replace(/[+]/g, 'pLus');
				const httpOptions = {
					headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': token }),
					observe: 'response' as 'response'
				};
				const secureParam = {
					Param: param
				};
				this._url = this.httpLink + sp + '/json';
				return this._Http.post(this._url, secureParam, httpOptions)
					.pipe(map((data: any) => {
						if (data.status == 200) {
							return data.body.ResultSets[0];
						}
					}), catchError((error) => {
						this.spinner.hide();
						console.log(error);
						return error;
					}));
			}
			else {
				this.spinner.hide();
				this._sessionCall.logout(this._sessionCall.getLoginLoggedType());
				return null;

			}
		} catch (error) {
			this.spinner.hide();
			console.log(error);
			// return error;
		}


	}
	postMethodWithTokenAllResult(sp: any, param: any): Observable<any> {
		try {
			let token = '';
			if (this._sessionCall.getToken()) {
				token = this._sessionCall.getToken();
			}
			if (token != '') {
				if (this._sessionCall.getLoginUserID()) {
					param.UserID = this._sessionCall.getLoginUserID();
				}
				sp = this._sessionCall.getEncryptedSPValues(sp);
				sp = sp.replace(/[/]/g, 'sLash');
				sp = sp.replace(/[=]/g, 'equalTo');
				sp = sp.replace(/[+]/g, 'pLus');
				param = JSON.stringify(param);
				param = this._sessionCall.getEncryptedSPValues(param);
				param = param.replace(/[/]/g, 'sLash');
				param = param.replace(/[=]/g, 'equalTo');
				param = param.replace(/[+]/g, 'pLus');
				const httpOptions = {
					headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': token }),
					observe: 'response' as 'response'
				};
				const secureParam = {
					Param: param
				};
				this._url = this.httpLink + sp + '/json';
				return this._Http.post(this._url, secureParam, httpOptions)
					.pipe(map((data: any) => {
						if (data.status == 200) {
							return data.body;
						}
					}), catchError((error) => {
						this.spinner.hide();
						console.log(error);
						return error;
					}));
			}
			else {
				this.spinner.hide();
				this._sessionCall.logout(this._sessionCall.getLoginLoggedType());
				return null;

			}
		} catch (error) {
			this.spinner.hide();
			console.log(error);
			// return error;
		}


	}

	postMethodWithoutToken(sp: any, param: any): Observable<any> {
		//try
		{
			this._url = this._host + sp + '/json';
			let headers = new HttpHeaders();
			headers.append('Content-Type', 'application/json');
			return this._Http.post(this._url, param, { headers: headers, observe: 'response' })
				.pipe(map((data: any) => {
					if (data.status == 200) {
						return data;
					}
				}),
					catchError((error) =>
						this.handleError(error, this._sessionCall, this._toastrService)))
		}
		//catch (error) {
		//		this.handleError(error, this._sessionCall, this._toastrService);
		//	}
	}
	postMethodWithTokenParam(sp: any, param: any): Observable<any> {
		try {
			var token = '';
			if (this._sessionCall.getToken()) {
				token = this._sessionCall.getToken();
			}
			if (this._sessionCall.getLoginUserID()) {
				param.UserID = this._sessionCall.getLoginUserID();
			}

			const httpOptions = {
				headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': token }),
				observe: 'response' as 'response'
			};

			this._url = this._host + sp + '/json';
			return this._Http.post(this._url, param, httpOptions)
				.pipe(map((data: any) => {
					if (data.status == 200) {
						return data.body.ResultSets[0];
					}
				}), catchError((error) => {
					this.spinner.hide();
					return this.handleError(error, this._sessionCall, this._toastrService)
				}))
		} catch (error) {
			this.spinner.hide();
			this.handleError(error, this._sessionCall, this._toastrService);
		}
	}
	postMethodForCustomrCall(sp: any, param: any): Observable<any> {
		try {
			var token = '';

			if (this._sessionCall.getToken()) {
				token = this._sessionCall.getToken();
			}
			if (this._sessionCall.getLoginUserID()) {
				param.UserID = this._sessionCall.getLoginUserID();
			}
			const httpOptions = {
				headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Token': token }),
				observe: 'response' as 'response'
			};
			this._url = this._host + sp;
			return this._Http.post(this._url, param, httpOptions)
				.pipe(
					map((response: any) => {
						return response.body;
					}),
					catchError((error) => {
						this.spinner.hide();
						console.log(error);
						return this.handleError(error, this._sessionCall, this._toastrService);
					})
				)
		} catch (error) {
			this.spinner.hide();
			this.handleError(error, this._sessionCall, this._toastrService);
		}
	}
	getExcelAsResult(id: any, spin: any,methodname: string, filename:any): Observable<any> {

        try {
            this.spinner.show();
            var token = this._sessionCall.getToken();
            var xhr = new XMLHttpRequest();
            xhr.open('POST', this._host + 'Misc/'+ methodname + '?ID=' + id + '&UserID='+ this._sessionCall.getLoginUserID() + '&token=' + token, true);
            xhr.setRequestHeader('Token', token);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function (e) {
                if (this.status == 200) {
                    spin.hide();
                    var blob = new Blob([this.response], { type: "application/vnd.ms-excel" });
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename + ".xlsx";
                    link.click();
                    return true;
                }
            };
            xhr.onerror = function (e) {
                return false;
            };
            xhr.send();
            return
        } catch (error) {
            console.log(error)
        }
    }
	handleError(error, _sessionCall, _notification) {
		const _ = this;
		try {
			let errorMessage = '';
			const statusCode = error.status;
			if (this.data.length != 0) {
				//this.insertEventLog(JSON.stringify(this.data)).subscribe();
				this._sessionCall.setSessionStorage('loggEvent', JSON.stringify(this.data = []));
			}
			if (statusCode === '401' || statusCode === 401) {
				// _notification.error('Your session is expired. Please login again.', 'Session expired')
				_sessionCall.logout(this._sessionCall.getLoginLoggedType());
			} else {
				const err = error.error;
				if (err instanceof ErrorEvent) {
					// client-side error
					errorMessage = `Error: ${err.message}`;

				} else {

					// server-side error
					errorMessage = `Error Code: ${error.status}\nMessage: ${err.Message}`;
					const statusCode = error.status;
					if (statusCode === '401' || statusCode === 401) {
						// _notification.error('Your session is expired. Please login again.', 'Session expired');
						_sessionCall.logout(this._sessionCall.getLoginLoggedType());
					} else {
						_notification.error(err.ExceptionMessage, errorMessage);
						console.log(err.ExceptionMessage)
						console.log(errorMessage)
					}
				}
				return throwError(error);
			}
		} catch (error) {
			console.log(error);
			// _notification.error(error);
		}
	}
	handleError_v2(error, _sessionCall, _notification) {
		const _ = this;
		try {
			let errorMessage = '';
			const statusCode = error.status;
			if (this.data.length != 0) {
				//	this.insertEventLog(JSON.stringify(this.data)).subscribe();
				this._sessionCall.setSessionStorage('loggEvent', JSON.stringify(this.data = []));
			}
			if (statusCode === '401' || statusCode === 401) {
				// _notification.error('Your session is expired. Please login again.', 'Session expired')
				_sessionCall.logout(this._sessionCall.getLoginLoggedType());
			} else {
				const err = error.error;
				errorMessage = `Error: ${err}`;
				_notification.error('', errorMessage);
				return throwError(error);

				if (err instanceof ErrorEvent) {
					// client-side error
					errorMessage = `Error: ${err.message}`;

				} else {

					// server-side error
					errorMessage = `Error Code: ${error.status}\nMessage: ${err.Message}`;
					const statusCode = error.status;
					if (statusCode === '401' || statusCode === 401) {
						// _notification.error('Your session is expired. Please login again.', 'Session expired');
						_sessionCall.logout(this._sessionCall.getLoginLoggedType());
					} else {
						_notification.error(err.ExceptionMessage, errorMessage);
						console.log(err.ExceptionMessage)
						console.log(errorMessage)
					}
				}
				return throwError(error);
			}
		} catch (error) {
			console.log(error);
			// _notification.error(error);
		}
	}
	DownloadExcelFile(fileID,fileName, spin: any,methodname): Observable<any> {
        try{
			const spinner=spin;
        spinner.show();
        var token = this._sessionCall.getToken();
        var xhr = new XMLHttpRequest();
        xhr.open('POST', this._host + 'Misc/'+methodname + '?DocId=' + fileID);
        xhr.setRequestHeader('Token', token);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function (e) {
            if (this.status == 200) {
                spin.hide();
                var blob = new Blob([this.response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download =fileName+ "_Result" + ".xlsx";
                link.click();
				spinner.hide();
                return true;
            }
        };
        xhr.onerror = function (e) {
			spinner.hide();

            return false;
        };
		spinner.hide();
        xhr.send();
        return
    } catch (error) {
		this.spinner.hide();
        console.log(error)
    }
    }
	DownloadPDFFile(fileID,fileName, spin: any): Observable<any> {
        try{
			const spinner=spin;
        spinner.show();
        var token = this._sessionCall.getToken();
        var xhr = new XMLHttpRequest();
        xhr.open('POST', this._host + 'Misc/POPDF?Id=' + fileID + '&userid=' + this._sessionCall.getLoginUserID() + '&token=' + this._sessionCall.getToken())
        xhr.setRequestHeader('Token', token);
        xhr.responseType = 'arraybuffer';
        xhr.onload = function (e) {
            if (this.status == 200) {
                spin.hide();
                var blob = new Blob([this.response], { type: "application/pdf" });
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download =fileName+ "_Result" + ".PDF";
                link.click();
				spinner.hide();
                return true;
            }
        };
        xhr.onerror = function (e) {
			spinner.hide();

            return false;
        };
		spinner.hide();
        xhr.send();
        return
    } catch (error) {
		this.spinner.hide();
        console.log(error)
    }
    }
	sessionStart() {
		this.session.start();
	}

	sessionReset() {
		this.session.reset();
	}
	sessionDispose() {
		this.session.dispose();
	}

	/**
	 * Returns the current user
	 */
	public currentUser(): User {
		return getFirebaseBackend().getAuthenticatedUser();
	}

	/**
	 * Performs the auth
	 * @param email email of user
	 * @param password password of user
	 */
	login(email: string, password: string) {
		return getFirebaseBackend().loginUser(email, password).then((response: any) => {
			const user = response;
			return user;
		});
	}

	/**
	 * Performs the register
	 * @param email email
	 * @param password password
	 */
	register(email: string, password: string) {
		return getFirebaseBackend().registerUser(email, password).then((response: any) => {
			const user = response;
			return user;
		});
	}

	/**
	 * Reset password
	 * @param email email
	 */
	resetPassword(email: string) {
		return getFirebaseBackend().forgetPassword(email).then((response: any) => {
			const message = response.data;
			return message;
		});
	}

	/**
	 * Logout the user
	 */
	logout() {
		// logout the user
		getFirebaseBackend().logout();
	}
}

