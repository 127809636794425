<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen] = "true"></ngx-spinner>

<div class="container-fluid">
  <!-- <app-pagetitle title="Packing List" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">Commercial Invoice List</h4>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Purchase Order List</h4> -->
          <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

          <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="BLNo">BL No</label>
                  <input class="form-control" formControlName="BLNo" id="BLNo">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="PONo">PO No</label>
                  <input class="form-control" formControlName="PONo" id="PONo">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="FromDate">From Date</label>
                  <input type="date" class="form-control" formControlName="FromDate" id="FromDate">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="ToDate">To Date</label>
                  <input type="date" class="form-control" formControlName="ToDate" id="ToDate">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="ArrivalDate">Arrival Date</label>
                  <input type="date" class="form-control" formControlName="ArrivalDate" id="ArrivalDate">
              
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <button class="btn btn-rounded  btn-warning" type="submit">Search
                        </button>
                      </div>
                    </div>

            </div>

          </form>
            <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
                <button class="btn btn-round btn-outline-primary" 
                (click)="openPO()" type="button"><i class="mdi mdi-account-plus"></i>
                Add Commercial Invoice</button>
                <!-- <button class="btn  btn-outline-success w-md waves-effect waves-light" (click)="saveExcel()"
                type="button">
                <i class="mdi mdi-microsoft-excel"></i> Excel</button>
              <button class="btn  btn-outline-warning w-md waves-effect waves-light" (click)="saveCSV()" type="button">
                <i class="mdi mdi-clipboard-text"></i> CSV</button>
              <button class="btn  btn-outline-danger w-md waves-effect waves-light" (click)="savePDF()" type="button">
                <i class="mdi mdi-file-pdf"></i> PDF</button> -->
                <!-- btn btn-success w-md waves-effect waves-light -->
                <!-- btn btn-block btn-outline-primary -->
              <!-- <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Show
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> entries</label></div> -->
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div class="row navbar">
                <div class="col-6 d-flex justify-content-left">
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <div class="row">
                    <div>
                      <input class="form-control" [(ngModel)]="searchString" placeholder="Search" id="searchString"
                        name="searchString" (keyup)="searchExistingRecords($event)" />
                    </div>
                    
                  </div>
                </div>
              </div>
            </div> 
              <!-- End search-->
          <!-- Table -->
          <div class="table-responsive">
            <table  id="table" #table class="table table-hover m-t-20 table-bordered">
                <thead class="bg-info text-white">
                    <tr class="">
                        <th>Date</th>
                        <th>Commercial Invoice No</th>
                        <th>BL No </th>
                        <th>Arrival Date</th>
                        <th>Created By</th>
                        <th>Created Date</th>
                        <th>Qty</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody class="border border-info">
                    <tr *ngFor="let item of work">

                        <td>{{item.PackingDate}}</td>
                        <td>{{item.PackingNo}}</td>
                        <td>{{item.BLNo}}</td>
                        <td>{{item.ArrivalDate}}</td>
                        <td>{{item.CreatedBy}}</td>
                        <td>{{item.CreatedDate}}</td>
                        <td>{{item.LoadQty}}</td>
                        <td>
                                <a href="javascript:void(0);" (click)="downloadExcelResult(item)" class="text-success" ngbTooltip="Edit"><i
                                  class="mdi mdi-file-excel font-size-22 "></i></a>
                                  &nbsp; <a href="javascript:void(0);" (click)="editRecord(item)" class="text-warning" ngbTooltip="Edit"><i
                                  class="mdi mdi-file-document-edit-outline font-size-22 "></i></a>
                                 &nbsp; <a href="javascript:void(0);" (click)="PrintPO(item,PrintModal)" class="text-primary" ngbTooltip="Edit"><i
                                    class="mdi mdi-printer font-size-22 "></i></a>
            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ngb-pagination [collectionSize]="pager.totalItems " [pageSize]="pager.pageSize "
            [(page)]="pager.currentPage " [maxSize]="10 " [rotate]="true "
            (pageChange)="setPage(pager.currentPage) " [boundaryLinks]="true ">
        </ngb-pagination>

        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #PrintModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Commercial Invoice</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <!-- <iframe width="100%" height="600px"></iframe> -->
    <ngx-extended-pdf-viewer [src]="pdfdoc" [useBrowserLocale]="true" ></ngx-extended-pdf-viewer>
</div>
</ng-template>
