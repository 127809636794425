<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus"
  [fullScreen]="true"></ngx-spinner>

<div class="container-fluid">
  <!-- <app-pagetitle title="Purchase Order" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">Estimate List</h4>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="FromDate">From Date</label>
                  <input type="date" class="form-control" formControlName="FromDate" id="FromDate">
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="ToDate">To Date</label>
                  <input type="date" class="form-control" formControlName="ToDate" id="ToDate">
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <br>
                  <button class="btn btn-rounded  btn-warning px-4" type="submit">
                    <i class="fa fa-search"></i>&nbsp; Search
                  </button>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <br>
                  <button class="btn btn-rounded  btn-success px-4" type="button" (click)="btnInvoice()">
                    <i class="fa fa-file"></i>&nbsp; Invoice
                  </button>
                </div>
              </div>

            </div>

          </form>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <button class="btn  btn-outline-success w-md waves-effect waves-light" (click)="saveExcel()"
                type="button">
                <i class="mdi mdi-microsoft-excel"></i> Excel</button>
              <button class="btn  btn-outline-warning w-md waves-effect waves-light" (click)="saveCSV()" type="button">
                <i class="mdi mdi-clipboard-text"></i> CSV</button>
              <button class="btn  btn-outline-danger w-md waves-effect waves-light" (click)="savePDF()" type="button">
                <i class="mdi mdi-file-pdf"></i> PDF</button>


            </div>
          </div>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <button class="btn  btn-outline-primary w-md waves-effect waves-light px-4" (click)="openPO()"
                type="button">
                <i class="mdi mdi-account-plus"></i> Add New</button>
            </div>

            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div class="row navbar">
                <div class="col-6 d-flex justify-content-left">
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <div class="row">
                    <div>

                      <input class="form-control" [(ngModel)]="searchString" placeholder="Search" id="searchString"
                        name="searchString" (keyup)="searchExistingRecords($event)" />
                    </div>
                    <!-- <div>
                            <button class="btn btn-primary" (click)="searchDBRecords()"><i
                                    class="ti-search m-r-5 m-l-5"></i></button>
                        </div> -->
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive">
              <table id="table" #table class="table table-hover m-t-20 table-bordered">
                <thead class="bg-info text-white">
                  <tr class="">
                    <th>Doc Date</th>
                    <th>Doc No</th>
                    <th>Quantity</th>
                    <th>Amount</th>
                    <th>Created Date</th>
                    <th>Created By</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="border border-info">
                  <tr *ngFor="let item of work">
                    <td>{{item.Dated}}</td>
                    <td>{{item.VoucherNo}}</td>
                    <td>{{item.Qty}}</td>
                    <td>{{item.Amount}}</td>
                    <td>{{item.CreatedDate}}</td>
                    <td>{{item.CreatedBy}}</td>
                    <td>
                      <label class="switch1">
                        <input type="hidden">
                        <input type="checkbox" [(ngModel)]="item.Chkbox" >
                        <span class="slider round"></span>
                      </label>
                      <a href="javascript:void(0);" (click)="editRecord(item)" class="text-primary" ngbTooltip="Edit"><i
                          class="mdi mdi-file-document-edit-outline font-size-22 "></i></a>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ngb-pagination [collectionSize]="pager.totalItems " [pageSize]="pager.pageSize "
              [(page)]="pager.currentPage " [maxSize]="10 " [rotate]="true " (pageChange)="setPage(pager.currentPage) "
              [boundaryLinks]="true ">
            </ngb-pagination>

          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #DetailModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Sale Invoice</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
    <div class="modal-body">

    </div>
  </ng-template>
