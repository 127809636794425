import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './RateListUpload.mustmatch';
//import { icons } from './form/data';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThisReceiver } from '@angular/compiler';
import * as fileSaver from '../../../../assets/filedownload/filesave.js';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import { CommonService } from 'src/app/core/services/CommonService.service';

@Component({
  selector: 'app-RateListUpload',
  templateUrl: './RateListUpload.component.html',
  styleUrls: ['./RateListUpload.component.scss']
})

/**
 * Forms Validation component
 */
export class RateListUploadComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form

  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private commonservice: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private _session:SessionCheck
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;
  tableData: any[];
  table: any[];
  worklist: any[];
  searchlist: any[];
  work: any[];
  pager: any = {};
  searchString: string;
  // Form submition
  submit: boolean;
  formsubmit: boolean;
  SearchItems: any = [];
  ID: any;
  accountTypes: any[];
  prdSizeList: any[];
  prdBrandList: any[];
  maxCode: string;
  fileUploadInputs:any;
  fileExcel: DataTransfer;
  fileExcelUpload: any;
  fileBase64:any;
  fileName:string;
  fileType:string;
  RoleName:string;
    ngOnInit() {
    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Brand', active: true }];
    this.validationform = this.formBuilder.group({
      Remarks: ['', [Validators.required]],
        });
        this.RoleName=this._session.getSessionStorage('Role');

    this.getRegularList();
    this.ID = 0;
  }
  get form() {
    return this.validationform.controls;
  }

  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      try {
        this.spinner.show();
        const JsonMaster = {
          File: this.fileBase64,
          FileName: this.fileName,
          FileType: this.fileType,
          Remarks: this.validationform.get('Remarks').value
        }

        this.setup.addRecord(this.ID, (JsonMaster), '', 'sp_AddRateListFile')
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data[data.length - 1].Status == 1) {
            this.modalService.dismissAll();
            this.toaster.success(data[0].ResponseMessage);
            this.validationform.reset();
            this.getRegularList();
          }
          else {
            this.toaster.error(data[0].ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }


    }
  }
  formSubmit() {
    this.formsubmit = true;

  }
  getRegularList() {
    try {
      this.spinner.show();
      this.setup.GetRegularworkList('sp_getRateListData')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        this.tableData = [];
        this.worklist = [];
        if (data.ResultSets.length > 0) {
          this.tableData = data.ResultSets[0];
          this.worklist = data.ResultSets[0];
        //  this.RoleName=data.ResultSets[0][0].RoleName;
          this.setPage(1);
          this.spinner.hide();

        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }

  editRecord(ID, modal) {
    try {
      this.setup.GetRecordInfo(ID, 'sp_GetPrdBrandRecord')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.fillEditRecordData(data, modal);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  fillEditRecordData(data, modal) {
    data.forEach(element => {
      var keys = Object.keys(element);
      var values = Object.values(element);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { console.log(error);}
      })
    });

    this.ID = data[0].pbid;
    this.openModal(modal);
  }
  setPage(page: number) {
    this.pager = this.getPager(this.worklist.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    //  this.pager = this.getPager(this.worklist.length, page);
    this.work = this.worklist.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  searchExistingRecords(event: any) {
    const val = event.target.value.toLowerCase();
    //const val = event.target.value;
    if (val != '') {
      const list = this.worklist.filter(function (d) {
        let ColumnsData = '';
        const columnsName = ['FileName', 'Remarks', 'UserName', 'CreatedDate' ];
        columnsName.forEach(col => {
          ColumnsData += ' ' + d[col];
        });
        return ColumnsData.toLowerCase().indexOf(val) !== -1 || !val;
      });
      // Whenever the filter changes, always go back to the first page.
      //this.tableData = list;
      this.table = list;
      this.work = list;
      this.setPageSearch(1);
    } else {
      this.table = this.worklist;
      this.setPageSearch(1);
    }
  }
  setPageSearch(page: number) {
    this.pager = this.getPager(this.table.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    this.work = this.table.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  openModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'lg' });
  }
  resetAndopenModal(largeDataModal: any) {
    this.validationform.reset();
    this.ID = 0;
    this.openModal(largeDataModal);
    this.validationform.patchValue(
      {
        BrandCode: this.maxCode
      }
    );

  }
  uploadExcel(event: any, content): void {
    
    try {
      this.spinner.show();
      this.fileExcel = <DataTransfer>event.target;
      this.fileExcelUpload = this.fileExcel.files;
      this.fileName = this.fileExcel.files[0].name;
      this.fileType = this.fileExcel.files[0].type;
      var ext = this.fileName.split('.')

      const reader = new FileReader();
      reader.readAsDataURL(this.fileExcelUpload[0]);
      reader.onload = function () {
        if (reader.result) {
          const base64 = reader.result.toString();
          accessionBatchExcel(base64);
        }
      }
      const accessionBatchExcel = (base64: any) => {
        this.fileBase64 = base64;
        this.openModal(content);
        //this.addEditAccessionBatchExcel(base64, '', fileName, type);
      }
    } catch (error) {

    }
  }
  downloadOriginalExcel(item: any) {
    try {
      this.spinner.show();
      var batchHeaderID = item.RateListId;
      if ( item.FileType.toString()=="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
        this.commonservice.getExcelAsResult(batchHeaderID,this.spinner,"getRateFIleExcel", item.FileName)
        .subscribe((data) => {
          this.spinner.hide();
        });
      }
      else
      {

      this.setup.GetRecordInfo(batchHeaderID, 'sp_GetUploadedFile')
        .subscribe((data) => {

          var base64string = data[0].FileData;
          if (base64string.length > 1) {
            base64string = base64string.substring(
              base64string.lastIndexOf(",") + 1,
              base64string.length - 1
            );
            base64string = base64string.substring(
              base64string.lastIndexOf(",") + 1,
              base64string.length - 1
            );
            var Data64ArrayBuffer = this.fixBinary(
              atob(base64string)
            );
            var blob = new Blob([Data64ArrayBuffer], {
              type: data[0].FileType
            });
            this.spinner.hide();
            fileSaver.saveAs(blob, data[0].FileName);
          }
        })
      }
    } catch (error) {
      this.spinner.hide();
      console.log(error);
    }
  }

  public fixBinary(bin: any) {
    var length = bin.length;
    var buf = new ArrayBuffer(length);
    var arr = new Uint8Array(buf);
    for (var i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }
}
