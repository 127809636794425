import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './productMst.mustmatch';
//import { icons } from './form/data';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThisReceiver } from '@angular/compiler';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import * as XLSX from 'xlsx';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-productMst',
  templateUrl: './productMst.component.html',
  styleUrls: ['./productMst.component.scss']
})

/**
 * Forms Validation component
 */
export class productMstComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form
  prdlist: any = [];
  EntryID:any;
  selectedTypeID: string;
  selectedSizeID: number;
  ImGstCodeCode: any[];
  fileUploadInputs: any;
  fileExcel: DataTransfer;
  fileExcelUpload: any;
  fileBase64: any;
  fileName: string;
  fileType: string;
  RoleName: string;
  errorList:any=[];
  ActiveTagCode: string;
  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private _session: SessionCheck
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;
  tableData: any[];
  table: any[];
  worklist: any[];
  searchlist: any[];
  work: any[];
  pager: any = {};
  searchString: string;
  // Form submition
  submit: boolean;
  formsubmit: boolean;
  SearchItems: any = [];
  ID: any;
  accountTypes: any[];
  ImGstCodeList: any[];
  ImGstCodecode: any[];
  GROUP_NAMEList:any[];
  GROUP_NAMECode: any[];
  MODEL_NameList:any[];
  MODEL_Namecode:any[];
  UNITList:any[];
  UNITCode:any[];
  size_nameList: any[];
  // prdTypeList: any[];
  
  maxCode: string;
  ngOnInit() {


    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Product', active: true }];
    this.validationform = this.formBuilder.group({
      IT_CODE: [''],
      ITEM_NAME: ['', [Validators.required]],
      PRODUCT_CODE: [''],
      Item_Alias:[''],
      SHORT_NAME: [''],
      GR_CODE: ['', [Validators.required]],
      size_name: [''],
      imOpnStk: [''],
      UNIT: ['', [Validators.required]],
      Weight: [''],
      PackingPerBox: [''],
      ImGstCode: [''],
      ModelCode: [''],
      Pur_Rate: [''],
      //Bar_Code_Rate:[''],
      imHSN_Code:[''],
      MinOrder:[''],
      MaxOrder:[''],
      Sale_Rate:[''],
      REMARKS:[''],
      BarCodeNo:['']
    });
    // this.prdTypeList = [
    //   { value: "Summer" },
    //   { value: "Winter" },
    //   { value: "All Season Tyre" },
    //   { value: "Truck Tyres" },
    //   { value: "AGR OTR Tyre" },
    //   { value: "Motorcycle Tyres" },
    //   { value: "Batteries" }
   // // ]
    //this.getRegularList();
    this.getDropdownList();
    this.ID = 0;
    this.RoleName = this._session.getSessionStorage('Role');
    this.ActiveTagCode='Y';
    this.EntryID = 0;
    this.EntryID = this.route.snapshot.queryParamMap.get('Id') ? this.route.snapshot.queryParamMap.get('Id') : '';
 
    if(+this.EntryID>0)
    {
      this.editRecord(this.EntryID);
    }
  }
  SetPrdDesc() {
    let sizename = this.size_nameList.filter(r => r.ID == this.validationform.get('size_name').value);
    let sizevalue = ''
    if (sizename.length > 0) {
      sizevalue = sizename[0].Name
    }
    let ImGstCode = this.ImGstCodeList.filter(r => r.ID == this.validationform.get('ImGstCode').value);
    let ImGstCode2 = '';
    if (ImGstCode.length > 0) {
      ImGstCode2 = ImGstCode[0].Name
    }
    let speedrating = '';
    let pattern = '';
    if (this.validationform.get('PrdSpeedRating').value != null) { speedrating = this.validationform.get('PrdSpeedRating').value; }
    if (this.validationform.get('PrdPattern').value != null) { pattern = this.validationform.get('PrdPattern').value; }
    this.validationform.get('PrdName').setValue(sizevalue + ' ' +
      speedrating + ' ' +
      ImGstCode2 + ' ' + pattern);
  }
  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }

  /**
   * Bootsrap validation form submit method
   */
  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      try {
        this.spinner.show();
        const JsonMaster = (this.validationform.value)
        JsonMaster.S_NO=this.EntryID;
        JsonMaster.IsActive=this.ActiveTagCode;
        JsonMaster.VType = '2002';
        this.setup.AddMasterlist(JSON.stringify(JsonMaster))
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data.Status == 1) {
            this.modalService.dismissAll();
            this.toaster.success(data.ResponseMessage);
            this.router.navigate(['/master/product']);
          }
          else {
            this.toaster.error(data.ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }


    }
  }

  AddPrdList(prdlist) {
    try {
      this.spinner.show();

      this.setup.addRecord(this.ID, JSON.stringify(prdlist), '', 'sp_addPrdMst')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data[data.length - 1].Status == 1) {
          this.modalService.dismissAll();
          this.toaster.success(data[0].ResponseMessage);
          this.getRegularList();
        }
        else {
          this.toaster.error(data[0].ResponseMessage);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }



  }


  formSubmit() {
    this.formsubmit = true;

  }
  // checkPrdType(): boolean {
  //   if (this.submit && !this.selectedTypeID) {
  //     return true;
  //   }
  //   else {
  //     return false;
  //   }
  // }
  checkSize(): boolean {
    if (this.submit && !this.selectedSizeID) {
      return true;
    }
    else {
      return false;
    }
  }
  checkimGSTCode(): boolean {
    if (this.submit && !this.ImGstCodeCode) {
      return true;
    }
    else {
      return false;
    }
  }
  getRegularList() {
    try {
      this.spinner.show();
      this.setup.GetRegularworkList('sp_PrdList')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        if (data.ResultSets.length > 0) {
          this.tableData = data.ResultSets[0];
          this.worklist = data.ResultSets[0];
          this.setPage(1);
          this.spinner.hide();
          this.maxCode = data.ResultSets[1][0].MaxCode;
          this.size_nameList = data.ResultSets[2];
          this.ImGstCodeList = data.ResultSets[3];
        }
        this.spinner.hide();

      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }

  editRecord(ID) {
    try {
      this.setup.GetRecordInfo(ID, 'sp_GetRecord','PrdMst')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.fillEditRecordData(data.ResultSets[0]);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  fillEditRecordData(data) {
    data.forEach(element => {
      var keys = Object.keys(element);
      var values = Object.values(element);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { console.log(error); }
      })
    });

    this.ImGstCodeCode = data[0].ImGstCode;
    this.selectedSizeID = data[0].size_name;
    this.selectedTypeID = data[0].PrdType;

    this.ID = data[0].id;
    this.ActiveTagCode = data[0].ActiveTag;
  }
  setPage(page: number) {
    this.pager = this.getPager(this.worklist.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    //  this.pager = this.getPager(this.worklist.length, page);
    this.work = this.worklist.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  searchExistingRecords(event: any) {
    const val = event.target.value.toLowerCase();
    //const val = event.target.value;
    if (val != '') {
      const list = this.worklist.filter(function (d) {
        let ColumnsData = '';
        const columnsName = ['PrdCode', 'PrdName', 'PrdType', 'SizeName', 'BrandName', 'PrdSpeedRating', 'PrdPattern', 'PrdContainerCapacity', 'UserName', 'IsActiveF'];
        columnsName.forEach(col => {
          ColumnsData += ' ' + d[col];
        });
        return ColumnsData.toLowerCase().indexOf(val) !== -1 || !val;
      });
      // Whenever the filter changes, always go back to the first page.
      //this.tableData = list;
      this.table = list;
      this.work = list;
      this.setPageSearch(1);
    } else {
      this.table = this.worklist;
      this.setPageSearch(1);
    }
  }
  setPageSearch(page: number) {
    this.pager = this.getPager(this.table.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    this.work = this.table.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  openModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'lg' });
  }
  resetAndopenModal(largeDataModal: any) {
    this.validationform.reset();
    this.ID = 0;

    this.openModal(largeDataModal);
    this.validationform.patchValue(
      {
        PrdCode: this.maxCode
      }
    );

  }
 
  saveBulkPrdListData(Prdlist) {
    try {
  this.spinner.show();
      const JsonMaster = (this.prdlist)
      this.setup.addRecord(this.ID, JSON.stringify(JsonMaster), '', 'sp_AddBulkProduct')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data[data.length - 1].Status == 1) {
          this.modalService.dismissAll();

this.getRegularList();
this.spinner.hide();
this.toaster.success('Product Uploaded Successfully');
        }
        else {
          this.toaster.error(data[0].ResponseMessage);
        }
      }
    }
    catch (error) {
      this.spinner.hide();
      console.log(error);
    }
  }
  clearFileUpload() {
    this.spinner.hide();
    
  }
 
  getDropdownList()
  {
    this.GROUP_NAMEList=[];
    this.UNITList=[];
    this.MODEL_NameList=[];
    this.ImGstCodeList=[];
    this.setup.GetRegularworkList('dbo.sp_GetMasterData',"ItemMst")
  .subscribe({
    next(list) {
      fetchData(list);
    }
  });
const fetchData = (data: any) => {
  this.spinner.hide();
  if (data.ResultSets.length > 0) {
    this.GROUP_NAMEList=data.ResultSets[0];
    this.UNITList=data.ResultSets[1];
 this.MODEL_NameList=data.ResultSets[2];   
 this.ImGstCodeList=data.ResultSets[3];
  }
};
} catch (error) {
this.spinner.hide();
console.log(error);
this.toaster.error(error);
}
checkUnitType(): boolean {
  if (this.submit && !this.UNITCode) {
    return true;
  }
  else {
    return false;
  }
}
checkGroupType(): boolean {
  if (this.submit && !this.GROUP_NAMECode) {
    return true;
  }
  else {
    return false;
  }
}
}
