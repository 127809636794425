import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './validation.mustmatch';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})

/**
 * Forms Validation component
 */
export class ValidationComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form
  tooltipvalidationform: FormGroup; // bootstrap tooltip validation form
  typeValidationForm: FormGroup; // type validation form
  rangeValidationForm: FormGroup; // range validation form
  SupplierList:any=[];
  constructor(public formBuilder: FormBuilder,
    private spinner:NgxSpinnerService,
    private toaster:ToastrService,
    private setup:SetupService) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;
  SearchItems:any=[];
  searchSize:string;
  selectedItemArray:any=[];
  ProductArray:any=[];
  SearchPanelVisible:boolean;
  TotalQty:any;

  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Form Validation', active: true }];

    /**
     * Bootstrap validation form data
     */
    this.validationform = this.formBuilder.group({
      PONo: ['', [Validators.required]],
      PODate: ['', [Validators.required]],
      SupplierName: ['', [Validators.required]],
      
    });

    /**
     * Bootstrap tooltip validation form data
     */
    // this.tooltipvalidationform = this.formBuilder.group({
    //   firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
    //   lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
    //   userName: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
    //   city: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
    //   state: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
    // });

    this.SupplierList= ['Alaska', 'Jaswinder Singh', 'California', 'Nevada', 'Oregon', 'Washington', 'Arizona', 'Colorado', 'Idaho', 'Montana', 'Nebraska', 'New Mexico', 'North Dakota', 'Utah', 'Wyoming', 'Alabama', 'Arkansas', 'Illinois', 'Iowa'];

    /**
     * Type validation form
     */
    // this.typeValidationForm = this.formBuilder.group({
    //   text: ['', [Validators.required]],
    //   email: ['', [Validators.required, Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
    //   url: ['', [Validators.required, Validators.pattern('https?://.+')]],
    //   digits: ['', [Validators.required, Validators.pattern('[0-9]+')]],
    //   number: ['', [Validators.required, Validators.pattern('[0-9]+')]],
    //   alphanum: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9]+')]],
    //   textarea: ['', [Validators.required]],
    //   password: ['', [Validators.required, Validators.minLength(6)]],
    //   confirmpwd: ['', Validators.required]
    // }, {
    //     validator: MustMatch('password', 'confirmpwd'),
    //   });


    /**
     * Range validation form
     */
    // this.rangeValidationForm = this.formBuilder.group({
    //   minlength: ['', [Validators.required, Validators.minLength(6)]],
    //   maxlength: ['', [Validators.required, Validators.maxLength(6)]],
    //   rangelength: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(10)]],
    //   minvalue: ['', [Validators.required, Validators.min(6)]],
    //   maxvalue: ['', [Validators.required, Validators.max(6)]],
    //   rangevalue: ['', [Validators.required, Validators.min(6), Validators.max(100)]],
    //   regularexp: ['', [Validators.required, Validators.pattern('#[A-Fa-f0-9]{6}')]],
    // });
    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }

  /**
   * Bootsrap validation form submit method
   */
  validSubmit() {
    this.submit = true;
  }

  /**
   * returns tooltip validation form
   */
  get formData() {
    return this.tooltipvalidationform.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;
  }

  /**
   * Returns the type validation form
   */
  get type() {
    return this.typeValidationForm.controls;
  }

  /**
   * Type validation form submit data
   */
  typeSubmit() {
    this.typesubmit = true;
  }

  /**
   * Returns the range validation form
   */
  get range() {
    return this.rangeValidationForm.controls;
  }

  checkSupplier():boolean
  {
    if(this.submit && !this.validationform.controls.SupplierName.value){
    return true;
  }
  else
  {
    return false;
  }
  }
  /**
   * range validation submit data
   */
  rangeSubmit() {
    this.rangesubmit = true;
  }
  addProducts()
{
  this.TotalQty=0;
  var arr_prd=this.SearchItems.filter(row=> row.PurQty>0);
  arr_prd.forEach(element => {
    this.ProductArray.push(
      {
'Description' : element.Description,
'PurPrice' : element.PurPrice,
'PurQty' : element.PurQty,

      }
    )
  });
  this.SearchItems=[];
  this.searchSize='';
  this.SearchPanelVisible=false;
  this.ProductArray.forEach(element => {
    this.TotalQty  += +element.PurQty;   
  });
 
}
searchPrdFromDB(item){
  try {
    this.SearchPanelVisible=false;
    this.SearchItems=[];
    this.spinner.show();
    this.setup.getSearchProducts(this.searchSize)
    .subscribe({
      next(list) {
        fetchData(list);
      }
    });
    const fetchData = (data: any) => {
      this.spinner.hide();
      if(data.length>0){
        this.SearchItems=data;
this.SearchPanelVisible=true;
      }
    };
  } catch (error) {
    this.spinner.hide();
    console.log(error);
    this.toaster.error(error);
  }
}
}
