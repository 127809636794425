import { Component, OnInit, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { DecimalPipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
//import { tableData } from './data';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { Router } from '@angular/router';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MENU } from 'src/app/layouts/shared/sidebar/menu';
import { MenuItem } from 'src/app/layouts/shared/sidebar/menu.model';

@Component({
  selector: 'app-UserAccountMapping',
  templateUrl: './UserAccountMapping.component.html',
  styleUrls: ['./UserAccountMapping.component.scss'],
  providers: [DecimalPipe]
})

/**
 * Advanced table component
 */
export class UserAccountMappingComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];
  tableData: any[];
  worklist: any[];
  searchlist: any[];
  work: any[];
  // Table data
  searchString: string;
  pager: any = {};
  total$: Observable<number>;
  tablesData$: Observable<any>;
  validationform: FormGroup;
  pdfdoc: any;
  UserRoleList: any;
  UserRoleSelected: string;
  page: any;
  Chk_SelectAll: boolean;
  @ViewChild(UserAccountMappingComponent) table: UserAccountMappingComponent[];

  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private _router: Router,
    private _session: SessionCheck,
    private modalService: NgbModal) {
    // this.tablesData$ = service._tableData$;
  }
  ngOnInit() {
    this.validationform = this.formBuilder.group({
      UserId: [''],
    });

    this.breadCrumbItems = [{ label: 'Quotation' }, { label: 'Customer Quotation', active: true }];

    /**
     * fetch data
     */
    //this._fetchData();
    this.getRegularWorklist();

  }
  getRegularWorklist() {
    try {
      this.spinner.show();
      this.tableData = [];
      //this.tableData =MENU;
      // this.worklist = MENU;
      this.setup.GetRegularworkList('sp_AccountList').subscribe(
        {
          next(list) {
            fetchData2(list);
          }

        });
      const fetchData2 = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {

          this.spinner.hide();
          var data2 = data.ResultSets[0];
          data2.forEach(element => {
            this.tableData.push(
              {
                AccName: element.AccName,
                AccCode: element.AccCode,
                AccType: element.AccTypeName,
                AccId: element.Amid,
                select: false
              }
            )
          });
          this.worklist = this.tableData;
          this.work = this.worklist;
        }

      };


      //this.setPage(1);
      //          this.spinner.hide();
      this.setup.GetRegularworkList('sp_GetUserList', '')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        this.UserRoleList = [];
        if (data.ResultSets.length > 0) {
          this.UserRoleList = data.ResultSets[0];
          this.spinner.hide();
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  getRoleWiseMenulist() {
    try {
      this.spinner.show();
      const searchJson = {
        UserId: this.UserRoleSelected
      }
      this.tableData = [];
      this.setup.GetRegularworkList('sp_GetUserWiseAccList', JSON.stringify(searchJson))
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          var data = data.ResultSets[0];
          this.worklist.forEach(e => {
            e.select = false;
            var result = data.filter(r => r.AcId == e.AccId)
            if (result != undefined && result.length > 0) {
              e.select = result[0].selected

            }
          })
          this.spinner.hide();
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }

  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * 
   * fetches the table value
   */
  _fetchData() {
    // this.tableData = tableData;
    // for (let i = 0; i <= this.tableData.length; i++) {
    //   this.hideme.push(true);
    // }
  }



  setPage(page: number) {
    this.pager = this.getPager(this.searchString != '' ? this.work.length : this.worklist.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    //  this.pager = this.getPager(this.worklist.length, page);
    this.work = this.searchString != '' ? this.work.slice(this.pager.startIndex, this.pager.endIndex + 1) : this.worklist.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  setPageSearch(page: number) {
    this.pager = this.getPager(this.table.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    this.work = this.table.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }


  searchExistingRecords(event: any) {

    var val = event.target.value.toLowerCase();
    //this._main.rndWorklistSearch = event.target.value.toLowerCase();
    if (val != '') {
      const list = this.worklist.filter(function (d) {
        var ColumnsData = '';
        var columnsName = ['AccName', 'AccCode', 'AccType']
        columnsName.forEach(col => {
          ColumnsData += ' ' + d[col]
        });
        return ColumnsData.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.work = list;
      this.table = list;
      //this.downloadWrk = list;
      // Whenever the filter changes, always go back to the first page.
      this.setPageSearch(this.page);
    }
    else {
      this.table = this.worklist;

      this.table = this.worklist;
      this.setPageSearch(this.page);
    }
  }


  public fixBinary(bin) {
    const length = bin.length;
    const buf = new ArrayBuffer(length);
    const arr = new Uint8Array(buf);
    for (let i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }

  openModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'xl' });
  }
  validSubmit() {
    this.getRegularWorklist();
  }
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }
  saveMenuList() {

    if (this.validationform.valid) {
      if (this.worklist.length == 0) {
        this.toaster.error('Please check List');
        return;
      }
      try {
        this.spinner.show();
        const JsonMaster = {
          UserId: this.UserRoleSelected

        }
        this.setup.addRecord(this.UserRoleSelected, JSON.stringify(JsonMaster),
          JSON.stringify(this.worklist), 'sp_AddUserAccMapping')
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data[0].Status == 1) {
            this.toaster.success(data[0].ResponseMessage);
          }
          else {
            this.toaster.error(data[0].ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }
    }
    else {
      this.toaster.error('Please enter required values');
    }

  }
  changeSelectAll()
  {
    this.work.forEach(e=>
      {
        e.select=this.Chk_SelectAll
      }
      )
  }
}
