<div class="container-fluid">
  <div class="row">
    <div class="col-10">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">Commercial Invoice</h4>
      </div>
    </div>
    <div class="col-2">
      <button *ngIf="EntryID > 0 && isApproved" class="btn btn-success btn-round" disabled>
        <i class="ri-check-line align-middle mr-2"></i>Approved</button>     
    </div>
  </div>
<br>
  <!-- <app-pagetitle title="Customer Quotation" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label for="AccCode">Customer Name</label>
                <ng-select name="AccCode" [items]="SupplierList" bindLabel="AccName" bindValue="Amid"
               [disabled]="checkAccDisabled()"   [(ngModel)]="selectedAccID2"></ng-select>
                <div class="invalid-feedback" *ngIf="checkSupplier2()">
                  Please provide Customer
                </div>
              </div>
            </div>
          </div>
                    <h4 class="card-title">Select Product</h4>
          <div class="card">
            <input class="form-control" [(ngModel)]="searchSize" placeholder="Size" id="searchSize" name="searchSize"
              (keydown.enter)="searchPrdFromDB($event)" />


          </div>
          <div *ngIf="SearchPanelVisible" class="table-responsive">
            <table class="table table-hover mb-0">

              <thead>
                <tr class="TableHeaderRow">
                  <th>#</th>
                  <th>Description</th>
                  <th>Order No</th>
                  <th>Order Date</th>
                  <th>Order Qty</th>
                  <th>Booked Qty</th>
                  <th>Invoice Qty</th>
                  <th>Rate</th>
                  <th>Volume</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of SearchItems;let i=index">
                  <th scope="row">{{i+1}}</th>
                  <td>{{item.Description}}</td>
                  <td>{{item.OrderNo}}</td>
                  <td>{{item.OrderDate}}</td>
                  <td>{{item.OrderQty}}</td>
                  <td>{{item.BookedQty}}</td>
                  <td>
                    <input type="number" class="form-control" id="InvoiceQty + {{item.id}}" value="0"
                      [(ngModel)]="item.InvoiceQty">
                  </td>
                  <!-- <td>
                    <input type="number" (change)="validateRate($event,item)" class="form-control"
                      id="PurPrice + {{item.id}}" value="0" [(ngModel)]="item.PurPrice">
                    <div *ngIf="item.InvalidRate==true" class="invalidValidate">
                      Please check Rate
                    </div>
                  </td> -->
                  <td>{{item.OrderRate}}</td>
                  <td>{{item.PurQty/item.PrdContainerCapacity | number : '.2-2'}}</td>
                  <td>
                    <input type="text" class="form-control" id="Remarks + {{item.id}}" value="0"
                      [(ngModel)]="item.Remarks">
                  </td>
                </tr>
                <tr class="TotalRow">
                  <td></td>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>{{productTotal.POQty}}</td>
                  <td>{{productTotal.BookedQty}}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-lg-10"></div>
              <div class="col-lg-2">
                <button class="btn btn-warning" (click)="addProducts()">Add & Continue</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
  <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-5">
                <div class="form-group">
                  <label for="AccCode">Customer Name</label>
                  <ng-select formControlName="AccCode" name="AccCode" id="AccCode"
                    [items]="SupplierList"  bindLabel="AccName" bindValue="Amid" 
                    [(ngModel)]="selectedAccID"></ng-select>
                  <div class="invalidValidate" *ngIf="checkSupplier()">
                    Please provide Customer
                  </div>
                </div>

              </div>
              

              <div class="col-md-3">
                <div class="form-group">
                  <label for="DocNo">Invoice No</label>
                  <input class="form-control" formControlName="DocNo" id="DocNo"
                    [ngClass]="{'is-invalid': submit && form.DocNo.errors}">
                  <div *ngIf="submit && form.DocNo.errors" class="invalid-feedback">
                    Please provide Invoice No
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="DocDate">Invoice Date</label>
                  <input type="date" class="form-control" formControlName="DocDate"
                   id="DocDate"
                    [ngClass]="{'is-invalid': submit && form.DocDate.errors}">
                  <div *ngIf="submit && form.DocDate.errors" class="invalid-feedback">
                    Please provide Invoice Date
                  </div>
                </div>
              </div>
            </div>
                  <div class="row">
              <!-- <div class="col-md-3">
                <div class="form-group">
                  <label for="DeliveryDate">Delivery Date</label>
                  <input type="date" class="form-control" formControlName="DeliveryDate" id="DeliveryDate"
                    [ngClass]="{'is-invalid': submit && form.DeliveryDate.errors}">
                  <div *ngIf="submit && form.DeliveryDate.errors" class="invalid-feedback">
                    Please provide Delivery Date
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-md-2">
              </div> -->
              <div class="col-md-6">
                <div class="form-group">
                  <label for="MasterRemarks">Remarks</label>
                  <input type="text" class="form-control" formControlName="MasterRemarks" id="MasterRemarks">
                </div>
              </div>
            </div>
              
            
          </div>
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Product Detail</h4>

            <div class="table-responsive">
              <table class="table table-hover mb-0">

                <thead>
                  <tr class="TableHeaderRow">
                    <th>#</th>
                    <th>Description</th>
                    <th>Order No</th>
                    <th>Order Date</th>
                    <th>Order Qty</th>
                    <th>Booked Qty</th>
                    <th>Invoice Qty</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Volume</th>
                    <th>Remarks</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of ProductArray;let i=index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.Description}}</td>
                    <td>{{item.OrderNo}}</td>
                    <td>{{item.OrderDate}}</td>
                    <td>{{item.OrderQty}}</td>
                    <td>{{item.BookedQty}}</td>
                    <td>{{item.InvoiceQty}}</td>
                    <td>{{item.OrderRate}}</td>
                    <td>{{item.Amount | number : '.2-2'}}</td>
                    <td>{{item.Volume}}</td>
                    <td>{{item.Remarks}}</td>
                    <td>
                      <button type="button" class="mdi mdi-18px mdi-delete text-danger"
                        (click)="DeletePrdItem(item,i)"></button>
                    </td>
                  </tr>
                  <tr class="TotalRow">
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td>{{grandTotal.InvoiceQty}}</td>
                    <td>{{grandTotal.BookedQty}}</td>
                    <td>{{grandTotal.OrderQty}}</td>
                    <td></td>
                    <td>{{grandTotal.Amount | number : '.2-2'}}</td>
                    <td>{{grandTotal.Volume}}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <table style="width:100% ;">
              <tr>
                <td style="width:50% ;">
                  <div class="form-group">
                    <label for="PaymentTerms">Payment Terms</label>
                    <textarea rows="4" class="form-control" formControlName="PaymentTerms" id="PaymentTerms"></textarea>
                  </div>
                    
                </td>
                <td style="width:50% ;">
            <div class="row">
              <div class="col-md-2">

              </div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                  </div>
                    <div class="col-md-4">
                     <label>Insurnace Charges</label>
                  </div>
                  <div class="col-md-4">
                    <input type="number" (change)="calculateGrandTotal()" class="form-control" style="text-align: right ;" formControlName="Insurance" id="Insurance">
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                  </div>
                  <div class="col-md-4">
                     <label>Freight</label>
                  </div>
                  <div class="col-md-4">
                    <input type="number" (change)="calculateGrandTotal()"class="form-control" style="text-align: right ;"formControlName="Freight" id="Freight">
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                  </div>
                  <div class="col-md-4">
                     <label>Other Charges</label>
                  </div>
                  <div class="col-md-4">
                    <input type="number" (change)="calculateGrandTotal()" class="form-control" style="text-align: right ;"formControlName="OtherCharges" id="OtherCharges">
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-md-2">
              </div>
                <div class="col-md-10">
                  <div class="form-group">
                    <div class="row">
                      <div class="col-md-4">
                      </div>
                          <div class="col-md-4">
                         <label> Sub Total</label>
                      </div>
                      <div class="col-md-4">
                        <input disabled readonly style="text-align: right ;" 
                        class="form-control"  id="SubTotal" formControlName="SubTotal" >
                      </div>
                    </div>
                     
                    
                       
                    </div>
                  </div>
                  <div class="col-lg-2">
                </div>
                  <div class="col-lg-2">
              </div>
             </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                  </div>

                  <div class="col-md-4">
                     <label>Discount</label>
                  </div>
                  <div class="col-md-4">
                    <input (change)="calculateGrandTotal()" 
                  type="number"  class="form-control" style="text-align: right ;" 
                    formControlName="Discount" id="Discount">
                  </div>
                </div>

              </div>
            </div>
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                  </div>
                  <div class="col-md-4">
                     <label>Grand Total</label>
                  </div>
                  <div class="col-md-4">
                    <input readonly disabled class="form-control" style="text-align: right ;" formControlName="GrandTotal" id="GrandTotal">
                  </div>
                </div>

              </div>
            </div>
          </td>
                  </tr>
        </table>

            </div>
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>

   
  
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-8">
              </div>
              
                <div class="col-lg-2">
                </div>
                  <div class="col-lg-2" *ngIf="!isApproved">
                  <br>
                  <div class="card">
                    <button class="btn btn-primary" *ngIf="!isApproved" type="submit">Save</button>
                </div>
              </div>
        

            </div>
            </div>
      
            
            
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
    <!-- end row -->

  </form>


</div>