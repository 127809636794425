import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './PaymentEntryPurchase.mustmatch';
//import { icons } from '../data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-validation',
  templateUrl: './PaymentEntryPurchase.component.html',
  styleUrls: ['./PaymentEntryPurchase.component.scss']
})

/**
 * Forms Validation component
 */
export class PaymentEntryPurchaseComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form
  SupplierList: any = [];
  selectedAccID: string;
  selectedAccID2: string;
  pendingInvoiceList: any=[];
  PaymentTypes:any=[];
  validateTableRates: boolean = true;
  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router: Router
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;
  SearchItems: any = [];
  searchSize: string;
  selectedItemArray: any = [];
  ProductArray: any = [];
  SearchPanelVisible: boolean;
  TotalQty: any;
  TotalAmount: any;
  EntryID: any;
  editMode: boolean;
  grandTotal: any = [];
  productTotal: any = [];
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Packing List', active: true }];
    this.EntryID = 0;
    this.EntryID = this.route.snapshot.queryParamMap.get('Id') ? this.route.snapshot.queryParamMap.get('Id') : '';
    this.editMode = false;
    this.PaymentTypes.push('TT');
    this.PaymentTypes.push('LC');
    /**
     * Bootstrap validation form data
     */
    this.validationform = this.formBuilder.group({
      DocNo: ['', [Validators.required]],
      DocDate: ['', [Validators.required]],
      AccCode: ['', [Validators.required]],
     
    });
    this.getAccountList();

    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;
    if (+this.EntryID > 0) {
      this.editRecord(this.EntryID);
    }
  }

  get form() {
    return this.validationform.controls;
  }

  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      const pendingInvoiceList2=this.pendingInvoiceList.filter(r=> r.RecAmount!=0);
      if (pendingInvoiceList2==undefined || pendingInvoiceList2.length == 0) {
        this.toaster.error('Please check Payment List');
        return;
      }
      try {
        this.SearchPanelVisible = false;
        this.SearchItems = [];
        this.spinner.show();
        var JsonMaster = (this.validationform.value)
        JsonMaster.VType = 'P';
        this.setup.addRecord(this.EntryID, JSON.stringify(JsonMaster), JSON.stringify(pendingInvoiceList2), 'sp_addPayment')
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data[0].Status == 1) {
            this.toaster.success(data[0].ResponseMessage);
            this.router.navigate(['/form/paymentListPurchase']);
          }
          else {
            this.toaster.error(data[0].ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }
    }
    else {
      this.toaster.error('Please enter required values');
    }
  }
  formSubmit() {
    this.formsubmit = true;

  }
  checkSupplier(): boolean {
    if (this.submit && !this.selectedAccID) {
      return true;
    }
    else {
      return false;
    }
  }
  addProducts() {
    if (!this.validateTableRates) {
      this.toaster.error('Please verify rates');
      return;
    }
    var arr_prd = this.SearchItems.filter(row => row.InvoiceQty > 0);
    arr_prd.forEach(element => {
      element.Volume = (element.InvoiceQty / element.PrdContainerCapacity).toFixed(2);
      element.Amount = (element.OrderRate * element.InvoiceQty).toFixed(2),
        this.ProductArray.push(element);
    });
    this.SearchItems = [];
    this.searchSize = '';
    this.SearchPanelVisible = false;
    this.calculateTotal();
  }
  calculateTotal() {
    this.grandTotal = [];
    this.grandTotal.OrderQty = 0;
    this.grandTotal.Amount = 0;
    this.grandTotal.Volume = 0;
    this.grandTotal.InvoiceQty = 0;
    this.grandTotal.BookedQty = 0;
    this.ProductArray.forEach(element => {
      this.grandTotal.OrderQty += +element.OrderQty;
      this.grandTotal.Amount += +element.Amount;
      this.grandTotal.Volume += +element.Volume;
      this.grandTotal.InvoiceQty += +element.InvoiceQty;
      this.grandTotal.BookedQty += +element.BookedQty;
    });
    this.grandTotal.Volume = this.grandTotal.Volume.toFixed(2);
  }
  searchPrdFromDB(item) {
    try {
      if (!this.checkSupplier2()) {
        this.toaster.error('Please select Customer to add products');
        return;
      }
      this.selectedAccID = this.selectedAccID2;
      this.SearchPanelVisible = false;
      this.SearchItems = [];
      this.spinner.show();
      const searchJson = {
        PrdName: this.searchSize,
        AcId: this.selectedAccID2,
        DocType: 'SL'
      }
      this.setup.getSearchList(JSON.stringify(searchJson), 'dbo.sp_GetSLPrdSearch')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.SearchItems = data;
          this.SearchPanelVisible = true;
          this.productTotal.OrderQty = 0;
          this.productTotal.Amount = 0;
          this.productTotal.InvoiceQty = 0;
          this.productTotal.BookedQty = 0;
          this.SearchItems.forEach(element => {
            this.productTotal.OrderQty += +element.PurQty;
            this.productTotal.Amount += +element.Amount;
            this.productTotal.InvoiceQty += +element.InvoiceQty;
            this.productTotal.BookedQty += +element.BookedQty;
            element.InvalidRate = false;
          });
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  DeletePrdItem(item, iRowIndex) {
    this.ProductArray.splice(iRowIndex, 1);
    this.calculateTotal();
  }
  editRecord(EntryID) {
    try {
      this.setup.GetRecordInfo(this.EntryID, 'sp_GetPaymentRecordpUR')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.fillEditRecordData(data);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  getAccountList() {
    try {
      this.setup.GetAccountList('Supplier')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.SupplierList = data.ResultSets[0];
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  getPendingInvoiceList() {
    try {
      this.spinner.show();
      const SearchString =
      {
        AcId: this.selectedAccID
      }
      this.setup.GetRegularworkList('sp_PendingPaymentsPurchaseFromCI', JSON.stringify(SearchString))
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.pendingInvoiceList = data.ResultSets[0];
          if (this.EntryID == 0) {
            this.validationform.patchValue(
              {
                DocNo: data.ResultSets[1][0].DocNo
              }
            )
          }
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  fillEditRecordData(data) {
    data.forEach(element => {
      var keys = Object.keys(element);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { }
      })
    });
    this.editMode = true;
    this.selectedAccID2 = data[0].Amid;
    this.selectedAccID = data[0].Amid;
    this.ProductArray = [];
    data.forEach(element => {
      this.ProductArray.push(element)
    });
    this.pendingInvoiceList=data;
    this.calculateTotal();
  }
  validateRate(event, item) {
    this.validateTableRates = true;
    item.InvalidRate = false;
    if (event.target.value < item.SalePrice && item.PurQty > 0) {
      item.InvalidRate = true;
      this.validateTableRates = false;
    }
  }
  checkSupplier2(): boolean {
    if (this.selectedAccID2 == undefined || !this.selectedAccID2) {
      return false;
    }
    else {
      return true;
    }
  }
  checkAccDisabled() {
    if (this.ProductArray != undefined && this.ProductArray.length > 0) {
      return true;
    }
    return false;
  }

}
