<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen] = "true"></ngx-spinner>

<div class="container-fluid">
  <app-pagetitle title="Account Setup" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Purchase Order List</h4> -->
          <form class="needs-validation" (ngSubmit)="validSearchSubmit()" [formGroup]="searchform">

            <div class="row">
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="AccountType">Account Type</label>
                    <ng-select formControlName="AccountType" name="AccountType" id="AccountType"
                    [items]="accountTypesSearch"  bindLabel="Name" bindValue="TypeCode" 
                    [(ngModel)]="searchselectedTypeID"></ng-select>
                  </div>
                </div>

            <div class="col-md-3">
                  <div class="form-group">
                <label for="ActiveTagSearch">Active</label>
                <ng-select formControlName="ActiveTagSearch" name="ActiveTagSearch" id="ActiveTagSearch"
                [items]="ActiveTagListSearch"  bindLabel="Name" bindValue="Code" 
                [(ngModel)]="ActiveTagCodeSearch"></ng-select>
              </div>
            </div>

            <div class="col-md-2">
                  <div class="form-group">
                    <br>
                    <button class="btn btn-rounded  btn-warning " type="submit">
                      <i class="fa fa-search"></i>&nbsp; Search
                          </button>
                        </div>
                      </div>
  
              </div>
  
            </form>          
        
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
                <button type="button"  class="btn btn-primary tblebtn w-md"
                        (click)="resetAndopenModal()"><i class="mdi mdi-plus"></i>Add New</button>
      
              <!-- <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Show
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> entries</label></div> -->
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div class="row navbar">
                <div class="col-6 d-flex justify-content-left">
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <div class="row">
                        <div>
                            <input class="form-control" [(ngModel)]="searchString" placeholder="Search" id="searchString"
                                name="searchString" (keyup)="searchExistingRecords($event)" />
                        </div>
                        <!-- <div>
                            <button class="btn btn-primary" (click)="searchDBRecords()"><i
                                    class="ti-search m-r-5 m-l-5"></i></button>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table #table class="table table-hover m-t-20 table-bordered table-striped">
                <thead class="bg-info text-white">
                    <tr class="">
                        <th>AccCode</th>
                        <th>Account Name</th>
                        <th>Type</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Country</th>
                        <th>MobileNo</th>
                        <th>Created Date</th>
                        <th>Created By</th>
                        <th>Active</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody class="border border-info">
                    <tr *ngFor="let item of work">

                        <td>{{item.AccCode}}</td>
                        <td>{{item.AccName}}</td>
                        <td>{{item.AccTypeName}}</td>
                        <td>{{item.City}}</td>
                        <td>{{item.State}}</td>
                        <td>{{item.Country}}</td>
                        <td>{{item.MobileNo}}</td>
                        <td>{{item.CreatedDate}}</td>
                        <td>{{item.UserName}}</td>
                        <td>{{item.IsActive}}</td>
                        <td>
                            <!-- <button type="button" class="btn btn-outline-info tblebtn"
                                (click)="downloadOriginalExcel(item,1)">
                                <i class="ti-download "></i> Download Excel File</button> 
                                &nbsp; <br/>-->
                                <a href="javascript:void(0);" (click)="editRecord(item.id)"  class="text-primary" ngbTooltip="Edit"><i
                                  class="mdi mdi-file-document-edit-outline font-size-22 "></i></a>
            
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ngb-pagination [collectionSize]="pager.totalItems " [pageSize]="pager.pageSize "
            [(page)]="pager.currentPage " [maxSize]="10 " [rotate]="true "
            (pageChange)="setPage(pager.currentPage) " [boundaryLinks]="true ">
        </ngb-pagination>

        </div>
      </div>
    </div>
  </div>



  <!-- Modal -->

</div>
