<div class="container-fluid">

  <app-pagetitle title="Purchase Order" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Select Product</h4>
          <div class="card">
            <input class="form-control" [(ngModel)]="searchSize" placeholder="Size" id="searchSize" name="searchSize"
              (keydown.enter)="searchPrdFromDB($event)" />


          </div>
          <div *ngIf="SearchPanelVisible" class="table-responsive">
            <table class="table table-hover mb-0">

              <thead>
                <tr>
                  <th>#</th>
                  <th>Description</th>
                  <th>PO Price</th>
                  <th>PO Qty</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of SearchItems;let i=index">
                  <th scope="row">{{i+1}}</th>
                  <td>{{item.Description}}</td>
                  <td>
                    <input type="text" class="form-control" id="Price + {{item.id}}" value="0"
                      [(ngModel)]="item.PurPrice">
                  </td>
                  <td>
                    <input type="text" class="form-control" id="Qty + {{item.id}}" value="0" [(ngModel)]="item.PurQty">

                  </td>
                </tr>
              </tbody>
            </table>
            
            <div class="row">
              <div class="col-lg-10"></div>
              <div class="col-lg-2">
                <button class="btn btn-warning" (click)="addProducts()">Add & Continue</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
  <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-5">
                <div class="form-group">
                  <label for="SupplierName">Supplier Name</label>
                  <ng-select formControlName="SupplierName" name="SupplierName" id="SupplierName"
                    [items]="SupplierList"  bindLabel="AccName" bindValue="Amid" 
                    [(ngModel)]="selectedSupplierID"></ng-select>
                  <div class="invalidValidate" *ngIf="checkSupplier()">
                    Please provide Supplier
                  </div>
                </div>

              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="PONo">PO No</label>
                  <input class="form-control" formControlName="PONo" id="PONo"
                    [ngClass]="{'is-invalid': submit && form.PONo.errors}">
                  <div *ngIf="submit && form.PONo.errors" class="invalid-feedback">
                    Please provide PONo
                  </div>
                </div>
              </div>

              <div class="col-md-2">
                <div class="form-group">
                  <label for="PODate">PO Date</label>
                  <input type="date" class="form-control" formControlName="PODate" id="PODate"
                    [ngClass]="{'is-invalid': submit && form.PODate.errors}">
                  <div *ngIf="submit && form.PODate.errors" class="invalid-feedback">
                    Please provide PO Date
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="ETCDate">Estimate Completion Date</label>
                  <input type="date" class="form-control" formControlName="ETCDate" id="ETCDate"
                    [ngClass]="{'is-invalid': submit && form.ETCDate.errors}">
                  <div *ngIf="submit && form.ETCDate.errors" class="invalid-feedback">
                    Please Provide Estimate Completion Date
                  </div>
                </div>
              </div>
             

            </div>
          </div>
        </div>
        <!-- end card -->
      </div> <!-- end col -->

    
    </div>
  </form>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Product Detail</h4>
  
            <div class="table-responsive">
              <table class="table table-hover mb-0">
  
                <thead>
                  <tr class="TableHeaderRow">
                    <th>#</th>
                    <th>Description</th>
                    <th>PO Price</th>
                   
                    <th>PO Qty</th>
                    
                    <th>Amount</th>
                    <th>Volume(%)</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of ProductArray;let i=index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.PrdName}}</td>
                    <td>{{item.Rate}}</td>
                    <!-- <td>{{item.Qty}}</td> -->
                    <input type="text" class="form-control" 
                    id="{{item.PrdId}}" value="{{item.Qty}}" 
                    [(ngModel)]="item.Qty" (change)="changePOQty(item)">

                    <td>{{item.Amount}}</td>
                    <td>{{item.Volume}} </td>
                    <td>
                      <button type="button" class="mdi mdi-18px mdi-delete text-danger" 
                      (click)="DeletePrdItem(item,i)"></button>
                    </td>
                  </tr>
                  <tr class="TotalRow">
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    
                    <td>{{TotalQty | number : '.2-2'}}</td>
                    <td>{{TotalAmount}}</td>
                    <td>{{TotalVolume}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
  
          </div>
        </div>
      </div>
  
    
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Payment Terms</h4>
            <div class="col-md-8">
              <div class="form-group">
                <textarea rows="3" placeholder="Payment Terms" 
                class="form-control" 
                id="PaymentTerms" [(ngModel)]="paymentTermsngModel"></textarea>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10">
      </div>
      <div class="col-lg-2">
            <div class="card">
             <button  class="btn btn-rounded btn-primary " (click)="validSubmit()">Save</button>
        </div>
      </div>
</div>
    <!-- end row -->
    
  <!-- <iframe width="100%" height="300"></iframe>
  <div class="col-lg-2">
    <div class="card">
     <button  class="btn btn-primary " (click)="PrintPO()" >Print</button>
</div>
</div> -->

</div>