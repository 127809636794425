<div class="container-fluid">

  <app-pagetitle title="Packing List (Sale)" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label for="AccCode">Customer Name</label>
                <ng-select name="AccCode" [items]="SupplierList" bindLabel="AccName" bindValue="Amid"
               [disabled]="checkAccDisabled()"   [(ngModel)]="selectedAccID2"></ng-select>
                <div class="invalid-feedback" *ngIf="checkSupplier2()">
                  Please provide Customer
                </div>
              </div>
            </div>
          </div>

          <h4 class="card-title">Select Product</h4>
          <div class="card">
            <input class="form-control" [(ngModel)]="searchSize" placeholder="Size" id="searchSize" name="searchSize"
              (keydown.enter)="searchPrdFromDB($event)" />


          </div>
          <div *ngIf="SearchPanelVisible" class="table-responsive">
            <table class="table table-hover mb-0">

              <thead>
                <tr class="TableHeaderRow">
                  <th>#</th>
                  <th>Description</th>
                  <th>Invoice No</th>
                  <th>Invoice Date</th>
                  <th>Invoice Rate</th>
                  <th>Invoice Qty</th>
                  <th>Packed Qty</th>
                  <th>Pen Qty</th>
                  <th>Load Qty</th>
                  <th>Volume</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of SearchItems;let i=index">
                  <th scope="row">{{i+1}}</th>
                  <td>{{item.Description}}</td>
                  <td>{{item.InvoiceNo}}</td>
                  <td>{{item.InvoiceDate}}</td>
                  <td>{{item.InvRate}}</td>
                  <td>{{item.InvQty}}</td>
                  <td>{{item.PackQty}}</td>
                  <td>{{item.PenQty}}</td>
                  <td>
                    <input type="number" class="form-control" id="Qty + {{item.id}}" value="0"
                      [(ngModel)]="item.Qty">
                  </td>
                  <td>{{item.Qty/item.PrdContainerCapacity | number : '.2-2'}}</td>
                </tr>
                <tr class="TotalRow">
                  <td></td>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{productTotal.InvQty}}</td>
                  <td>{{productTotal.PackQty}}</td>
                  <td>{{productTotal.PenQty}}</td>
                  <td>{{productTotal.Qty}}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-lg-10"></div>
              <div class="col-lg-2">
                <button class="btn btn-warning" (click)="addProducts()">Add & Continue</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
  <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-5">
                <div class="form-group">
                  <label for="AccCode">Customer Name</label>
                  <ng-select formControlName="AccCode" name="AccCode" id="AccCode"
                    [items]="SupplierList"  bindLabel="AccName" bindValue="Amid" 
                    [(ngModel)]="selectedAccID"></ng-select>
                  <div class="invalidValidate" *ngIf="checkSupplier()">
                    Please provide Customer
                  </div>
                </div>

              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="PackingNo">Packing No</label>
                  <input class="form-control" formControlName="PackingNo" id="PackingNo"
                    [ngClass]="{'is-invalid': submit && form.PackingNo.errors}">
                  <div *ngIf="submit && form.PackingNo.errors" class="invalid-feedback">
                    Please provide Packing No
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="PackingDate">Packing Date</label>
                  <input type="date" class="form-control" formControlName="PackingDate" id="PackingDate"
                    [ngClass]="{'is-invalid': submit && form.PackingDate.errors}">
                  <div *ngIf="submit && form.PackingDate.errors" class="invalid-feedback">
                    Please provide Packing Date
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="BLNo">BL No</label>
                  <input class="form-control" formControlName="BLNo" id="BLNo"
                    [ngClass]="{'is-invalid': submit && form.BLNo.errors}">
                  <div *ngIf="submit && form.BLNo.errors" class="invalid-feedback">
                    Please provide BLNo No
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="ArrivalDate">Arrival Date</label>
                  <input type="date" class="form-control" formControlName="ArrivalDate" id="ArrivalDate"
                    [ngClass]="{'is-invalid': submit && form.ArrivalDate.errors}">
                  <div *ngIf="submit && form.ArrivalDate.errors" class="invalid-feedback">
                    Please provide Arrival Date
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Product Detail</h4>

            <div class="table-responsive">
              <table class="table table-hover mb-0">

                <thead>
                  <tr class="TableHeaderRow">
                    <th>#</th>
                    <th>Description</th>
                    <th>Invoice No</th>
                    <th>Invoice Date</th>
                    <th>Invoice Rate</th>
                    <th>Invoice Qty</th>
                    <th>Load Qty</th>
                    <th>Pen Qty</th>
                    <th>Volume(%)</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of ProductArray;let i=index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.Description}}</td>
                    <td>{{item.InvoiceNo}}</td>
                    <td>{{item.InvoiceDate}}</td>
                    <td>{{item.InvRate}}</td>
                    <td>{{item.InvQty}}</td>
                    <td>{{item.Qty}}</td>
                    <td>{{item.PenQty}}</td>
                   <td> {{item.Volume}}</td>
                    <td>
                      <button type="button" class="mdi mdi-18px mdi-delete text-danger"
                        (click)="DeletePrdItem(item,i)"></button>
                    </td>
                  </tr>
                  <tr class="TotalRow">
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{grandTotal.InvQty}}</td>
                    <td>{{grandTotal.Qty}}</td>
                    <td>{{grandTotal.PenQty}}</td>
                    <td>{{grandTotal.Volume}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>

    <div class="row">
      <div class="col-lg-10">
      </div>
      <div class="col-lg-2">
        <div class="card">
          <button class="btn btn-rounded btn-primary " type="submit">
            Save</button>
        </div>
      </div>
    </div>
    <!-- end row -->

  </form>


</div>