import { Component, OnInit, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import { DecimalPipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
//import { tableData } from './data';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { Router } from '@angular/router';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import jspdf, {jsPDF} from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-SaleVoucherList',
  templateUrl: './SaleVoucherList.component.html',
  styleUrls: ['./SaleVoucherList.component.scss'],
})

/**
 * Advanced table component
 */
export class SaleVoucherListComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];
  tableData: any[];
  worklist: any[];
  searchlist: any[];
  work: any[];
  table:any[];
  // Table data
  searchString: string;
  pager: any = {};
  total$: Observable<number>;
  tablesData$: Observable<any>;
  validationform: FormGroup;
  title = 'Pending Payments (Purchase)';
  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService, 
    private toaster: ToastrService,
    private setup: SetupService,
    private _router: Router,
    private _session: SessionCheck) {
    // this.tablesData$ = service._tableData$;
  }
  ngOnInit() {
    this.validationform = this.formBuilder.group({
      FromDate: [''],
      ToDate: [''],
    });

    this.breadCrumbItems = [{ label: 'Purchase' }, { label: 'PaymentsListPurchase', active: true }];
    var date = new Date();
    date.setDate(date.getDate() - 60);
    this.validationform.patchValue(
      {
        FromDate: formatDate(date, "yyyy-MM-dd", "en-us"),
        ToDate: formatDate(new Date(), "yyyy-MM-dd", "en-us")
      });

      /**
     * fetch data
     */
    //this._fetchData();
    this.getWorklist();

  }
  getWorklist() {
    try {
      this.spinner.show();
      const searchjson = {
        FromDate: this.validationform.get('FromDate').value,
        ToDate: this.validationform.get('ToDate').value
      }
      this.setup.GetRegularworkList('sp_getEntryMasterList',"SaleInvoice")
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        this.work=[];
        this.tableData = [];
        this.worklist = [];
        if (data.ResultSets.length > 0) {
          this.tableData = data.ResultSets[0];
          this.worklist = data.ResultSets[0];
          this.setPage(1);
          this.spinner.hide();
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }

  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  setPage(page: number) {
    this.pager = this.getPager(this.worklist.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    //  this.pager = this.getPager(this.worklist.length, page);
    this.work = this.worklist.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  searchExistingRecords(event: any) {
    const val = event.target.value.toLowerCase();
    //const val = event.target.value;
    if (val != '') {
      const list = this.worklist.filter(function (d) {
        let ColumnsData = '';
        const columnsName = ['Dated', 'VoucherNo','Debit','Credit', 'CreatedDate', 'CreatedBy'];
        columnsName.forEach(col => {
          ColumnsData += ' ' + d[col];
        });
        return ColumnsData.toLowerCase().indexOf(val) !== -1 || !val;
      });
      // Whenever the filter changes, always go back to the first page.
      //this.tableData = list;
      this.table = list;
      this.work = list;
      this.setPageSearch(1);
    } else {
      this.table = this.worklist;
      this.setPageSearch(1);
    }
  }
  setPageSearch(page: number) {
    this.pager = this.getPager(this.table.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    this.work = this.table.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  editRecord(item) {
    this._router.navigate(['/entry/saleInvoice'], { queryParams: { Id: item.id } });
  }
  PrintPO(item) {
    try {
      const printItem = {
        id: item.POId,
        Name: 'po',
        returnurl: '/form/polist'
      }
      //    this._session.clearSession('doprint');
      //   this._session.setSessionStorage('doprint', JSON.stringify(printItem));
      this._router.navigate(['/form/print'], { queryParams: { POId: 1 } });

    }
    catch (error) {
      console.log(error);
    }
  }
  openPO() {
    this._router.navigate(['/entry/saleInvoice'], { queryParams: { Id: 0 } });
  }
  saveExcel() {
    const fitToColumn = (arrayOfArray) => {
      return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
    }

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(this.work, { skipHeader: false });
    //workSheet['!cols'] = fitToColumn(this.work);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    const ext='xlsx';
    var filename = 'document.'+ext;
      XLSX.writeFile(workBook, filename, { type: 'array', bookType: ext, bookSST: false }); // initiate a file download in browser

  }
  savePDF() {
    var data = document.getElementById('table');
    html2canvas(data).then(canvas => {
      var imgWidth = 200;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 2;
      pdf.addImage(contentDataURL, 'PNG', 2, position, imgWidth, imgHeight)
      //pdf.html(contentDataURL)
      pdf.save('document.pdf');  
    });
  }
  saveCSV() {
    const fitToColumn = (arrayOfArray) => {
      return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
    }

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(this.work, { skipHeader: false });
    //workSheet['!cols'] = fitToColumn(this.work);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    const ext='csv';
    var filename = 'document.'+ext;
      XLSX.writeFile(workBook, filename, { type: 'array', bookType: 'csv', bookSST: false }); // initiate a file download in browser
}
validSubmit() {
  this.getWorklist();
}

}
