import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ValidationComponent } from './validation/validation.component';
import { EditorComponent } from './editor/editor.component';
import { UploadsComponent } from './uploads/uploads.component';
import { WizardComponent } from './wizard/wizard.component';
import { MaskComponent } from './mask/mask.component';
import { AdvancedformComponent } from './advancedform/advancedform.component';
import { POFormComponent } from './POForm/POForm.component';
import { PoListComponent } from './POList/POList.component';
import { CommonPrintComponent } from './CommonPrint/CommonPrint.component';
import { PurchasePackingComponent } from './PurchasePacking/PurchasePacking.component';
import { SaleQuotationListComponent } from './SaleQuotationList/SaleQuotationList.component';
import { SaleQuotationEntryComponent } from './SaleQuotationEntry/SaleQuotationEntry.component';
import { PurchaseCommercialInvoiceComponent } from './PurchaseCommercialInvoice/PurchaseCommercialInvoice.component';
import { PurchaseCommercialInvoiceListComponent } from './PurchaseCommercialInvoiceList/PurchaseCommercialInvoiceList.component';
import { SaleOrderEntryComponent } from './SaleOrderEntry/SaleOrderEntry.component';
import { SaleOrderListComponent } from './SaleOrderList/SaleOrderList.component';
import { sizeMstComponent } from './sizeMst/sizeMst.component';
import { brandMstComponent } from './brandMst/brandMst.component';
import { ChangePasswordComponent } from './changePassword/changePassword.component';
import { SaleCommercialInvoiceListComponent } from './SaleCommercialInvoiceList/SaleCommercialInvoiceList.component';
import { SaleCommercialInvEntryComponent } from './SaleCommercialInvEntry/SaleCommercialInvEntry.component';
import { PendingPaymentsSaleComponent } from './PendingPaymentsSale/PendingPaymentsSale.component';
import { PaymentEntrySaleComponent } from './PaymentEntrySale/PaymentEntrySale.component';
import { PaymentsListSaleComponent } from './PaymentsListSale/PaymentsListSale.component';
import { PaymentsListPurchaseAdvanceComponent } from './PaymentsListPurchaseAdvance/PaymentsListPurchaseAdvance.component';
import { PendingPaymentsPurchaseComponent } from './PendingPaymentsPurchase/PendingPaymentsPurchase.component';
import { StockListComponent } from './StockList/StockList.component';
import { PaymentEntryPurchaseComponent } from './PaymentEntryPurchase/PaymentEntryPurchase.component';
import { UserPermListComponent } from './UserPermList/UserPermList.component';
import { roleMstComponent } from './roleMst/roleMst.component';
import { userMasterComponent } from './userMaster/userMaster.component';
import { UserAccountMappingComponent } from './UserAccountMapping/UserAccountMapping.component';
import { RateListUploadComponent } from './RateListUpload/RateListUpload.component';
import { SaleAdvanceEntryComponent } from './SaleAdvanceEntry/SaleAdvanceEntry.component';
import { SaleAdvanceListomponent } from './SaleAdvanceList/SaleAdvanceList.component';
import { salePackingListComponent } from './salePackingList/salePackingList.component';
import { SalePackingEntryComponent } from './SalePackingEntry/SalePackingEntry.component';
import { AdminSettingsComponent } from './AdminSettings/AdminSettings.component';
import { AccountStatementComponent } from './AccountStatement/AccountStatement.component';
 
const routes: Routes = [
   
    {
        path: 'size',
        component: sizeMstComponent
    },
    {
        path: 'brand',
        component: brandMstComponent
    },
    {
        path: 'changePassword',
        component: ChangePasswordComponent
    },
    {
        path: 'poform',
        component: POFormComponent
    },
    {
        path: 'polist',
        component: PoListComponent
    },
    {
        path: 'commercialInvoice',
        component: PurchaseCommercialInvoiceComponent
    },
    {
        path: 'commercialInvoiceList',
        component: PurchaseCommercialInvoiceListComponent
    },
    {
        path: 'packing',
        component: PurchasePackingComponent
    },
    {
        path: 'quotationList',
        component: SaleQuotationListComponent
    },
    {
        path: 'quotation',
        component: SaleQuotationEntryComponent
    },
    {
        path: 'saleorder',
        component: SaleOrderEntryComponent
    },
    {
        path: 'saleOrderList',
        component: SaleOrderListComponent
    },
    {
        path: 'saleCommercialList',
        component: SaleCommercialInvoiceListComponent
    },
    {
        path: 'saleCommercialEntry',
        component: SaleCommercialInvEntryComponent
    },
    {
        path: 'pendingpaymentListSale',
        component: PendingPaymentsSaleComponent
    },
    {
        path: 'paymentListSale',
        component: PaymentsListSaleComponent
    },
    {
        path: 'paymentEntrySale',
        component: PaymentEntrySaleComponent
    },
    {
        path: 'pendingpaymentListPurchase',
        component: PendingPaymentsPurchaseComponent
    },
    
    {
        path: 'paymentListPurchaseAdvance',
        component: PaymentsListPurchaseAdvanceComponent
    },
    
    {
        path: 'paymentEntryPurchase',
        component: PaymentEntryPurchaseComponent
    },
    {
        path: 'stockList',
        component: StockListComponent
    },
    
    {
        path: 'print',
        component: CommonPrintComponent
    },
    {
        path: 'validation',
        component: ValidationComponent
    },
    {
        path: 'editor',
        component: EditorComponent
    },
    {
        path: 'uploads',
        component: UploadsComponent
    },
    {
        path: 'wizard',
        component: WizardComponent
    },
    {
        path: 'mask',
        component: MaskComponent
    },
    {
        path: 'advanced',
        component: AdvancedformComponent
    },
    {
        path: 'userPerm',
        component: UserPermListComponent
    },
    {
        path: 'userRoles',
        component: roleMstComponent
    },
    {
        path: 'userMaster',
        component: userMasterComponent
    },
    {
        path: 'rateListUpload',
        component: RateListUploadComponent
    },
    {
        path: 'saleAdvance',
        component: SaleAdvanceEntryComponent
    },
    {
        path: 'saleAdvanceList',
        component: SaleAdvanceListomponent
    },
    {
        path: 'userAccountMap',
        component: UserAccountMappingComponent
    },
    {
        path: 'salePackingList',
        component: salePackingListComponent
    },
    {
        path: 'salePacking',
        component: SalePackingEntryComponent
    },
    {
        path: 'adminSettings',
        component: AdminSettingsComponent
    },
    {
        path: 'accountStatement',
        component: AccountStatementComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FormRoutingModule { }
