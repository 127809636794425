import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './TaxHeadCreationList.mustmatch';
import { icons } from './data';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'underscore';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ThisReceiver } from '@angular/compiler';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';

@Component({
  selector: 'app-TaxHeadCreationList',
  templateUrl: './TaxHeadCreationList.component.html',
  styleUrls: ['./TaxHeadCreationList.component.scss']
})

/**
 * Forms Validation component
 */
export class TaxHeadCreationListComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form
  searchform: FormGroup; // bootstrap validation form
  searchselectedTypeID: string;
  ActiveTagCode: string;
  selectedTypeID: string;
  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private session:SessionCheck
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;
  tableData: any[];
  table: any[];
  worklist: any[];
  searchlist: any[];
  work: any[];
  pager: any = {};
  searchString: string;
  // Form submition
  submit: boolean;
  formsubmit: boolean;
  SearchItems: any = [];
  ID: any;
  TaxTypes: any[];
  TaxTypesSearch: any[];
  ActiveTagList: any[];
  ActiveTagListSearch: any[];
  ActiveTagCodeSearch: string;
  maxAccCode: string;
  roleName:string;
  ngOnInit() {
    this.TaxTypes =
      [
        { TypeCode: '1', Name: 'Customer' },
        { TypeCode: '2', Name: 'Supplier' },
        { TypeCode: '3', Name: 'Other' },
      ];
    this.TaxTypesSearch =
      [
        { TypeCode: '1', Name: 'All' },
        { TypeCode: '2', Name: 'Customer' },
        { TypeCode: '3', Name: 'Supplier' },
        { TypeCode: '4', Name: 'Other' }
      ];
    this.ActiveTagList =
      [
        { Code: 'Y', Name: 'Y' },
        { Code: 'N', Name: 'N' }
      ];
    this.ActiveTagListSearch =
      [
        { Code: 'A', Name: 'ALL' },
        { Code: 'Y', Name: 'Y' },
        { Code: 'N', Name: 'N' }
      ];


    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Tax', active: true }];
    /**
     * Bootstrap validation form data
     */
    this.searchform = this.formBuilder.group({
      TaxType: [''],
      ActiveTagSearch: [''],

    });

    this.validationform = this.formBuilder.group({
      AccCode: [''],
      AccType: ['', [Validators.required]],
      AccName: ['', [Validators.required]],
      Address: ['', [Validators.required]],
      City: ['', [Validators.required]],
      State: ['', [Validators.required]],
      ZipCode: ['', [Validators.required]],
      Country: ['', [Validators.required]],
      ContactName: [''],
      ContactNo: [''],
      MobileNo: [''],
      Email: ['', [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      SaleMargin: [''],
      MobileCode: ['', [Validators.required]],
      CountryCode: ['', [Validators.required]],
      AccAsgnCode: ['', [Validators.required]],
      ActiveTag: ['', [Validators.required]],
    });

    this.searchselectedTypeID = "1";
    this.ActiveTagCodeSearch = 'A';
    this.getWorklist();
    this.ID = 0;
    this.roleName=this.session.getSessionStorage('Role');
    // if(+this.ID>0)
    // {
    //   this.editRecord(this.ID);
    // }
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }

  /**
   * Bootsrap validation form submit method
   */
  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      try {
        this.spinner.show();
        const JsonMaster = (this.validationform.value)

        this.setup.addAccountMst(this.ID, JSON.stringify(JsonMaster), '')
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data[data.length - 1].Status == 1) {
            this.modalService.dismissAll();
            this.toaster.success(data[0].ResponseMessage);
            this.getWorklist();
          }
          else {
            this.toaster.error(data[0].ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }


    }
  }
  formSubmit() {
    this.formsubmit = true;

  }
  checkSupplierType(): boolean {
    if (this.submit && !this.selectedTypeID) {
      return true;
    }
    else {
      return false;
    }
  }
  // getTaxList() {
  //   try {
  //     this.spinner.show();
  //     var searchname = this.TaxTypesSearch.filter(r => r.TypeCode == this.searchselectedTypeID);

  //     const searchjson = {
  //       TaxType: searchname[0].Name,
  //       ActiveTag: this.ActiveTagCodeSearch,
  //     }
  //     this.setup.GetAccountListSearch('ALL', JSON.stringify(searchjson))
  //       .subscribe({
  //         next(list) {
  //           fetchData(list);
  //         }
  //       });
  //     const fetchData = (data: any) => {
  //       this.spinner.hide();
  //       this.tableData = [];
  //       this.worklist = [];
  //       this.work = [];
  //       if (data.ResultSets.length > 0) {
  //         this.tableData = data.ResultSets[0];
  //         this.worklist = data.ResultSets[0];
  //         this.setPage(1);
  //         this.spinner.hide();
  //         this.maxAccCode = data.ResultSets[1][0].MaxAccCode;
  //       }
  //     };
  //   } catch (error) {
  //     this.spinner.hide();
  //     console.log(error);
  //     this.toaster.error(error);
  //   }
  // }
  getWorklist() {
    try {
      this.spinner.show();
      // const searchjson = {
      //   FromDate: this.validationform.get('FromDate').value,
      //   ToDate: this.validationform.get('ToDate').value
      // }
      this.setup.GetRegularworkList('sp_getMasterList',"TaxList")
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        this.work=[];
        this.tableData = [];
        this.worklist = [];
        if (data.ResultSets.length > 0) {
          this.tableData = data.ResultSets[0];
          this.worklist = data.ResultSets[0];
          this.setPage(1);
          this.spinner.hide();
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }









  editRecord(ID) {
    this.router.navigate(['/master/TaxSetup'], { queryParams: { Id: ID } });
    // try {
    //   this.setup.GetRecordInfo(ID, 'sp_GetActmstRecord')
    //     .subscribe({
    //       next(list) {
    //         fetchData(list);
    //       }
    //     });
    //   const fetchData = (data: any) => {
    //     this.spinner.hide();
    //     if (data.length > 0) {
    //       this.fillEditRecordData(data, modal);
    //     }
    //   };
    // } catch (error) {
    //   this.spinner.hide();
    //   console.log(error);
    //   this.toaster.error(error);
    // }

  }
  fillEditRecordData(data, modal) {
    data.forEach(element => {
      var keys = Object.keys(element);
      var values = Object.values(element);
      const JsonMaster = (this.validationform.value);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { }
      })
    });

    this.selectedTypeID = data[0].AccType;
    this.ID = data[0].Amid;
    this.ActiveTagCode = data[0].ActiveTag;
    this.roleName=data[0].RoleName;
    this.openModal(modal);
  }
  setPage(page: number) {
    this.pager = this.getPager(this.worklist.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    //  this.pager = this.getPager(this.worklist.length, page);
    this.work = this.worklist.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  searchExistingRecords(event: any) {
    const val = event.target.value.toLowerCase();
    // const columnsName =Object.keys(this.worklist[0]);
    //const val = event.target.value;
    if (val != '') {
      const list = this.worklist.filter(function (d) {
        let ColumnsData = '';
        const columnsName = ['th_code', 'HEAD_NAME', 'tax_per', 'TRANSFER_HEAD', 'CreatedDate','UserName'];
        // const columnsName =Object.keys(this.worklist);
        columnsName.forEach(col => {
          ColumnsData += ' ' + d[col];
        });
        return ColumnsData.toLowerCase().indexOf(val) !== -1 || !val;
      });
      // Whenever the filter changes, always go back to the first page.
      //this.tableData = list;
      this.table = list;
      this.work = list;
      this.setPageSearch(1);
    } else {
      this.table = this.worklist;
      this.setPageSearch(1);
    }
  }
  setPageSearch(page: number) {
    this.pager = this.getPager(this.table.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    this.work = this.table.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  openModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'xl' });
  }
  resetAndopenModal(largeDataModal: any='') {
    this.router.navigate(['/master/taxSetup'], { queryParams: { Id: 0 } });
  }
  validSearchSubmit() {
    this.getWorklist();
  }

}
