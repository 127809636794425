import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './PurchaseInvoice.mustmatch';
//import { icons } from '../data';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { format, parse } from 'path';
import { threadId } from 'worker_threads';
import { IProductModel } from './purchaseDataModel';

@Component({
  selector: 'app-PurchaseInvoice',
  templateUrl: './PurchaseInvoice.component.html',
  styleUrls: ['./PurchaseInvoice.component.scss']
})

/**
 * Forms Validation component
 */
export class PurchaseInvoiceComponent implements OnInit {
  submitDisabled:boolean;
  validationform: FormGroup; // bootstrap validation form
  SaleAcList: any = [];
  BillingAcList: any = [];
  OtherInfoList: any = [];
  ItemList: any = [];
  TaxList: any = [];
  TaxMasterList:any=[];
  TaxDetailList:any=[];
  selectedSaleAcID: string;
  selectedAccID: string;
  SelectedDispAc: string;
  selectedAccID2: string;
  VoucherDetailList: any = [];
  PaymentTypes: any = [];
  validateTableRates: boolean = true;
  TotalItemQty: number;
  TotalItemAmount: number;
  BillAmount: number;
  RoundOff: number;
  TaxableAmount:number;
  barCodeValueModel : string;
  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router: Router
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;
  SearchItems: any = [];
  SearchPanelVisible: boolean;
  TotalQty: any;
  TotalAmount: any;
  EntryID: any;
  editMode: boolean;
  grandTotal: any = [];
  productTotal: any = [];
  AccountList: any = [];
  ProductArray: any = [];
  VoucherTypeList: any[];
  VoucherTypeCode: any[];
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Packing List', active: true }];
    this.EntryID = 0;
    this.EntryID = this.route.snapshot.queryParamMap.get('Id') ? this.route.snapshot.queryParamMap.get('Id') : '';
    this.editMode = false;
    this.PaymentTypes.push('TT');
    this.PaymentTypes.push('LC');
    /**
     * Bootstrap validation form data
     */
    this.validationform = this.formBuilder.group({
      BILL_NO: ['', [Validators.required]],
      DATED: ['', [Validators.required]],
      sa_code: ['', [Validators.required]],
      A_CODE: ['', [Validators.required]],
      TRANS_TYPE: [''],
      S_NO: [''],
      ROUND_OFF: [''],
      NET_AMT: [''],
      TOTAL_AMT: [''],
      TAXABLE_AMT : [''],

    });
    this.VoucherTypeList =
      [
        { Code: 'Cash', Name: 'Cash' },
        { Code: 'Credit', Name: 'Credit' }
      ];
    var date = new Date();
    date.setDate(date.getDate());
    this.validationform.patchValue(
      {
        DATED: formatDate(date, "yyyy-MM-dd", "en-us"),
        TRANS_TYPE: 'Cash'
      }
    )
    this.setVoucherList();
    this.getAccountList();
    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;


  }
  setVoucherList() {
    //this.addBlankRow();
    this.addBlankRow();
  }

  addBlankRow() {
    let productModel:IProductModel;
    productModel={
     ROW_NO:this.VoucherDetailList.length + 1,
     IT_CODE:'',
     Remarks: '',
     AMOUNT: 0,
     QTY: 0,
     Case_Qty: 0,
     PcsPerBox: 0,
     RATE: null,
     S_NO: 0,
     id: 0,
     UN_CODE: '',
     ITEM_NAME: '',
     UNIT: ''
    }
    this.VoucherDetailList.push(
      productModel
         );
  }
  checkLastRow(event, item, index) {
    item.UN_CODE = event.UN_CODE;
    item.UOM = event.UnitName;
    item.RATE = event.Sale_Rate;
    this.calculateProductAmount(event, item);
    if (index == this.VoucherDetailList.length - 1) {
      this.addBlankRow();
    }
  }
  get form() {
    return this.validationform.controls;
  }

  validSubmit() {
    this.submitDisabled=true;
    this.submit = true;
    if (this.validationform.valid) {
      this.validationform.patchValue(
        {
          NET_AMT : this.BillAmount,
          TOTAL_AMT: this.TotalItemAmount,
          TAXABLE_AMT : this.TaxableAmount,
          ROUND_OFF : this.RoundOff
        }
      )
      const VoucherDetailList2 = this.VoucherDetailList.filter(r => r.AMOUNT != 0 && r.AMOUNT != null && r.AMOUNT != undefined);
      const TaxDetailList = this.TaxList.filter(r => r.TAX_AMT != 0 && r.TAX_AMT != null && r.TAX_AMT != undefined);
      const OtherInfoDetailList = this.OtherInfoList.filter(r => r.Description != '' && r.Description != null && r.Description != undefined);
      if (VoucherDetailList2 == undefined || VoucherDetailList2.length == 0) {
        this.toaster.error('Please check Payment List');
        this.submitDisabled=false;
        return;
      }
      try {
        this.spinner.show();
        var JsonMaster = (this.validationform.value)
        JsonMaster.VType = '1006';

        this.setup.AddMasterDetailData(JSON.stringify(JsonMaster), JSON.stringify(VoucherDetailList2), JSON.stringify(TaxDetailList), JSON.stringify(OtherInfoDetailList))
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data.Status == 1) {
            this.spinner.hide();
            this.toaster.success(data.ResponseMessage);
            this.router.navigate(['/entry/purchaseList']);
          }
          else {
            this.spinner.hide();
            this.submitDisabled=false;
            this.toaster.error(data.ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.submitDisabled=false;
        this.toaster.error(error);
      }
    }
    else {
      this.submitDisabled=false;
      this.toaster.error('Please enter required values');
    }
  }
  formSubmit() {
    this.formsubmit = true;

  }
  checkSupplier(): boolean {
    if (this.submit && !this.selectedAccID) {
      return true;
    }
    else {
      return false;
    }
  }


  DeletePrdItem(item, iRowIndex) {
    this.VoucherDetailList.splice(iRowIndex, 1);
  }
  editRecord(EntryID) {
    try {
      this.setup.GetRecordInfo(this.EntryID, 'sp_getEditRecord', 'PurchaseInvoice')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.fillEditRecordData(data);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  getAccountList() {
    try {
      this.setup.GetAccountList('Purchase Account')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.SaleAcList = data.ResultSets[0];
          this.AccountList = data.ResultSets[1];
          this.BillingAcList = data.ResultSets[1];
          this.OtherInfoList = data.ResultSets[2];
          this.ItemList = data.ResultSets[3];
          this.TaxMasterList = data.ResultSets[4];
          this.TaxDetailList = data.ResultSets[5];
          if (+this.EntryID > 0) {
            this.editRecord(this.EntryID);
          }
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  getVoucherDetailList(event) {
    try {
      this.spinner.show();
      const SearchString =
      {
        SearchJson: this.selectedSaleAcID
      }
      this.setup.GetRegularworkList('sp_GetMaxVoucher', 'PurchaseInvoice', event.Code)
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.TaxList = data.ResultSets[0];
          this.calcTaxAmt('','');
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  fillEditRecordData(data) {
    data.ResultSets[0].forEach(element => {
      var keys = Object.keys(element);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { }
      })
    });
    this.editMode = true;
    //this.selectedAccID = data[0].BAc_Code;
    this.VoucherDetailList = [];
    this.OtherInfoList=[];
    this.TaxList=[];
    this.VoucherDetailList = data.ResultSets[1];
    this.TaxList=data.ResultSets[2];
    this.OtherInfoList=data.ResultSets[3];

    this.addBlankRow();
    this.calcTotalBillAmt();
  }
  calculateProductAmount(event, item) {
    item.QTY= +(+item.Case_Qty * +item.PcsPerBox).toFixed(2);
    item.AMOUNT =(item.RATE * item.QTY).toFixed(2);
    this.calcTotalBillAmt();
  }
  checkSupplier2(): boolean {
    if (this.selectedAccID2 == undefined || !this.selectedAccID2) {
      return false;
    }
    else {
      return true;
    }
  }
  checkAccDisabled() {
    // if (this.ProductArray != undefined && this.ProductArray.length > 0) {
    //   return true;
    // }
    return false;
  }
  BillingParty_Change(event) {
    if (this.SelectedDispAc == null || this.SelectedDispAc == undefined || this.SelectedDispAc == "") {
      this.SelectedDispAc = this.selectedAccID;
    }
    if (this.selectedSaleAcID == null || this.selectedSaleAcID == undefined || this.selectedSaleAcID == "") {
      this.selectedSaleAcID = event.LastSaleAc;
      this.getVoucherDetailList(event);
    }
  }
  calcTaxAmt(item, index) {
    var TaxableCategory:any[] =["VAT", "CST", "IGST", "CGST", "SGST"];
var sale_value=0;
    this.TaxList.forEach(element => {
      this.TaxableAmount=0;
this.TaxMasterList.forEach(taxmst_element => {
  if (element.HEAD_NAME==taxmst_element.HEAD_NAME)
  {
    this.TaxDetailList.forEach(taxdetail_element => {
      if(taxdetail_element.S_NO == taxmst_element.S_NO)
      {
        for(let l=0;l<=this.TaxList.length-1;l++)
        {
            if(element.HEAD_NAME==taxdetail_element.HEAD_NAME)
            {
              if(taxdetail_element.TAX_NATURE=="+")
              {
                this.TaxableAmount += +this.TaxList[l].TAX_AMT
              }
              else
              {
                this.TaxableAmount -= +this.TaxList[l].TAX_AMT
              }
            }
        }
      }
    });
  }
});

let tax_on =element.TAX_ON;// Val(NumberFormat(.Item("tax_on", i).Value))
if(element.BASIC_AMT == "YES")
{
  this.TaxableAmount= +this.TaxableAmount + +this.TotalItemAmount;
}
if(tax_on > 0)
{
 this.TaxableAmount= +this.TaxableAmount * +tax_on / 100
}
if(TaxableCategory.find(x=> x==element.CATEGORY))
{
  sale_value = this.TaxableAmount;
}
if(element.PERCENTAGE == "YES")
{
    //.Item("tax_amt", i).Value = FormatNumber(Taxable_Amt * Val(NumberFormat(.Item("tax_per", i).Value)) / 100, 2)
    element.TAX_AMT = (this.TotalItemAmount * element.TAX_PER / 100).toFixed(2);
}


      // if (element.PERCENTAGE == "YES") {
      //   element.TAX_AMT = (this.TotalItemAmount * element.TAX_PER / 100).toFixed(2);
      // }
    });
  }
  calcTotalBillAmt() {
    this.TotalItemQty = 0;
    this.TotalItemAmount = 0;
    for (let i = 0; i <= this.VoucherDetailList.length - 1; i++) {
      var element = this.VoucherDetailList[i];
      this.TotalItemQty += element.QTY;
      this.TotalItemAmount += +element.AMOUNT;
      this.BillAmount = this.TotalItemAmount;
    }
    this.TotalItemAmount= +this.TotalItemAmount.toFixed(2);
    this.calcTaxAmt('','');

    let totalTax = 0;
    this.RoundOff=0;
    for (let i = 0; i <= this.TaxList.length - 1; i++) {
      var element = this.TaxList[i];
      if (element.TAX_NATURE == "-") {
        totalTax -= +element.TAX_AMT;
      }
      else {
        totalTax += +element.TAX_AMT;
      }
    }
    let totBillAmt= +this.TotalItemAmount + +totalTax;
    let roundOffAmt= Math.round(totBillAmt) - (totBillAmt);
    if(+roundOffAmt!=0)
    {
      this.RoundOff =parseFloat(roundOffAmt.toFixed(2));
    }
    this.BillAmount = +(+this.TotalItemAmount + +totalTax + +this.RoundOff).toFixed(2);

  }
  calcNetAmtwithRoundoff()
  {
    this.BillAmount = +(+this.TotalItemAmount +  +this.RoundOff).toFixed(2);
  }
  barcodeKeyPress(event)
  {
    var barCodeValue = this.barCodeValueModel;
    //alert(barCodeValue);
console.log(this.ItemList);
    // if (event.key === "Enter")
    {
      if (barCodeValue != "") {

        this.ItemList.forEach(itemmst_element => {

          if (itemmst_element.PCode == barCodeValue) {

            this.VoucherDetailList.push(
              {
                ROW_NO: this.VoucherDetailList.length + 1,
                IT_CODE: itemmst_element.Code,
                narration: '',
                AMOUNT: itemmst_element.Rate * 1,
                QTY: 1,
                Case_Qty: 1,
                PcsPerBox: itemmst_element.PackingPerBox,
                RATE: itemmst_element.Rate,
                S_NO: 0,
                id: '',
                UN_CODE: itemmst_element.UN_CODE,
                ITEM_NAME: itemmst_element.Name,
                UNIT: itemmst_element.UnitName,
                UOM: itemmst_element.UnitName,

              }
            );
            this.VoucherDetailList.forEach(
              r => {
                if (r.IT_CODE.length == 0) {
                  this.VoucherDetailList.splice(r.ROW_NO - 1, 1);
                }
              }
            )
            this.addBlankRow();
            this.barCodeValueModel="";
            this.calcTotalBillAmt();
            //event.target.value = "";
          }
        });
       // console.log(this.VoucherDetailList);
      }
    }

  }
}
