import { Component, OnInit, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { DecimalPipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import * as _ from 'underscore';
//import { tableData } from './data';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { Router } from '@angular/router';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MENU } from 'src/app/layouts/shared/sidebar/menu';
import { MenuItem } from 'src/app/layouts/shared/sidebar/menu.model';

@Component({
  selector: 'app-AdminSettings',
  templateUrl: './AdminSettings.component.html',
  styleUrls: ['./AdminSettings.component.scss'],
  providers: [DecimalPipe]
})

/**
 * Advanced table component
 */
export class AdminSettingsComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];
  tableData: any[];
  worklist: any[];
  searchlist: any[];
  work: any[];
  // Table data
  searchString: string;
  pager: any = {};
  validationform: FormGroup;
  page: any;
  submit: boolean;
  @ViewChild(AdminSettingsComponent) table: AdminSettingsComponent[];

  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private _router: Router,
    private _session: SessionCheck,
    private modalService: NgbModal) {
    // this.tablesData$ = service._tableData$;
  }
  ngOnInit() {
    this.validationform = this.formBuilder.group({
      BankDetail: [''],
      Address1: [''],
      Address2: [''],
      CompanyName: [''],
      Phone: [''],
      Email: [''],
      TermsAndConditions:['']
    });
    this.editRecord();
    this.breadCrumbItems = [{ label: 'Quotation' }, { label: 'Customer Quotation', active: true }];
  }

  setPage(page: number) {
    this.pager = this.getPager(this.searchString != '' ? this.work.length : this.worklist.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    //  this.pager = this.getPager(this.worklist.length, page);
    this.work = this.searchString != '' ? this.work.slice(this.pager.startIndex, this.pager.endIndex + 1) : this.worklist.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  setPageSearch(page: number) {
    this.pager = this.getPager(this.table.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    this.work = this.table.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }


  searchExistingRecords(event: any) {

    var val = event.target.value.toLowerCase();
    //this._main.rndWorklistSearch = event.target.value.toLowerCase();
    if (val != '') {
      const list = this.worklist.filter(function (d) {
        var ColumnsData = '';
        var columnsName = ['AccName', 'AccCode', 'AccType']
        columnsName.forEach(col => {
          ColumnsData += ' ' + d[col]
        });
        return ColumnsData.toLowerCase().indexOf(val) !== -1 || !val;
      });
      this.work = list;
      this.table = list;
      //this.downloadWrk = list;
      // Whenever the filter changes, always go back to the first page.
      this.setPageSearch(this.page);
    }
    else {
      this.table = this.worklist;

      this.table = this.worklist;
      this.setPageSearch(this.page);
    }
  }


  public fixBinary(bin) {
    const length = bin.length;
    const buf = new ArrayBuffer(length);
    const arr = new Uint8Array(buf);
    for (let i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }

  openModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'xl' });
  }
  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      try {
        this.spinner.show();
        const JsonMaster = (this.validationform.value)

        this.setup.addRecord(0, JSON.stringify(JsonMaster), '','sp_addAdminConfig')
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data[data.length - 1].Status == 1) {
            this.modalService.dismissAll();
            this.toaster.success(data[0].ResponseMessage);
          }
          else {
            this.toaster.error(data[0].ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }
    }
  }
  editRecord() {
    try {
      this.setup.GetRecordInfo(0, 'sp_GetAdminConfigData')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.fillEditRecordData(data);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  fillEditRecordData(data) {
    data.forEach(element => {
      var keys = Object.keys(element);
      var values = Object.values(element);
      const JsonMaster = (this.validationform.value);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { }
      })
    });

  }

}
