<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen] = "true"></ngx-spinner>

<div class="container-fluid">
  <app-pagetitle title="Tax Setup" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Purchase Order List</h4> -->
          <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">
            <div class="row">
              <div class="col-lg-12">
                <!-- <div class="card o">
                  <div class="card-body o"> -->
                    <div class="row">
                      <div class="col-md-8">
                        <div class="form-group">
                          <label for="HEAD_NAME">Head Name</label>
                          <input class="form-control" formControlName="HEAD_NAME" id="HEAD_NAME"
                            [ngClass]="{'is-invalid': submit && form.HEAD_NAME.errors}">
                          <div *ngIf="submit && form.HEAD_NAME.errors" class="invalid-feedback">
                            Please provide Head Name
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-md-3">
                        <div class="form-group">
                          <label for="amGroupCode2">Account Type</label>
                          <ng-select formControlName="amGroupCode2" name="amGroupCode2" id="amGroupCode2"
                          [items]="accountTypes"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="selectedTypeID"></ng-select>
                        <div class="invalidValidate" *ngIf="checkSupplierType()">
                          Please provide Account Type
                        </div>
      
                        </div>
                      </div> -->
                      
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="TH_CODE">Tax Head Code</label>
                          <input class="form-control" formControlName="TH_CODE" id="TH_CODE"
                            disabled readonly   [ngClass]="{'is-invalid': submit && form.TH_CODE.errors}">
                              <div *ngIf="submit && form.TH_CODE.errors" class="invalid-feedback">
                                Please provide Tax Head Code
                              </div>
                        </div>
                      </div>
                      <div class="col-md-2 ">
                        <div class="form-group">
                          <label for="isactive">Active</label>
                          <ng-select formControlName="isactive" name="isactive" id="isactive"
                          [items]="ActiveTagList"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="ActiveTagCode"></ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="PERCENTAGE">Percentage</label>
                          <ng-select formControlName="PERCENTAGE" name="PERCENTAGE" id="PERCENTAGE"
                          [items]="AllList"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="AllCode"></ng-select>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <label for="TAX_PER">Tax (%)</label>
                          <input type = "number"class="form-control" formControlName="TAX_PER" id="TAX_PER"
                            [ngClass]="{'is-invalid': submit && form.TAX_PER.errors}">
                          <div *ngIf="submit && form.TAX_PER.errors" class="invalid-feedback">
                            Please provide Tax Percentage
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="TAX_ON">Tax on</label>
                          <input class="form-control" formControlName="TAX_ON" id="TAX_ON"
                            [ngClass]="{'is-invalid': submit && form.TAX_ON.errors}">
                          <div *ngIf="submit && form.TAX_ON.errors" class="invalid-feedback">
                            Please provide Tax on
                          </div>
                        </div>
                      </div>
                    
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="TAX_NATURE">Tax Nature</label>
                          <ng-select formControlName="TAX_NATURE" name="TAX_NATURE" id="TAX_NATURE"
                          [items]="TaxNatureList"  bindLabel="Name" bindValue="Code">
                          </ng-select>
                        </div>
                      </div>
                    
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="ROUND_OFF">Round off</label>
                          <ng-select formControlName="ROUND_OFF" name="ROUND_OFF" id="ROUND_OFF"
                          [items]="AllList"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="AllCode"></ng-select>
   
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="TRANSFER_HEAD">Ladger Transfer Head</label>
                          <input class="form-control" formControlName="TRANSFER_HEAD" id="TRANSFER_HEAD">
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="BASIC_AMT">Include Basic Amount</label>
                          <ng-select formControlName="BASIC_AMT" name="BASIC_AMT" id="BASIC_AMT"
                          [items]="AllList"  bindLabel="Name" bindValue="Code" 
                          [(ngModel)]="AllCode"></ng-select>
                        </div>
                      </div>
                    </div>
                    <div class="row">

                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="CATEGORY">Tax Category</label>
                          <input class="form-control" formControlName="CATEGORY" id="CATEGORY"
                            [ngClass]="{'is-invalid': submit && form.CATEGORY.errors}">
                          <div *ngIf="submit && form.CATEGORY.errors" class="invalid-feedback">
                            Please provide CATEGORY
                          </div>
                        </div>
                      </div>
                      
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="INDEX_ORDER">Index</label>
                          <input class="form-control" formControlName="INDEX_ORDER" id="INDEX_ORDER"
                            [ngClass]="{'is-invalid': submit && form.INDEX_ORDER.errors}">
                          <div *ngIf="submit && form.INDEX_ORDER.errors" class="invalid-feedback">
                            Please provide Index
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" >
                      <div class="col-lg-12">
                        <div class="card">
                          <div class="card-body" >
                            <h4 class="card-title">Tax Related Head</h4>
                
                            <div class="table-responsive" style="min-height:250px ;">
                              <table class="table table-hover mb-0">
                
                                <thead>
                                  <tr class="TableHeaderRow">
                                    <th>#</th>
                                    <th style="min-width:250px!important">Tax Head</th>
                                    <th style="min-width:25px!important">Tax Nature</th>
                                    <th>Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let item of VoucherDetailList;let i=index">
                                    <td>{{i+1}}</td>
                                    <td>
                                      <ng-select name="TaxList" id="TaxList + {{item.id}}"
                                      [items]="TaxList"  bindLabel="Name" bindValue="Code" 
                                      (change)="checkLastRow(i)" [(ngModel)]="item.TH_CODE"></ng-select>
                                      </td>
                                      <td>
                                        <input class="form-control" id="NARRATION + {{item.id}}" value=""
                                        [(ngModel)]="item.NARRATION"></td>
                                     <td>
                                      <button type="button" class="mdi mdi-18px mdi-delete text-danger"
                                        (click)="DeletePrdItem(item,i)"></button>
                                    </td>
                                  </tr>
                                  <tr class="TotalRow">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                
                          </div>
                        </div>
                      </div>
                
                    </div>
                
                  <!-- </div>
                </div> -->
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
