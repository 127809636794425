<div class="container-fluid">
    <div class="row">
            <div class="card">
                  <!-- <h4 class="card-title">Purchase Order List</h4> -->
                <button type="button" class="btn btn-primary" (click)="goBack()">
                    &nbsp;&nbsp;&nbsp;  Back&nbsp;&nbsp;&nbsp;</button> 
                                 
        </div>
        <div class="row"></div>
                <!-- <h4 class="card-title">Purchase Order List</h4> -->
                        <div style="height: 100%;width: 100%;">
                        </div>
            </div>
</div>
<!-- <iframe width="100%" height="600px"></iframe> -->
