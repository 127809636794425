<div class="container-fluid">
  <div class="row">
    <div class="col-10">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">Payment Entry (Sale)</h4>
      </div>
    </div>
    <div class="col-2">
    </div>
  </div>
<br>
  <!-- <app-pagetitle title="Customer Quotation" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
  <!-- <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-5">
              <div class="form-group">
                <label for="AccCode">Customer Name</label>
                <ng-select name="AccCode" [items]="SupplierList" bindLabel="AccName" bindValue="Amid"
               [disabled]="checkAccDisabled()"   [(ngModel)]="selectedAccID2"></ng-select>
                <div class="invalid-feedback" *ngIf="checkSupplier2()">
                  Please provide Customer
                </div>
              </div>
            </div>
          </div>
                    <h4 class="card-title">Select Product</h4>
          <div class="card">
            <input class="form-control" [(ngModel)]="searchSize" placeholder="Size" id="searchSize" name="searchSize"
              (keydown.enter)="searchPrdFromDB($event)" />


          </div>
          <div *ngIf="SearchPanelVisible" class="table-responsive">
            <table class="table table-hover mb-0">

              <thead>
                <tr class="TableHeaderRow">
                  <th>#</th>
                  <th>Description</th>
                  <th>Order No</th>
                  <th>Order Date</th>
                  <th>Order Qty</th>
                  <th>Booked Qty</th>
                  <th>Invoice Qty</th>
                  <th>Rate</th>
                  <th>Volume</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of SearchItems;let i=index">
                  <th scope="row">{{i+1}}</th>
                  <td>{{item.Description}}</td>
                  <td>{{item.OrderNo}}</td>
                  <td>{{item.OrderDate}}</td>
                  <td>{{item.OrderQty}}</td>
                  <td>{{item.BookedQty}}</td>
                  <td>
                    <input type="number" class="form-control" id="InvoiceQty + {{item.id}}" value="0"
                      [(ngModel)]="item.InvoiceQty">
                  </td>
                  <td>{{item.OrderRate}}</td>
                  <td>{{item.PurQty/item.PrdContainerCapacity | number : '.2-2'}}</td>
                  <td>
                    <input type="text" class="form-control" id="Remarks + {{item.id}}" value="0"
                      [(ngModel)]="item.Remarks">
                  </td>
                </tr>
                <tr class="TotalRow">
                  <td></td>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>{{productTotal.POQty}}</td>
                  <td>{{productTotal.BookedQty}}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-lg-10"></div>
              <div class="col-lg-2">
                <button class="btn btn-warning" (click)="addProducts()">Add & Continue</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div> -->
   <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform"> 

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-5">
                <div class="form-group">
                  <label for="AccCode">Customer Name</label>
                  <ng-select formControlName="AccCode" name="AccCode" id="AccCode"
                (change)="getPendingInvoiceList()"    [items]="SupplierList"  bindLabel="AccName" bindValue="Amid" 
                    [(ngModel)]="selectedAccID"></ng-select>
                  <div class="invalidValidate" *ngIf="checkSupplier()">
                    Please provide Customer
                  </div>
                </div>

              </div>
              

              <div class="col-md-3">
                <div class="form-group">
                  <label for="DocNo">Voucher No</label>
                  <input class="form-control" formControlName="DocNo" id="DocNo"
                 disabled readonly   [ngClass]="{'is-invalid': submit && form.DocNo.errors}">
                  <div *ngIf="submit && form.DocNo.errors" class="invalid-feedback">
                    Please provide Voucher No
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="DocDate">Payment Date</label>
                  <input type="date" class="form-control" formControlName="DocDate"
                   id="DocDate"
                    [ngClass]="{'is-invalid': submit && form.DocDate.errors}">
                  <div *ngIf="submit && form.DocDate.errors" class="invalid-feedback">
                    Please provide Payment Date
                  </div>  
                </div>
              </div>
            </div>
                  <!-- <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="MasterRemarks">Remarks</label>
                  <input type="text" class="form-control" formControlName="MasterRemarks" id="MasterRemarks">
                </div>
              </div>
            </div> -->
              
            
          </div>
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
   </form>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Payment Detail</h4>

            <div class="table-responsive">
              <table class="table table-hover mb-0" style="min-height:300px !important ;">

                <thead>
                  <tr class="TableHeaderRow">
                    <th>#</th>
                    <th>DocNo</th>
                    <th>DocDate</th>
                    <th>Balance Amount</th>
                    <th style="min-width:250px!important">Payment Mode</th>
                    <th>Received Amount</th>
                    <th style="min-width:250px!important">Remarks</th>
                    <!-- <th>Action</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of pendingInvoiceList;let i=index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.RefDocNo}}</td>
                    <td>{{item.RefDocDate}}</td>
                    <td>{{item.PenAmount}}</td>
                    <td>
                      <ng-select name="PaymentMode" id="PaymentMode + {{item.SLCIId}}"
                      [items]="PaymentTypes"  bindLabel="Name" bindValue="Name" 
                      [(ngModel)]="item.PaymentMode"></ng-select>
                      </td>
                    <td>
                      <input type="number" class="form-control" id="RecAmount + {{item.SLCIId}}" value="0"
                      [(ngModel)]="item.RecAmount"></td>
                      <td>
                        <input class="form-control" id="Remarks + {{item.SLCIId}}" value=""
                        [(ngModel)]="item.Remarks"></td>
                      <!-- <td>
                      <button type="button" class="mdi mdi-18px mdi-delete text-danger"
                        (click)="DeletePrdItem(item,i)"></button>
                    </td> -->
                  </tr>
                  <tr class="TotalRow">
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    <td>{{grandTotal.PenAmount}}</td>
                    <td></td>
                    <td>{{grandTotal.RecAmount}}</td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>

 
  
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-8">
                <div class="form-group">
                  <!-- <label for="PaymentTerms">Remarks</label>
                  <textarea rows="4" class="form-control" formControlName="PaymentTerms" id="PaymentTerms"></textarea> -->
                </div>
              </div>
              
                <div class="col-lg-2">
                </div>
                  <div class="col-lg-2">
                  <br>
                  <div class="card">
                    <button class="btn btn-primary " (click)="validSubmit()">Save</button>
                </div>
              </div>
        

            </div>
            </div>
      
            
            
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
    <!-- end row -->

  <!-- </form> -->


</div>