<div class="container-fluid">

  <app-pagetitle title="Commercial Invoice" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Select Product</h4>
          <div class="card">
            <input class="form-control" [(ngModel)]="searchSize" placeholder="Size" id="searchSize" name="searchSize"
              (keydown.enter)="searchPrdFromDB($event)" />


          </div>
          <div *ngIf="SearchPanelVisible" class="table-responsive">
            <table class="table table-hover mb-0">

              <thead>
                <tr class="TableHeaderRow">
                  <th>#</th>
                  <th>Description</th>
                  <th>Supplier</th>
                  <th>PO No</th>
                  <th>PO Date</th>
                  <th>PO Rate</th>
                  <th>PO Qty</th>
                  <th>Received Qty</th>
                  <th>Shipped Qty</th>
                  <th>Pen Qty</th>
                  <th>Load Qty</th>
                  <!-- <th>Container No</th> -->
                  <th>Volume</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of SearchItems;let i=index">
                  <th scope="row">{{i+1}}</th>
                  <td>{{item.Description}}</td>
                  <td>{{item.AccName}}</td>
                  <td>{{item.PONo}}</td>
                  <td>{{item.PODate}}</td>
                  <td>{{item.PORate}}</td>
                  <td>{{item.POQty}}</td>
                  <td>{{item.ReceivedQty}}</td>
                  <td>{{item.ShippedQty}}</td>
                  <td>{{item.PenQty}}</td>
                  <td>
                    <input type="number" class="form-control" id="LoadQty + {{item.id}}" value="0"
                      [(ngModel)]="item.LoadQty">
                  </td>
                  <!-- <td>
                    <input type="text" class="form-control" id="ContainerNo + {{item.id}}" value=""
                      [(ngModel)]="item.ContainerNo">
                  </td> -->
                  <td>{{item.LoadQty/item.PrdContainerCapacity | number : '.2-2'}}</td>
                </tr>
                <tr class="TotalRow">
                  <td></td>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>{{productTotal.POQty}}</td>
                  <td>{{productTotal.ReceivedQty}}</td>
                  <td>{{productTotal.ShippedQty}}</td>
                  <td>{{productTotal.PenQty}}</td>
                  <td>{{productTotal.LoadQty}}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-lg-10"></div>
              <div class="col-lg-2">
                <button class="btn btn-warning" (click)="addProducts()">Add & Continue</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
  <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <!-- <div class="col-md-5">
                <div class="form-group">
                  <label for="SupplierName">Supplier Name</label>
                  <ng-select formControlName="SupplierName" name="SupplierName" id="SupplierName"
                    [items]="SupplierList"  bindLabel="AccName" bindValue="AccCode" 
                    [(ngModel)]="selectedSupplierID"></ng-select>
                  <div class="invalidValidate" *ngIf="checkSupplier()">
                    Please provide Supplier
                  </div>
                </div>

              </div>
               -->

              <div class="col-md-3">
                <div class="form-group">
                  <label for="PackingNo">Commercial Invoice No</label>
                  <input class="form-control" formControlName="PackingNo" id="PackingNo"
                    [ngClass]="{'is-invalid': submit && form.PackingNo.errors}">
                  <div *ngIf="submit && form.PackingNo.errors" class="invalid-feedback">
                    Please provide Commercial Invoice No
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="PackingDate">Commercial Invoice Date</label>
                  <input type="date" class="form-control" formControlName="PackingDate" id="PackingDate"
                    [ngClass]="{'is-invalid': submit && form.PackingDate.errors}">
                  <div *ngIf="submit && form.PackingDate.errors" class="invalid-feedback">
                    Please provide Commercial Invoice Date
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="BLNo">BL No</label>
                  <input class="form-control" formControlName="BLNo" id="BLNo"
                    [ngClass]="{'is-invalid': submit && form.BLNo.errors}">
                  <div *ngIf="submit && form.BLNo.errors" class="invalid-feedback">
                    Please provide BLNo No
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="ArrivalDate">Arrival Date</label>
                  <input type="date" class="form-control" formControlName="ArrivalDate" id="ArrivalDate"
                    [ngClass]="{'is-invalid': submit && form.ArrivalDate.errors}">
                  <div *ngIf="submit && form.ArrivalDate.errors" class="invalid-feedback">
                    Please provide Arrival Date
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Product Detail</h4>

            <div class="table-responsive">
              <table class="table table-hover mb-0">

                <thead>
                  <tr class="TableHeaderRow">
                    <th>#</th>
                    <th>Description</th>
                    <th>Supplier</th>
                    <th>PO No</th>
                    <th>PO Date</th>
                    <th>PO Rate</th>
                    <th>PO Qty</th>
                    <th>Received Qty</th>
                    <th>Shipped Qty</th>
                    <th>Pen Qty</th>
                    <th>Load Qty</th>
                    <!-- <th>Container No</th> -->
                    <th>Volume(%)</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of ProductArray;let i=index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.Description}}</td>
                    <td>{{item.AccName}}</td>
                    <td>{{item.PONo}}</td>
                    <td>{{item.PODate}}</td>
                    <td>{{item.PORate}}</td>
                    <td>{{item.POQty}}</td>
                    <td>{{item.ReceivedQty}}</td>
                    <td>{{item.ShippedQty}}</td>
                    <td>{{item.PenQty}}</td>
                    <td>{{item.LoadQty}}</td>
                    <!-- <td>{{item.ContainerNo}}</td> -->
                   <td> {{item.Volume}}</td>
                    <td>
                      <button type="button" class="mdi mdi-18px mdi-delete text-danger"
                        (click)="DeletePrdItem(item,i)"></button>
                    </td>
                  </tr>
                  <tr class="TotalRow">
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{grandTotal.POQty}}</td>
                    <td>{{grandTotal.ReceivedQty}}</td>
                    <td>{{grandTotal.ShippedQty}}</td>
                    <td>{{grandTotal.PenQty}}</td>
                    <td>{{grandTotal.LoadQty}}</td>
                    <td></td>
                    <td>{{grandTotal.Volume}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>

 
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <table style="width:100% ;">
              <tr>
                <td style="width:50% ;">
                  <div class="form-group">

                  </div>
                    
                </td>
                <td style="width:50% ;">
            <div class="row">
              <div class="col-md-2">

              </div>
              <div class="col-md-10">
                <div class="row">
                  <div class="col-md-4">
                  </div>
                    <div class="col-md-4">
                     <label>Freight</label>
                  </div>
                  <div class="col-md-4">
                    <input type="number"  class="form-control" style="text-align: right ;" 
                    formControlName="Freight" id="Freight">
                  </div>
                </div>

              </div>
            </div>
         
          </td>
                  </tr>
        </table>

            </div>
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>


    <div class="row">
      <div class="col-lg-10">
      </div>
      <div class="col-lg-2">
        <div class="card">
          <button class="btn btn-rounded btn-primary " type="submit">
            Save</button>
        </div>
      </div>
    </div>
    <!-- end row -->

  </form>


</div>