<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen]="true">
</ngx-spinner>

<div class="container-fluid">
  <app-pagetitle title="Settings" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Purchase Order List</h4> -->
          <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

            <div class="row">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                <div class="form-group">
                  <label for="BankDetail">Bank Detail</label>
                  <textarea style="min-height:150px; ;" class="form-control" formControlName="BankDetail"
                    id="BankDetail">
                  </textarea>
                </div>
              </div>
              </div>

              <div class="row">
                <div class="col-md-12">
              <div class="form-group">
                <label for="TermsAndConditions">Terms & Conditions</label>
                <textarea style="min-height:150px; ;" class="form-control" formControlName="TermsAndConditions"
                  id="TermsAndConditions">
                </textarea>
              </div>
            </div>
            </div>

              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="CompanyName">Company Name</label>
                      <input type="text" class="form-control" formControlName="CompanyName" 
                      id="CompanyName" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="Address1">Address1</label>
                      <input type="text" class="form-control" formControlName="Address1" id="Address1" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="Address2">Address2</label>
                      <input type="text" class="form-control" formControlName="Address2" id="Address2" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="Phone">Phone</label>
                      <input type="text" class="form-control" formControlName="Phone" id="Phone" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="Email">Email</label>
                      <input type="text" class="form-control" formControlName="Email" id="Email" />
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <button class="btn  btn-primary tblebtn w-md" type="submit">
              Save</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>