import { Component, OnInit, ViewChildren, ViewChild, QueryList, ElementRef } from '@angular/core';
import { DecimalPipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
import { Table } from './PoList.model';
import * as _ from 'underscore';
//import { tableData } from './data';
import { PoListService } from './PoList.service';
import { PoListDirective, SortEvent } from './PoList-sortable.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { Router } from '@angular/router';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import  * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/core/services/CommonService.service';
// import * as pdfMake from "pdfmake/build/pdfmake";
// import * as pdfFonts from "pdfmake/build/vfs_fonts";
//const htmlToPdfmake = require("html-to-pdfmake");
///(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-PoList',
  templateUrl: './PoList.component.html',
  styleUrls: ['./PoList.component.scss'],
  providers: [PoListService, DecimalPipe]
})

/**
 * Advanced table component
 */
export class PoListComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];
  tableData: any[];
  worklist: any[];
  searchlist: any[];
  work: any[];
  // Table data
  tableData2: any[];
  searchString: string;
  pager: any = {};
  tables$: Observable<Table[]>;
  total$: Observable<number>;
  tablesData$: Observable<any>;
  validationform: FormGroup;
  @ViewChildren(PoListDirective) headers: QueryList<PoListDirective>;
  @ViewChild(PoListComponent) table: PoListComponent[];
pdfdoc:any;
  title = 'PO List';
  constructor(private formBuilder: FormBuilder,
    public service: PoListService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private _router: Router,
    private commonservice: CommonService,
    private modalService: NgbModal) {
    this.tables$ = service.tables$;
    this.total$ = service.total$;
    // this.tablesData$ = service._tableData$;
  }
  ngOnInit() {
    this.validationform = this.formBuilder.group({
      FromDate: [''],
      ToDate: [''],
    });

    this.breadCrumbItems = [{ label: 'Purchase' }, { label: 'Purchase Order List', active: true }];
    var date = new Date();
    date.setDate(date.getDate() - 60);

    this.validationform.patchValue(
      {
        FromDate: formatDate(date, "yyyy-MM-dd", "en-us"),
        ToDate: formatDate(new Date(), "yyyy-MM-dd", "en-us")
      });

    /**
     * fetch data
     */
    //this._fetchData();
    this.getPOList();

  }
  getPOList() {
    this.tableData2 = [];
    try {
      this.spinner.show();
      const searchjson = {
        FromDate: this.validationform.get('FromDate').value,
        ToDate: this.validationform.get('ToDate').value
      }
        this.setup.GetPOList(JSON.stringify(searchjson))
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        this.work=[];
        if (data.length > 0) {
          this.tableData = data;
          this.worklist = data;
          this.tableData2=this.worklist;
          this.setPage(1);
          this.spinner.hide();
          // this.service.tables$
          //this.tableData2 = data;
          //this.service.tableData=data;
          // this.service.tableData=data;
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }

  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * fetches the table value
   */
  _fetchData() {
    // this.tableData = tableData;
    // for (let i = 0; i <= this.tableData.length; i++) {
    //   this.hideme.push(true);
    // }
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }


  setPage(page: number) {
    this.pager = this.getPager(this.worklist.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    //  this.pager = this.getPager(this.worklist.length, page);
    this.work = this.worklist.slice(this.pager.startIndex, this.pager.endIndex + 1);
    
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  searchExistingRecords(event: any) {
    const val = event.target.value.toLowerCase();
    //const val = event.target.value;
    if (val != '') {
      const list = this.worklist.filter(function (d) {
        let ColumnsData = '';
        const columnsName = ['PODate', 'PONo', 'AccName', 'CreatedBy', 'CreatedDate', 'Qty', 'Amount','Volume'];
        columnsName.forEach(col => {
          ColumnsData += ' ' + d[col];
        });
        return ColumnsData.toLowerCase().indexOf(val) !== -1 || !val;
      });
      // Whenever the filter changes, always go back to the first page.
      //this.tableData = list;
      this.table = list;
      this.work = list;
      this.tableData2 = list;
      this.setPageSearch(1);
    } else {
      this.table = this.worklist;
      this.tableData2 = this.worklist;
      this.setPageSearch(1);
    }
  }
  setPageSearch(page: number) {
    this.pager = this.getPager(this.table.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    this.work = this.table.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  editRecord(item) {
    this._router.navigate(['/form/poform'], { queryParams: { POId: item.POId } });
  }
  public fixBinary(bin) {
    const length = bin.length;
    const buf = new ArrayBuffer(length);
    const arr = new Uint8Array(buf);
    for (let i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }

  PrintPO(item,largeDataModal:any) {
    try {
      this.spinner.show();
      this.setup.getPDFAsResult(item.POId, "POPDF")
        .subscribe((result) => {
          this.spinner.hide();
          var base64string = result.toString();
          if (base64string.length > 1000) {
            const Data64ArrayBuffer = this.fixBinary(
              atob(base64string)
            );
            const blob = new Blob([Data64ArrayBuffer], {
              type: 'application/pdf'
            });
            this.openModal(largeDataModal);
            const url = window.URL.createObjectURL(blob);
            //document.querySelector("iframe").src = url;
          this.pdfdoc=url;
          }
        })

    }
    catch (error) {
      console.log(error);
    }

  }
  openPO() {
    this._router.navigate(['/form/poform'], { queryParams: { POId: 0 } });
  }
  saveExcel() {
    const fitToColumn = (arrayOfArray) => {
      return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
    }

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(this.work, { skipHeader: false });
    //workSheet['!cols'] = fitToColumn(this.work);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    const ext='xlsx';
    var filename = 'document.'+ext;
      XLSX.writeFile(workBook, filename, { type: 'array', bookType: ext, bookSST: false }); // initiate a file download in browser

  }
   PrintTable(){
    let printContents = '';
    const WindowObject = window.open('','PrintWindow','width=750,height=650,top=50,left=50,toolbars=no,scrollbars=yes,status=no,resizable=yes'
    );
    var style = "<style>";
style = style + "table {width: 100%; font: 17px Calibri;}";
style = style + "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
style = style + "padding: 2px 3px; text-align: center;}";
style = style + "</style>";
    printContents += `<table>
    <thead class="bg-info text-white">
                     <tr>
                     <h1 style = "text-align: center">BANOZE LIMITED</h1>
                     <h2 style = "text-align: center">PO List</h2>
                     <h4 style = "text-align: center"> From Date : ` + this.validationform.get('FromDate').value + `
                     To Date : `+ this.validationform.get('ToDate').value+`</h4>
                   
                       <th>PO Date</th>
                       <th>PO No</th> 
                       <th>Supplier</th>
                       <th>Created By</th>
                       <th>Created Date</th>
                       <th>Qty</th>
                       <th>Amount</th>
                       <th>Volume</th>
                     </tr>
                     </thead><tbody class="border border-info">`;
    for(let index=0;index<=this.tableData2.length-1;index++)
    {
      const data=this.tableData2[index];
                     //this.tableData2.map(data => {
         printContents += `<tr>
                       <td>${data.PODate}</td>
                       <td>${data.PONo}</td> 
                       <td>${data.AccName}</td>
                       <td>${data.CreatedBy}</td>
                       <td>${data.CreatedDate}</td>
                       <td>${data.Qty}</td>
                       <td>${data.Amount}</td>
                       <td>${data.Volume}</td>
                     </tr>`;
                    }
    const htmlData = `</tbody></table><html>`+style+`<body>${printContents}</body></html>`;
  
    WindowObject.document.writeln(htmlData);
    WindowObject.document.close();
    WindowObject.focus();
    setTimeout(() => {
      WindowObject.print();
      WindowObject.close();
    }, 400);
  //});
  } 
  // savePDF2() {
  //   var data = document.getElementById('table');
  //   html2canvas(data).then(canvas => {
  //     var imgWidth = 200;
  //     var imgHeight = canvas.height * imgWidth / canvas.width;
  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let pdf = new jspdf('p', 'mm', 'a4');
  //     var position = 2;
  //     pdf.addImage(contentDataURL, 'PNG', 2, position, imgWidth, imgHeight)
  //     //pdf.html(contentDataURL)
  //     pdf.save('document.pdf');  
  //   });
  // }
  saveCSV() {
    const fitToColumn = (arrayOfArray) => {
      return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
    }

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(this.work, { skipHeader: false });
    //workSheet['!cols'] = fitToColumn(this.work);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    const ext='csv';
    var filename = 'document.'+ext;
      XLSX.writeFile(workBook, filename, { type: 'array', bookType: 'csv', bookSST: false }); // initiate a file download in browser
}
openModal(largeDataModal: any) {
  this.modalService.open(largeDataModal, { size: 'xl' });
}
validSubmit() {
  this.getPOList();
}
@ViewChild('pdfTable')
pdfTable!: ElementRef;
savePDF()
{
  // const pdfTable = this.pdfTable.nativeElement;
  //   var html = htmlToPdfmake(pdfTable.innerHTML);
  //   const documentDefinition = { content: html };
  //   pdfMake.createPdf(documentDefinition).download(); 
}
downloadExcelResult(item: any) {
  try {
    this.commonservice.DownloadExcelFile(item.POId, 'POList', this.spinner,'getPOListExcel');
  } catch (error) {
    this.spinner.hide();
    console.log(error);
  }
}
}
