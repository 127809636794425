import { Component, OnInit, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { Observable } from 'rxjs';
import * as fileSaver from 'src/assets/filedownload/filesave.js';
//import { Table } from './PurchaseCommercialInvoiceList.model';
import * as _ from 'underscore';
//import { tableData } from './data';
//import {  PurchaseCommercialInvoiceListService } from './PurchaseCommercialInvoiceList.service';
//import { PurchaseCommercialInvoiceListDirective, SortEvent } from './PurchaseCommercialInvoiceList-sortable.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { Router } from '@angular/router';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/core/services/CommonService.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-PurchaseCommercialInvoiceList',
  templateUrl: './PurchaseCommercialInvoiceList.component.html',
  styleUrls: ['./PurchaseCommercialInvoiceList.component.scss'],
  //providers: [PurchaseCommercialInvoiceListService, DecimalPipe]
})

/**
 * Advanced table component
 */
export class PurchaseCommercialInvoiceListComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];
  tableData: any[];
  worklist: any[];
  searchlist: any[];
  pdfdoc:any;
  work: any[];
  
  // Table data
  //tableData2: Table[];
  searchString: string;
  pager: any = {};
  //tables$: Observable<Table[]>;
  total$: Observable<number>;
  tablesData$: Observable<any>;
  //@ViewChildren(PurchaseCommercialInvoiceListDirective) headers: QueryList<PurchaseCommercialInvoiceListDirective>;
  @ViewChild(PurchaseCommercialInvoiceListComponent) table: PurchaseCommercialInvoiceListComponent[];
  validationform: FormGroup; // bootstrap validation form
  dtOptions: any;
  showContent: boolean;
  constructor(public formBuilder: FormBuilder,
    //  public service: PurchaseCommercialInvoiceListService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private _router: Router,
    private _session: SessionCheck,
    private commonservice: CommonService,
    private modalService: NgbModal) {
    //this.tables$ = service.tables$;
    //this.total$ = service.total$;
    // this.tablesData$ = service._tableData$;
  }
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Purchase' }, { label: 'Packing List', active: true }];
    this.validationform = this.formBuilder.group({
      FromDate: [''],
      BLNo: [''],
      ToDate: [''],
      PONo: [''],
      ArrivalDate: [''],

    });
    this.setDataTableOptions();
    this.work = [];
    this.getPackingList();

  }
  validSubmit() {
    const searchstr = JSON.stringify(this.validationform.value);
    this.getPackingList(searchstr);

  }
  setDataTableOptions() {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      dom: 'Bfrtip',
      buttons: [
        'copy', 'csv', 'excel', 'print', 'pdf'
      ]
    };

  }
  getPackingList(SearchJson = '') {
    try {
      this.spinner.show();
      this.setup.GetRegularworkList('dbo.sp_GetPurInvoiceList', SearchJson)
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        this.tableData = [];
        this.worklist = [];
        this.work = [];
        if (data.ResultSets.length > 0) {
          this.tableData = data.ResultSets[0];
          this.worklist = data.ResultSets[0];
          //this.work = data.ResultSets[0];
          this.showContent = true;
          this.setPage(1);
          this.spinner.hide();
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }

  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * fetches the table value
   */
  _fetchData() {
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  // onSort({ column, direction }: SortEvent) {
  //   // resetting other headers
  //   this.headers.forEach(header => {
  //     if (header.sortable !== column) {
  //       header.direction = '';
  //     }
  //   });
  //   this.service.sortColumn = column;
  //   this.service.sortDirection = direction;
  // }


  setPage(page: number) {
    this.pager = this.getPager(this.worklist.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    //  this.pager = this.getPager(this.worklist.length, page);
    this.work = this.worklist.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  searchExistingRecords(event: any) {
    const val = event.target.value.toLowerCase();
    //const val = event.target.value;
    if (val != '') {
      const list = this.worklist.filter(function (d) {
        let ColumnsData = '';
        const columnsName = ['PackingDate', 'PackingNo', 'BLNo', 'ArrivalDate', 'CreatedBy', 'CreatedDate', 'LoadQty'];
        columnsName.forEach(col => {
          ColumnsData += ' ' + d[col];
        });
        return ColumnsData.toLowerCase().indexOf(val) !== -1 || !val;
      });
      // Whenever the filter changes, always go back to the first page.
      //this.tableData = list;
      this.table = list;
      this.work = list;
      this.setPageSearch(1);
    } else {
      this.table = this.worklist;
      this.setPageSearch(1);
    }
  }
  setPageSearch(page: number) {
    this.pager = this.getPager(this.table.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    this.work = this.table.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  editRecord(item) {
    this._router.navigate(['/form/commercialInvoice'], { queryParams: { Id: item.Id } });
  }
  public fixBinary(bin) {
    const length = bin.length;
    const buf = new ArrayBuffer(length);
    const arr = new Uint8Array(buf);
    for (let i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }
  PrintPO(item, largeDataModal: any) {
    try {
      this.spinner.show();
      this.setup.getPDFAsResult(item.Id, "POCommercialPDF")
        .subscribe((result) => {
          this.spinner.hide();
          var base64string = result.toString();
          if (base64string.length > 1000) {
            const Data64ArrayBuffer = this.fixBinary(
              atob(base64string)
            );
            const blob = new Blob([Data64ArrayBuffer], {
              type: 'application/pdf'
            });
            this.openModal(largeDataModal);
            const url = window.URL.createObjectURL(blob);
            //document.querySelector("iframe").src = url;
            this.pdfdoc=url;
          }
        })

    }
    catch (error) {
      console.log(error);
    }
  }
  openPO() {
    this._router.navigate(['/form/commercialInvoice'], { queryParams: { Id: 0 } });
  }
  saveExcel() {
    const fitToColumn = (arrayOfArray) => {
      return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
    }

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(this.work, { skipHeader: false });
    //workSheet['!cols'] = fitToColumn(this.work);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    const ext = 'xlsx';
    var filename = 'document.' + ext;
    XLSX.writeFile(workBook, filename, { type: 'array', bookType: ext, bookSST: false }); // initiate a file download in browser

  }
  savePDF() {
    var data = document.getElementById('table');
    html2canvas(data).then(canvas => {
      var imgWidth = 200;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');
      var position = 2;
      pdf.addImage(contentDataURL, 'PNG', 2, position, imgWidth, imgHeight)
      //pdf.html(contentDataURL)
      pdf.save('document.pdf');
    });
  }
  saveCSV() {
    const fitToColumn = (arrayOfArray) => {
      return arrayOfArray[0].map((a, i) => ({ wch: Math.max(...arrayOfArray.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
    }

    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet(this.work, { skipHeader: false });
    //workSheet['!cols'] = fitToColumn(this.work);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data');
    const ext = 'csv';
    var filename = 'document.' + ext;
    XLSX.writeFile(workBook, filename, { type: 'array', bookType: 'csv', bookSST: false }); // initiate a file download in browser
  }
  downloadExcelResult(item: any) {
    try {
      this.commonservice.DownloadExcelFile(item.Id, 'CommercialInvoice', this.spinner, 'getCommericalInvExcel');
    } catch (error) {
      this.spinner.hide();
      console.log(error);
    }
  }
  openModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'xl' });
  }

  resetAndopenModal(largeDataModal: any) {
    this.openModal(largeDataModal);
  }
}
