<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen] = "true"></ngx-spinner>

<div class="container-fluid">
  <app-pagetitle title="Sale Order List" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Purchase Order List</h4> -->
          <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

            <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="FromDate">From Date</label>
                    <input type="date" class="form-control" formControlName="FromDate" id="FromDate">
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="ToDate">To Date</label>
                    <input type="date" class="form-control" formControlName="ToDate" id="ToDate">
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <br>
                    <button class="btn btn-rounded  btn-warning " type="submit">
                      <i class="fa fa-search"></i>&nbsp; Search
                          </button>
                        </div>
                      </div>
  
              </div>
  
            </form>  
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <button class="btn btn-round btn-outline-primary" 
              (click)="openPO()" type="button"><i class="mdi mdi-account-plus"></i>
              Add Sale Order</button>

              <!-- <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Show
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> entries</label></div> -->
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div class="row navbar">
                <div class="col-6 d-flex justify-content-left">
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <div class="row">
                        <div>
                            <input class="form-control" [(ngModel)]="searchString" placeholder="Search" id="searchString"
                                name="searchString" (keyup)="searchExistingRecords($event)" />
                        </div>
                        <!-- <div>
                            <button class="btn btn-primary" (click)="searchDBRecords()"><i
                                    class="ti-search m-r-5 m-l-5"></i></button>
                        </div> -->
                    </div>
                </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table #table class="table table-hover m-t-20 table-bordered">
                <thead class="bg-info text-white">
                    <tr class="">
                        <th>Doc Date</th>
                        <th>Doc No</th>
                        <th>Customer </th>
                        <th>Created By</th>
                        <th>Created Date</th>
                        <th>Qty</th>
                        <th>Amount</th>
                        <th *ngIf="roleName=='Admin'">Profit(%)</th>
                        <th *ngIf="roleName=='Admin'">Profit Amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody class="border border-info">
                    <tr *ngFor="let item of work">

                        <td>{{item.DocDate}}</td>
                        <td>{{item.DocNo}}</td>
                        <td>{{item.AccName}}</td>
                        <td>{{item.CreatedBy}}</td>
                        <td>{{item.CreatedDate}}</td>
                        <td style="text-align: right ;">{{item.Qty}}</td>
                        <td style="text-align: right ;">{{item.Amount}}</td>
                        <td *ngIf="roleName=='Admin'" style="text-align: right ;">{{item.ProfitPer}}</td>
                        <td *ngIf="roleName=='Admin'" style="text-align: right ;">{{item.ProfitAmt}}</td>
                        <td>
                          <a href="javascript:void(0);" (click)="downloadExcelResult(item)" class="text-success"
                          ngbTooltip="Download excel"><i class="mdi mdi-file-excel font-size-22 "></i></a>
                          &nbsp;<a href="javascript:void(0);" (click)="editRecord(item)" class="text-primary" ngbTooltip="Edit"><i
                                  class="mdi mdi-file-document-edit-outline font-size-22 "></i></a>
                                 &nbsp; <a href="javascript:void(0);" (click)="PrintSO(item,PrintModal)" class="text-primary" ngbTooltip="Edit"><i
                                    class="mdi mdi-printer font-size-22 "></i></a>
            
                        </td>
                    </tr>
                    <tr class="TotalRow">
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style="text-align: right ;">{{this.GrandTotal.Qty}}</td>
                      <td style="text-align: right ;">{{this.GrandTotal.Amount}}</td>
                      <td *ngIf="roleName=='Admin'"></td>
                      <td *ngIf="roleName=='Admin'"  style="text-align: right ;">{{this.GrandTotal.ProfitAmt}}</td>
                      <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ngb-pagination [collectionSize]="pager.totalItems " [pageSize]="pager.pageSize "
            [(page)]="pager.currentPage " [maxSize]="10 " [rotate]="true "
            (pageChange)="setPage(pager.currentPage) " [boundaryLinks]="true ">
        </ngb-pagination>

        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #PrintModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Sale Order</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
  </div>
  <div class="modal-body">
    <!-- <iframe width="100%" height="600px"></iframe> -->
    <ngx-extended-pdf-viewer [src]="pdfdoc" [useBrowserLocale]="true" ></ngx-extended-pdf-viewer>
  </div>
</ng-template>