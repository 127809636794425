<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen] = "true"></ngx-spinner>

<div class="container-fluid">
  <!-- <app-pagetitle title="Purchase Order" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
  <div class="row">
    <div class="col-12">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">Pending Payment List (Sale)</h4>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <button class="btn  btn-outline-success w-md waves-effect waves-light" (click)="saveExcel()"
                type="button">
                <i class="mdi mdi-microsoft-excel"></i> Excel</button>
              <button class="btn  btn-outline-warning w-md waves-effect waves-light" (click)="saveCSV()" type="button">
                <i class="mdi mdi-clipboard-text"></i> CSV</button>
              <button class="btn  btn-outline-danger w-md waves-effect waves-light" (click)="PrintTable()" type="button">
                <i class="mdi mdi-file-pdf"></i> PDF</button>

              <!-- btn btn-success w-md waves-effect waves-light -->
              <!-- <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Show
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> entries</label></div> -->
            </div>
          </div>
          <div class="row mb-md-2">
              <div class="col-sm-12 col-md-6">
              <!-- <button class="btn  btn-outline-primary w-md waves-effect waves-light" (click)="openPO()" type="button">
                <i class="mdi mdi-account-plus"></i> Add PO</button> -->
              </div>

            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div class="row navbar">
                <div class="col-6 d-flex justify-content-left">
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <div class="row">
                    <div>
                      <input class="form-control" [(ngModel)]="searchString" placeholder="Search" id="searchString"
                        name="searchString" (keyup)="searchExistingRecords($event)" />
                    </div>
                    <!-- <div>
                            <button class="btn btn-primary" (click)="searchDBRecords()"><i
                                    class="ti-search m-r-5 m-l-5"></i></button>
                        </div> -->
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive">
              <table id="table" #table class="table table-hover m-t-20 table-bordered">
                <thead class="bg-info text-white">
                  <tr class="">
                    <th>Customer Name</th>
                    <th>Code</th>
                    <th>City</th>
                    <th>DocDate</th>
                    <th>Doc No</th>
                    <th>Balance Amount</th>
                    <th>Due Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="border border-info">
                  <tr *ngFor="let item of work">

                    <td>{{item.AccName}}</td>
                    <td>{{item.AccCode}}</td>
                    <td>{{item.City}}</td>
                    <td>{{item.DocDate}}</td>
                    <td>{{item.DocNo}}</td>
                    <td style="text-align: right ;">{{item.PenAmount}}</td>
                    <td>{{item.PaymentDueDate}}</td>
                    <td>
                    <a href="javascript:void(0);" (click)="editRecord(item)" class="text-primary" ngbTooltip="Edit"><i
                      class="mdi mdi-file-document-edit-outline font-size-22 "></i></a>
                      &nbsp;  <a  href="javascript:void(0);" (click)="openConfirmationModal(item,confirmation)" class="text-primary" ngbTooltip="Edit"><i
                        class="mdi mdi-comment-edit-outline font-size-22 "></i></a>

                    </td>
                    </tr>
                    <tr class="TotalRow">
                      <td>Total</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style="text-align: right ;">{{this.GrandTotal.PenAmount}}</td>
                      <td></td>
                      <td></td>
                    </tr>

                  </tbody>
              </table>
            </div>
            <ngb-pagination [collectionSize]="pager.totalItems " [pageSize]="pager.pageSize "
              [(page)]="pager.currentPage " [maxSize]="10 " [rotate]="true " (pageChange)="setPage(pager.currentPage) "
              [boundaryLinks]="true ">
            </ngb-pagination>

          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #DetailModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Pedning Payments</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
    <div class="modal-body">

    </div>
  </ng-template>
  <ng-template #confirmation let-modal>
    <form class="needs-validation" (ngSubmit)="updatePaymentDueDate()" [formGroup]="validationform">
    <div class="modal-header">
      <h5 class="modal-title mt-0">Comments</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
    <div class="modal-body">
  <div class="col-md-3">
      <div class="form-group">
        <label for="DueDate">Payment Due Date</label>
        <input type="date" [(ngModel)]="DueDate" formControlName="DueDate" 
        class="form-control" id="DueDate"
        [ngClass]="{'is-invalid': submit && form.DueDate.errors}"
        />
        <div *ngIf="submit && form.DueDate.errors" class="invalid-feedback">
          Please provide DueDate
        </div>

        <br>
        </div>
      </div>

      <div class="form-group">
    <label for="Comments">Comments</label>
    <textarea [(ngModel)]="comments" rows="4" formControlName="Comments" class="form-control" 
    id="Comments" [ngClass]="{'is-invalid': submit && form.Comments.errors}"></textarea>
    <div *ngIf="submit && form.Comments.errors" class="invalid-feedback">
      Please provide Comments
    </div>

    <br>
    <div *ngFor="let item of paymentCommentsHistory ">
      <span class="ti-user"></span> {{item.UserName}}, DueDate : {{item.DueDate}}, 
      Comments : {{item.Comments}}, LogDate : {{ item.LogDate }} 
  </div>
  <br>

    &nbsp;<button type="button" class="btn  btn-primary" type="submit">
      <i class="ri-save-3-line align-middle ml-2"></i> Save</button>
      &nbsp;<button type="button" class="btn  btn-secondary" (click)="modal.dismiss('Cross click')">
        <i class="ri-close-line align-middle mr-2"></i> Close</button>
    
  </div>
  
    </div>
  </form>
  </ng-template>
  