<div class="container-fluid">
  <app-pagetitle title="Form File Upload" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="card-title">Dropzone</div>
          <p class="card-title-desc">DropzoneJS is an open source library
            that provides drag’n’drop file uploads with image previews.
          </p>
          <div>
            <form>
              <dropzone class="dropzone"></dropzone>
            </form>
          </div>
          <div class="text-center mt-4">
            <button type="button" class="btn btn-primary">Send Files</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
