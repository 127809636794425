<div class="container-fluid">
  <div class="row">
    <div class="col-10">
      <div
        class="page-title-box d-flex align-items-center justify-content-between"
      >
        <h4 class="mb-0">Estimate</h4>
      </div>
    </div>
    <div class="col-2"></div>
  </div>
  <br />

  <form
    class="needs-validation"
    (ngSubmit)="validSubmit()"
    [formGroup]="validationform"
  >
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group">
                  <label for="ChallanNo">Doc No</label>
                  <input
                    class="form-control"
                    formControlName="ChallanNo"
                    id="ChallanNo"
                    [ngClass]="{
                      'is-invalid': submit && form.ChallanNo.errors
                    }"
                  />
                  <div
                    *ngIf="submit && form.ChallanNo.errors"
                    class="invalid-feedback"
                  >
                    Please provide Doc No
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="Dated">Doc Date</label>
                  <input
                    type="date"
                    class="form-control"
                    formControlName="Dated"
                    id="Dated"
                    [ngClass]="{ 'is-invalid': submit && form.Dated.errors }"
                  />
                  <div
                    *ngIf="submit && form.Dated.errors"
                    class="invalid-feedback"
                  >
                    Please provide Date
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="MasterRemarks">Remarks</label>
                  <input type="text" class="form-control" formControlName="MasterRemarks" id="MasterRemarks">
                </div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
  </form>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Product Detail</h4>
          <div class="row" style="padding: 10px !important ">
            <div class="col-sd-3">
              <input
                [(ngModel)]="barCodeValueModel"
                (keyup.enter)="barcodeKeyPress($event)"
                class="form-control"
                placeholder="Sacn BarCode"
                id="BarCode"
              />
            </div>
            <div class="col-sd-3">
              <button
                class="btn btn-primary"
                (click)="barcodeKeyPress(barCodeValueModel)"
              >
                scan
              </button>
            </div>
          </div>

          <div class="table-responsive" style="min-height: 270px">
            <table class="table table-hover mb-0">
              <thead>
                <tr class="TableHeaderRow">
                  <th>#</th>
                  <th style="min-width: 250px !important">Item Name</th>
                  <th style="min-width: 150px !important">Narration</th>
                  <th>Box</th>
                  <th>Pcs/Box</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Amount</th>
                  <th>UOM</th>

                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of VoucherDetailList; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>
                    <ng-select
                      name="IT_CODE"
                      id="IT_CODE + {{ item.id }}"
                      [items]="ItemList"
                      bindLabel="Name"
                      bindValue="Code"
                      (change)="checkLastRow($event, item, i)"
                      [(ngModel)]="item.IT_CODE"
                      appendTo="body"
                    ></ng-select>
                  </td>
                  <td>
                    <input
                      class="form-control"
                      id="narration + {{ item.id }}"
                      value=""
                      [(ngModel)]="item.narration"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      style="text-align: right"
                      class="form-control"
                      id="Case_Qty + {{ item.id }}"
                      value="0"
                      (change)="calculateProductAmount($event, item)"
                      [(ngModel)]="item.Case_Qty"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      style="text-align: right"
                      class="form-control"
                      id="PcsPerBox + {{ item.id }}"
                      value="0"
                      (change)="calculateProductAmount($event, item)"
                      [(ngModel)]="item.PcsPerBox"
                    />
                  </td>

                  <td>
                    <input
                      type="number"
                      style="text-align: right"
                      class="form-control"
                      id="QTY + {{ item.id }}"
                      value="0"
                      (change)="calculateProductAmount($event, item)"
                      [(ngModel)]="item.QTY"
                    />
                  </td>
                  <td>
                    {{ item.RATE }}
                  </td>
                  <td>
                    {{ item.AMOUNT }}
                  </td>
                  <td>
                    {{item.UOM}}
                  </td>
                  <td>
                    <button
                      type="button"
                      class="mdi mdi-18px mdi-delete text-danger"
                      (click)="DeletePrdItem(item, i)"
                    ></button>
                  </td>
                </tr>

                <tr class="TotalRow">
                  <td></td>
                  <td>Total</td>
                  <td></td>
                  <td style="text-align: right; padding-right: 30px">
                    {{ _BoxTotal }}
                  </td>
                  <td></td>
                  <td style="text-align: right; padding-right: 30px">
                    {{ _QtyTotal }}
                  </td>
                  <td></td>
                  <td style="text-align: right; padding-right: 30px">
                    {{ _AmountTotal }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <!-- <label for="PaymentTerms">Remarks</label>
                  <textarea rows="4" class="form-control" formControlName="PaymentTerms" id="PaymentTerms"></textarea> -->
              </div>
            </div>

            <div class="col-lg-2"></div>
            <div class="col-lg-2">
              <br />
              <div class="card">
                <button class="btn btn-primary" (click)="validSubmit()">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->

  <!-- </form> -->
</div>
