import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
//import { GenericAuthService } from 'src/app/core/services/genericAuth.service';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { point } from 'leaflet';
import { CommonService } from '../CommonService.service';
//import { clsCatalogNumber } from '../../classes/common';

@Injectable()
export class SetupService {
	_host = '';
	userID = 0;
	token = '';
	id = 0;
	base64: any = '';
	fileBase64: any = '';
	//catalog: clsCatalogNumber[] = [];


	sendOutKitList = [];
	clientlistSearch = '';
	sendOutKitListSearch = '';
	physicianslistSearch = '';
	testworklistSearch = '';
	testCPTworklistSearch = '';
	referencerangelistsearch = ''
	paneltestworklistsearch = ''
	cptpricinglistSearch = '';
	instrumentlistSearch = '';
	elisatestlistSearch = '';
	constructor(
		//private _auth: GenericAuthService,
		private _sessionCall: SessionCheck,
		private router: Router,
		private toastr: ToastrService,
		private _commonService: CommonService,
		private spinner: NgxSpinnerService
	) {
	}



	getSearchProducts(SizeName) {
		try {
			const param = {
				'UserID': 0,
				'SearchString': SizeName
			};
			return new Observable(observer => {
				this._commonService.postMethodWithToken('sp_GetPrdSearch', param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	getPDFAsResult(Id,methodname) {
		try {
			const param = {
				'UserID': this._sessionCall.getLoginUserID(),
			};
			const link = 'Misc/'+ methodname + '?Id=' + Id + '&userid=' + this._sessionCall.getLoginUserID() + '&token=' + this._sessionCall.getToken();
			return new Observable(observer => {

				this._commonService.postMethodForCustomrCall(link, param)
					.subscribe((doc) => {
						observer.next(doc);
						observer.complete();
					});

			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}

	getSearchList(SearchString,sp) {
		try {
			const param = {
				'UserID': 0,
				'SearchString': SearchString
			};
			return new Observable(observer => {
				this._commonService.postMethodWithTokenAllResult(sp, param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	addPOData(POID, JsonMasterData, JsonPrdData) {
		try {
			const param = {
				'UserID': 0,
				'POID': POID,
				'JsonMasterData': JsonMasterData,
				'JsonPrdData': JsonPrdData
			};
			return new Observable(observer => {
				this._commonService.postMethodWithToken('dbo.sp_addPOData', param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	addAccountMst(ID, JsonMasterData, JsonPrdData) {
		try {
			const param = {
				'UserID': 0,
				'ID': ID,
				'JsonMasterData': JsonMasterData,
				'JsonPrdData': JsonPrdData
			};
			return new Observable(observer => {
				this._commonService.postMethodWithToken('dbo.sp_addActMst', param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	addRecord(ID, JsonMasterData, JsonPrdData,sp) {
		try {
			const param = {
				'UserID': 0,
				'ID': ID,
				'JsonMasterData': JsonMasterData,
				'JsonPrdData': JsonPrdData
			};
			return new Observable(observer => {
				this._commonService.postMethodWithToken(sp, param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	addRecordWithAllResults(ID, JsonMasterData, JsonPrdData,sp) {
		try {
			const param = {
				'UserID': 0,
				'ID': ID,
				'JsonMasterData': JsonMasterData,
				'JsonPrdData': JsonPrdData
			};
			return new Observable(observer => {
				this._commonService.postMethodWithTokenAllResult(sp, param)
					.subscribe((list) => {
						observer.next(list.ResultSets);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	GetPOList(SearchJson='') {
		try {
			const param = {
				'UserID': 0,
				'SearchJson' :SearchJson
			};
			return new Observable(observer => {
				this._commonService.postMethodWithToken('dbo.sp_GetPoList', param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	GetEstimatePrdListById(SearchJson='') {
		try {
			const param = {
			//	'UserID': 0,
				'SearchJson' :SearchJson
			};
			return new Observable(observer => {
				this._commonService.postMethodWithToken('usp_getSaleDataByEstimateId', param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	GetAccountList(AccType='All') {
		try {
			const param = {
				'UserID': 0,
				'AccType' : AccType,
			};
			return new Observable(observer => {
				this._commonService.postMethodWithTokenAllResult('sp_AccountList', param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	GetItemList(Type='All') {
		try {
			const param = {
				'UserID': 0,
				'Type' : Type,
			};
			return new Observable(observer => {
				this._commonService.postMethodWithTokenAllResult('sp_getItemList', param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}

	GetAccountListSearch(AccType='All',SearchJson:string='') {
		try {
			const param = {
				'UserID': 0,
				'AccType' : AccType,
				'SearchJson':SearchJson
			};
			return new Observable(observer => {
				this._commonService.postMethodWithTokenAllResult('sp_AccountList_Search', param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	GetTaxHeadList(HeadType='All') {
		try {
			const param = {
				'UserID': 0,
				'SearchJson' : "TaxHead",
			};
			return new Observable(observer => {
				this._commonService.postMethodWithTokenAllResult('dbo.sp_getCommonList', param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}

	GetRegularworkList(sp,SearchJson='',SearchString2='') {
		try {
			const param = {
				'UserID': 0,
				'SearchJson': SearchJson,
				'SearchString2' : SearchString2
			};
			return new Observable(observer => {
				this._commonService.postMethodWithTokenAllResult(sp, param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	GetPORecord(POId) {
		try {
			const param = {
				'UserID': 0,
				'POId': POId,
			};
			return new Observable(observer => {
				this._commonService.postMethodWithToken('dbo.sp_GetPoRecord', param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	GetRecordInfo(Id,sp,SearchJson='') {
		try {
			const param = {
				'UserID': 0,
				'Id': Id,
				'SearchJson':SearchJson
			};
			return new Observable(observer => {
				this._commonService.postMethodWithTokenAllResult(sp, param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	GetAllRecordInfo(Id,sp) {
		try {
			const param = {
				'UserID': 0,
				'Id': Id,
			};
			return new Observable(observer => {
				this._commonService.postMethodWithTokenAllResult(sp, param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	GetPOPDF(POID) {
		try {
			const param = {
				'UserID': 0,
				'POID': POID,
			};
			const link = 'api/AllSpecimen/POPDF?userid=' + this._sessionCall.getLoginUserID() + '&token=' + this._sessionCall.getToken() + '&poid=' + POID;
            return new Observable(observer => {
                this._commonService.postMethodForCustomrCall(link, param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	GetApiCallResult(apiEndPoint) {
		try {
			const param = {
                "UserID": 0,
            };
			const link =apiEndPoint;
            return new Observable(observer => {
                this._commonService.postMethodForCustomrCall(link, param)
					.subscribe((list) => {
						observer.next(list);
						observer.complete();
					});
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	}
	saveAccessionResultsDetailsUsingCSVWashFlowCyto(prm: any): Observable<any> {
        try {
            const param = {
                "UserID": 0,
                "AccessionJson": prm.AccessionJson,
                "AccesstionIds": prm.AccesstionIds,
                "FileName": prm.FileName,
                "fileType": prm.fileType,
                "File": prm.File,
                "AssayedDate": prm.AssayedDate,
            };
            console.log(param);
            return new Observable(observer => {
                //this._auth.postMethodWithToken2D('Sp_SaveAccessionResultsDetailsUsingCSVWashFlowCyto/other/auth/check/function/change/common/inside', param)
                this._commonService.postMethodForCustomrCall('api/AllSpecimen/POPDF', param)
                    .subscribe((list) => {
                        observer.next(list.Table);
                        observer.complete();
                    })
            });
        } catch (error) {
            this._commonService.handleError(error, this._sessionCall, this.toastr)
        }
    }
	AddMasterlist(jsonData:any): Observable<any> {
		try {
			const param = {
                "UserID": 0,
				"Data":jsonData
            };
		  const link =
			"api/Save/AddMaster?userid=" +
			this._sessionCall.getLoginUserID() +
			"&token=" +
			this._sessionCall.getToken();
		  return new Observable(observer => {
			this._commonService.postMethodForCustomrCall(link, param)
			  .subscribe((list) => {
				observer.next(list);
				observer.complete();
			  });
		  });
		} catch (error) {
		  this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	  }
    getDataApiCall(address,param): Observable<any> {
      try {

        return new Observable(observer => {
        this._commonService.postMethodForCustomrCall(address, param)
          .subscribe((list) => {
          observer.next(list);
          observer.complete();
          });
        });
      } catch (error) {
        this._commonService.handleError(error, this._sessionCall, this.toastr);
      }
      }
	  AddMasterDetailData(MasterJsonData:any,DetailsJsonData:any,DetailsJsonData2=null,DetailsJsonData3=null): Observable<any> {
		try {
			const param = {
                "UserID": 0,
				"MasterData":MasterJsonData,
				"DetailsData":DetailsJsonData,
				"DetailsData2":DetailsJsonData2,
				"DetailsData3":DetailsJsonData3,
            };
		  const link =
			"api/Save/AddMasterDetail?userid=" +
			this._sessionCall.getLoginUserID() +
			"&token=" +
			this._sessionCall.getToken();
		  return new Observable(observer => {
			this._commonService.postMethodForCustomrCall(link, param)
			  .subscribe((list) => {
				observer.next(list);
				observer.complete();
			  });
		  });
		} catch (error) {
		  this._commonService.handleError(error, this._sessionCall, this.toastr);
		}
	  }
}





