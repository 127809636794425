<div class="container-fluid">
  <div class="row">
    <div class="col-10">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">Sale Invoice</h4>
      </div>
    </div>
    <div class="col-2">
    </div>
  </div>
<br>

   <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">

            <div class="row">

              <div class="col-md-4">
                <div class="form-group">
                  <label for="A_CODE">Billing Party</label>
                  <ng-select formControlName="A_CODE" name="A_CODE" id="A_CODE"
               (change)="BillingParty_Change($event)"      [items]="BillingAcList"  [(ngModel)]="selectedAccID"
                bindLabel="Name" bindValue="Code" ></ng-select>
                  <div class="invalidValidate" *ngIf="checkSupplier()">
                    Please provide Billing Account
                  </div>
                </div>

              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="smDispPartyCode">Shipping Party</label>
                  <ng-select formControlName="smDispPartyCode" name="smDispPartyCode" id="smDispPartyCode"
                   [items]="BillingAcList"
                bindLabel="Name" bindValue="Code"
                    [(ngModel)]="SelectedDispAc"></ng-select>
                  <div class="invalidValidate" *ngIf="checkSupplier()">
                    Please provide Shipping Account
                  </div>
                </div>

              </div>
            </div>
            <div class="row">

              <div class="col-md-4">
                <div class="form-group">
                  <label for="sac_code">Sale Account</label>
                  <ng-select formControlName="sac_code" name="sac_code" id="sac_code"
                (change)="getVoucherDetailList()"    [items]="SaleAcList"
                bindLabel="Name" bindValue="Code"
                    [(ngModel)]="selectedSaleAcID"></ng-select>
                  <div class="invalidValidate" *ngIf="checkSupplier()">
                    Please provide Sale Account
                  </div>
                </div>

              </div>


              <div class="col-md-2">
                <div class="form-group">
                  <label for="BILL_NO">Invoice No</label>
                  <input class="form-control" formControlName="BILL_NO" id="BILL_NO"
                     [ngClass]="{'is-invalid': submit && form.BILL_NO.errors}">
                  <div *ngIf="submit && form.BILL_NO.errors" class="invalid-feedback">
                    Please provide Invoice No
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="DATED">Invoice Date</label>
                  <input type="date" class="form-control" formControlName="DATED"
                   id="DATED"
                    [ngClass]="{'is-invalid': submit && form.DATED.errors}">
                  <div *ngIf="submit && form.DATED.errors" class="invalid-feedback">
                    Please provide Date
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label for="TRANS_TYPE">Transaction Type</label>
                  <ng-select formControlName="TRANS_TYPE" name="TRANS_TYPE" id="TRANS_TYPE"
                  [items]="VoucherTypeList"  bindLabel="Name" bindValue="Code" ></ng-select>
                </div>
              </div>

            </div>
            <!-- <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="MasterRemarks">Remarks</label>
                  <input type="text" class="form-control" formControlName="MasterRemarks" id="MasterRemarks">
                </div>
              </div>
            </div> -->


          </div>
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
   </form>
    <div class="row" >
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" >
            <h4 class="card-title">Product Detail</h4>
            <div class="row" style="padding:10px!important ;">
              <div class="col-sd-3">
                <input (keydown.enter)="barcodeKeyPress($event)" class="form-control" placeholder="Sacn BarCode" formControlName="BarCode" id="BarCode">
              </div>
            </div>


            <div class="table-responsive" style="min-height:270px ;">
              <table class="table table-hover mb-0">

                <thead>
                  <tr class="TableHeaderRow">
                    <th>#</th>
                    <th style="min-width:250px!important">Item Name</th>
                    <th style="min-width:250px!important">Narration</th>
                    <th >Box</th>
                    <th >Pcs/Box</th>
                    <th >Qty</th>
                    <th>UOM</th>
                    <th>Rate</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of VoucherDetailList;let i=index">
                    <td>{{i+1}}</td>
                    <td>
                      <ng-select name="IT_CODE" id="IT_CODE + {{item.id}}"
                      [items]="ItemList"  bindLabel="Name" bindValue="Code"
                      (change)="checkLastRow($event, item,i)" [(ngModel)]="item.IT_CODE" appendTo="body"></ng-select>
                      </td>
                      <td>
                        <input class="form-control" id="narration + {{item.id}}" value=""
                        [(ngModel)]="item.narration"></td>
                        <td>
                          <input type="number" style="text-align:right ;" class="form-control" id="Case_Qty + {{item.id}}" value="0"
                        (change)="calculateProductAmount($event,item)"   [(ngModel)]="item.Case_Qty"></td>
                            <td>
                              <input type="number" style="text-align:right ;" class="form-control" id="PcsPerBox + {{item.id}}" value="0"
                            (change)="calculateProductAmount($event,item)"   [(ngModel)]="item.PcsPerBox"></td>

                            <td>
                          <input type="number" style="text-align:right ;" class="form-control" id="QTY + {{item.id}}" value="0"
                        (change)="calculateProductAmount($event,item)"   [(ngModel)]="item.QTY"></td>
                          <td>
                            <input class="form-control" id="uom + {{item.id}}" value="0"
                            [(ngModel)]="item.UOM"></td>

                          <td>
                            <input type="number" style="text-align:right ;"class="form-control" id="RATE + {{item.id}}" value="0"
                            (change)="calculateProductAmount($event,item)"    [(ngModel)]="item.RATE"></td>

                        <td>
                      <input type="number" style="text-align:right ;"class="form-control" id="AMOUNT + {{item.id}}" value="0"
                      [(ngModel)]="item.AMOUNT"></td>

                      <td>
                      <button type="button" class="mdi mdi-18px mdi-delete text-danger"
                        (click)="DeletePrdItem(item,i)"></button>
                    </td>
                  </tr>

                  <tr class="TotalRow">
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="text-align:right ;padding-right: 30px;">{{TotalItemQty}}</td>
                    <td></td>
                    <td></td>
                    <td style="text-align:right ;padding-right: 30px;">{{TotalItemAmount}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>

 <div class="row">
  <div class="col-lg-12">
    <div class="card">
      <div class="card-body">
        <div class="row">
        <div class="col-md-6">
          <h4 class="card-title">Other Info</h4>
          <div class="table-responsive">
            <table class="table table-hover mb-0">
              <thead>
                <tr class="TableHeaderRow">

                  <th style="min-width:250px!important">Description</th>
                  <th style="min-width:250px!important">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of OtherInfoList;let i=index">

                  <td>{{item.HEAD_NAME}}</td>
                  <td>
                    <input class="form-control" id="HeadValue + {{item.HeadValue}}"
                    [(ngModel)]="item.HeadValue">

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="col-md-6">
          <h4 class="card-title">Tax Info</h4>
          <div class="table-responsive">
            <table class="table table-hover mb-0">
              <thead>
                <tr class="TableHeaderRow">

                  <th style="min-width:200px!important">Description</th>
                  <th style="min-width:150px!important">Tax %</th>
                  <th style="min-width:150px!important">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of TaxList;let i=index">

                  <td>{{item.HEAD_NAME}}</td>
                  <td>
                    <input class="form-control" style="text-align:right ;" id="TAX_PER + {{item.TAX_PER}}"
                 (change)="calcTotalBillAmt()"   [(ngModel)]="item.TAX_PER" *ngIf="item.PERCENTAGE=='YES'">

                  </td>
                  <td>
                    <input class="form-control" style="text-align:right ;" id="TAX_AMT + {{item.TAX_AMT}}"
                    (change)="calcTotalBillAmt()"    [(ngModel)]="item.TAX_AMT">

                  </td>
                </tr>
                <tr>
                  <td style="font-weight: bold;">Round off</td>
                  <td></td>
                  <td>
                    <input type="number" style="text-align:right ;" class="form-control"  id="ROUND_OFF"
                  (change)="calcNetAmtwithRoundoff()"  [(ngModel)]="RoundOff">
                  </td>
                </tr>
                <tr>
                  <td style="font-weight: bold;">Net Amount</td>
                  <td></td>
                  <td>
                    <input type="number" style="text-align:right ;" disabled="true" readonly="true"
                    class="form-control" id="NET_AMT"
                    [(ngModel)]="BillAmount">


                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      </div>
    </div>
  </div>
 </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-8">
                <div class="form-group">
                  <!-- <label for="PaymentTerms">Remarks</label>
                  <textarea rows="4" class="form-control" formControlName="PaymentTerms" id="PaymentTerms"></textarea> -->
                </div>
              </div>

                <div class="col-lg-2">
                </div>
                  <div class="col-lg-2">
                  <br>
                  <div class="card">
                    <button class="btn btn-primary " (click)="validSubmit()">Save</button>
                </div>
              </div>


            </div>
            </div>



        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
    <!-- end row -->

  <!-- </form> -->


</div>
