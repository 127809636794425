<div class="container-fluid">

  <app-pagetitle title="Packing List" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <div class="row">
    <div class="col-lg-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Select Product</h4>
          <div class="card">
            <input class="form-control" [(ngModel)]="searchSize" placeholder="Size" id="searchSize" name="searchSize"
              (keydown.enter)="searchPrdFromDB($event)" />


          </div>
          <div *ngIf="SearchPanelVisible" class="table-responsive">
            <table class="table table-hover mb-0">

              <thead>
                <tr class="TableHeaderRow">
                  <th>#</th>
                  <th>Description</th>
                  <th>Invoice No</th>
                  <th>Invoice Date</th>
                  <th>Invoice Qty</th>
                  <th>Packed Qty</th>
                  <th>Pen Qty</th>
                  <th>Load Qty</th>
                  <th>Volume</th>
                  <th>Container No</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of SearchItems;let i=index">
                  <th scope="row">{{i+1}}</th>
                  <td>{{item.Description}}</td>
                  <td>{{item.InvoiceNo}}</td>
                  <td>{{item.InvoiceDate}}</td>
                  <td>{{item.LoadQty}}</td>
                  <td>{{item.PackQty}}</td>
                  <td>{{item.PenQty}}</td>
                  <td>
                    <input type="number" class="form-control" (change)="calculateProductTotal()" id="Qty + {{item.id}}" value="0"
                      [(ngModel)]="item.Qty">
                  </td>
                  <td>{{item.Qty/item.PrdContainerCapacity | number : '.2-2'}}</td>
                  <td>
                    <input type="text" class="form-control" id="ContainerNo + {{item.id}}" value=""
                      [(ngModel)]="item.ContainerNo">
                  </td>

                </tr>
                <tr class="TotalRow">
                  <td></td>
                  <td>Total</td>
                  <td></td>
                  <td></td>
                  <td>{{productTotal.LoadQty}}</td>
                  <td>{{productTotal.PackQty}}</td>
                  <td>{{productTotal.PenQty}}</td>
                  <td>{{productTotal.Qty}}</td>
                  <td></td>
                  <td></td>

                </tr>
              </tbody>
            </table>
            <div class="row">
              <div class="col-lg-10"></div>
              <div class="col-lg-2">
                <button class="btn btn-warning" (click)="addProducts()">Add & Continue</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  </div>
  <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <!-- <div class="col-md-5">
                <div class="form-group">
                  <label for="SupplierName">Supplier Name</label>
                  <ng-select formControlName="SupplierName" name="SupplierName" id="SupplierName"
                    [items]="SupplierList"  bindLabel="AccName" bindValue="AccCode" 
                    [(ngModel)]="selectedSupplierID"></ng-select>
                  <div class="invalidValidate" *ngIf="checkSupplier()">
                    Please provide Supplier
                  </div>
                </div>

              </div>
               -->

              <div class="col-md-3">
                <div class="form-group">
                  <label for="PackingNo">Packing No</label>
                  <input class="form-control" formControlName="PackingNo" id="PackingNo"
                    [ngClass]="{'is-invalid': submit && form.PackingNo.errors}">
                  <div *ngIf="submit && form.PackingNo.errors" class="invalid-feedback">
                    Please provide Packing No
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="PackingDate">Packing Date</label>
                  <input type="date" class="form-control" formControlName="PackingDate" id="PackingDate"
                    [ngClass]="{'is-invalid': submit && form.PackingDate.errors}">
                  <div *ngIf="submit && form.PackingDate.errors" class="invalid-feedback">
                    Please provide Packing Date
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="BLNo">BL No</label>
                  <input class="form-control" formControlName="BLNo" id="BLNo"
                    [ngClass]="{'is-invalid': submit && form.BLNo.errors}">
                  <div *ngIf="submit && form.BLNo.errors" class="invalid-feedback">
                    Please provide BLNo No
                  </div>
                </div>
              </div>

              <div class="col-md-3">
                <div class="form-group">
                  <label for="ArrivalDate">Arrival Date</label>
                  <input type="date" class="form-control" formControlName="ArrivalDate" id="ArrivalDate"
                    [ngClass]="{'is-invalid': submit && form.ArrivalDate.errors}">
                  <div *ngIf="submit && form.ArrivalDate.errors" class="invalid-feedback">
                    Please provide Arrival Date
                  </div>
                </div>
              </div>

              
            </div>
          </div>
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Product Detail</h4>

            <div class="table-responsive">
              <table class="table table-hover mb-0">

                <thead>
                  <tr class="TableHeaderRow">
                    <th>#</th>
                    <th>Description</th>
                    <th>Invoice No</th>
                    <th>Invoice Date</th>
                    <th>Container No</th>
                    <th>Invoice Qty</th>
                    <th>Packed Qty</th>
                    <th>Pen Qty</th>
                    <th>Load Qty</th>
                    <th>Volume(%)</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of ProductArray;let i=index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.Description}}</td>
                    <td>{{item.InvoiceNo}}</td>
                    <td>{{item.InvoiceDate}}</td>
                    <td>{{item.ContainerNo}}</td>
                    <td>{{item.LoadQty}}</td>
                    <td>{{item.PackQty}}</td>
                    <td>{{item.PenQty}}</td>
                    <td>{{item.Qty}}</td>
                   <td> {{item.Volume}}</td>
                    <td>
                      <button type="button" class="mdi mdi-18px mdi-delete text-danger"
                        (click)="DeletePrdItem(item,i)"></button>
                    </td>
                  </tr>
                  <tr class="TotalRow">
                    <td></td>
                    <td>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{grandTotal.LoadQty}}</td>
                    <td>{{grandTotal.PackQty}}</td>
                    <td>{{grandTotal.PenQty}}</td>
                    <td>{{grandTotal.Qty}}</td>
                    <td>{{grandTotal.Volume}}</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>
  </form>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Container Detail</h4>

            <div class="table-responsive">
              <table class="table table-hover mb-0">

                <thead>
                  <tr class="TableHeaderRow">
                    <th>#</th>
                    <th>Container No</th>
                    <th>Weight</th>
                    <th>Volume(%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of containerList;let i=index">
                    <th scope="row">{{i+1}}</th>
                    <td>{{item.ContainerNo}}</td>
                    <td>
                      <input type="text" class="form-control" (change)="calculateTotalContainerTotal()" id="containerListWeight+{{item.id}}" value="0"
                        [(ngModel)]="item.Weight">
                      </td>
                      <td>
                        <input type="text" class="form-control" (change)="calculateTotalContainerTotal()" id="containerListVolume+{{item.id}}" value="0"
                          [(ngModel)]="item.Volume">
                        </td>
                    </tr>
                  <tr class="TotalRow">
                    <td></td>
                    <td>Total</td>
                    <td>{{containerListGrandTotal.Weight}}</td>
                    <td>{{containerListGrandTotal.Volume}}</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>
    <div class="row">
      <div class="col-lg-10">
      </div>
      <div class="col-lg-2">
        <div class="card">
          <button class="btn btn-rounded btn-primary " (click)="validSubmit()" >
            Save</button>
        </div>
      </div>
    </div>
    <!-- end row -->



</div>