import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './EstimateVoucher.mustmatch';
//import { icons } from '../data';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { format, parse } from 'path';
import { threadId } from 'worker_threads';
import { IGrandTotal, IProductModel } from './estimateDataModel';

@Component({
  selector: 'app-EstimateVoucher',
  templateUrl: './EstimateVoucher.component.html',
  styleUrls: ['./EstimateVoucher.component.scss']
})

/**
 * Forms Validation component
 */
export class EstimateVoucherComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form
  SaleAcList: any = [];
  BillingAcList: any = [];
  OtherInfoList: any = [];
  ItemList: any = [];
  TaxList: any = [];
  TaxMasterList: any = [];
  TaxDetailList: any = [];
  selectedSaleAcID: string;
  selectedAccID: string;
  SelectedDispAc: string;
  selectedAccID2: string;
  VoucherDetailList: any = [];
  PaymentTypes: any = [];
  validateTableRates: boolean = true;
  TotalItemQty: number;
  TotalItemAmount: number;
  BillAmount: number;
  RoundOff: number;
  TaxableAmount: number;
  _grandTotal: IGrandTotal;
  grandtotal2: any;
  _QtyTotal: number;
  _AmountTotal: number;
  _BoxTotal: number;
  barCodeValueModel : string;
  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router: Router
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;
  SearchItems: any = [];
  SearchPanelVisible: boolean;
  TotalQty: any;
  TotalAmount: any;
  EntryID: any;
  editMode: boolean;
  grandTotal: any = [];
  productTotal: any = [];
  AccountList: any = [];
  ProductArray: any = [];
  VoucherTypeList: any[];
  VoucherTypeCode: any[];
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Packing List', active: true }];
    this.EntryID = 0;
    this.EntryID = this.route.snapshot.queryParamMap.get('Id') ? this.route.snapshot.queryParamMap.get('Id') : '';
    this.editMode = false;
    this.PaymentTypes.push('TT');
    this.PaymentTypes.push('LC');
    /**
     * Bootstrap validation form data
     */
    this.validationform = this.formBuilder.group({
      ChallanNo: ['', [Validators.required]],
      Dated: ['', [Validators.required]],
      S_NO: [''],
      NET_AMT: ['']

    });
    var date = new Date();
    date.setDate(date.getDate());
    this.validationform.patchValue(
      {
        Dated: formatDate(date, "yyyy-MM-dd", "en-us"),
      }
    )
    this.setVoucherList();
    this.getItemList();
    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;


  }
  setVoucherList() {
    this.addBlankRow();
    //this.addBlankRow();
  }

  addBlankRow() {
    let productModel: IProductModel;
    productModel = {
      ROW_NO: this.VoucherDetailList.length + 1,
      IT_CODE: '',
      narration: '',
      AMOUNT: 0,
      QTY: 0,
      Case_Qty: 0,
      PcsPerBox: 1,
      RATE: null,
      S_NO: 0,
      id: 0,
      UN_CODE: '',
      ITEM_NAME: '',
      UNIT: ''
    }
    this.VoucherDetailList.push(
      productModel
    );
  }
  checkLastRow(event, item, index) {
    item.UN_CODE = event.UN_CODE;
    item.UOM = event.UnitName;
    item.RATE = event.Sale_Rate;
    this.calculateProductAmount(event, item);
    if (index == this.VoucherDetailList.length - 1) {
      this.addBlankRow();
    }
  }
  get form() {
    return this.validationform.controls;
  }

  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      this.validationform.patchValue(
        {
          NET_AMT: this.BillAmount
        }
      )
      const VoucherDetailList2 = this.VoucherDetailList.filter(r => r.QTY != 0 && r.QTY != null && r.QTY != undefined);
      if (VoucherDetailList2 == undefined || VoucherDetailList2.length == 0) {
        this.toaster.error('Please check Product List');
        return;
      }
      try {
        this.spinner.show();
        var JsonMaster = (this.validationform.value)
        JsonMaster.VType = '1005';

        this.setup.AddMasterDetailData(JSON.stringify(JsonMaster), JSON.stringify(VoucherDetailList2),
        )
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data.Status == 1) {
            this.toaster.success(data.ResponseMessage);
            this.router.navigate(['/entry/estimateList']);
          }
          else {
            this.toaster.error(data[0].ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }
    }
    else {
      this.toaster.error('Please enter required values');
    }
  }
  formSubmit() {
    this.formsubmit = true;

  }
  checkSupplier(): boolean {
    if (this.submit && !this.selectedAccID) {
      return true;
    }
    else {
      return false;
    }
  }


  DeletePrdItem(item, iRowIndex) {
    this.VoucherDetailList.splice(iRowIndex, 1);
  }
  editRecord(EntryID) {
    try {
      this.setup.GetRecordInfo(this.EntryID, 'sp_getEditRecord', 'Estimate')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.fillEditRecordData(data);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  getItemList() {
    try {
      this.setup.GetItemList('Estimate')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.ItemList = data.ResultSets[1];
          this.validationform.patchValue(
            {
              ChallanNo: data.ResultSets[0][0].challanNo
            }
          )
          if (+this.EntryID > 0) {
            this.editRecord(this.EntryID);
          }
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  getVoucherDetailList() {
    try {
      this.spinner.show();
      const SearchString =
      {
        SearchJson: this.selectedSaleAcID
      }
      this.setup.GetRegularworkList('sp_GetMaxVoucher', 'Estimate', this.selectedSaleAcID)
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          if (+this.EntryID == 0) {
            this.validationform.patchValue(
              {
                BILL_NO: data.ResultSets[1][0].DocNo,
                BILL_BOOK: data.ResultSets[1][0].BILL_BOOK
              }
            )
          }
          this.TaxList = data.ResultSets[2];

        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  fillEditRecordData(data) {
    data.ResultSets[0].forEach(element => {
      var keys = Object.keys(element);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { }
      })
    });
    this.editMode = true;
    //this.selectedAccID = data[0].BAc_Code;
    this.VoucherDetailList = [];
    this.OtherInfoList = [];
    this.TaxList = [];
    this.VoucherDetailList = data.ResultSets[1];
    this.TaxList = data.ResultSets[2];
    this.OtherInfoList = data.ResultSets[3];

    this.addBlankRow();
    this.calcTotalBillAmt();
  }
  calculateProductAmount(event, item) {
    item.QTY = +(+item.Case_Qty * +item.PcsPerBox).toFixed(2);
    item.AMOUNT =(item.RATE * item.QTY).toFixed(2);
    this.calcTotalBillAmt();
  }
  checkSupplier2(): boolean {
    if (this.selectedAccID2 == undefined || !this.selectedAccID2) {
      return false;
    }
    else {
      return true;
    }
  }
  checkAccDisabled() {
    // if (this.ProductArray != undefined && this.ProductArray.length > 0) {
    //   return true;
    // }
    return false;
  }
  BillingParty_Change(event) {
    if (this.SelectedDispAc == null || this.SelectedDispAc == undefined || this.SelectedDispAc == "") {
      this.SelectedDispAc = this.selectedAccID;
    }
    if (this.selectedSaleAcID == null || this.selectedSaleAcID == undefined || this.selectedSaleAcID == "") {
      this.selectedSaleAcID = event.LastSaleAc;
      this.getVoucherDetailList();
    }
  }


  calcTotalBillAmt() {
    this._QtyTotal = 0;
    this._BoxTotal = 0;
    this._AmountTotal=0;
    for (let i = 0; i <= this.VoucherDetailList.length - 1; i++) {
      var element: IProductModel = this.VoucherDetailList[i];
      this._QtyTotal += +element.QTY;
      this._BoxTotal += +element.Case_Qty;
      this._AmountTotal += +element.AMOUNT;
    }
  }
  calcNetAmtwithRoundoff() {
    this.BillAmount = +(+this.TotalItemAmount + +this.RoundOff).toFixed(2);
  }
  barcodeKeyPress(event) {
    var barCodeValue = this.barCodeValueModel;
    //alert(barCodeValue);

    // if (event.key === "Enter")
    {
      if (barCodeValue != "") {

        this.ItemList.forEach(itemmst_element => {

          if (itemmst_element.PCode == barCodeValue) {

            this.VoucherDetailList.push(
              {
                ROW_NO: this.VoucherDetailList.length + 1,
                IT_CODE: itemmst_element.Code,
                narration: '',
                AMOUNT: itemmst_element.Sale_Rate * 1,
                QTY: 1,
                Case_Qty: 1,
                PcsPerBox: itemmst_element.PackingPerBox,
                RATE: itemmst_element.Sale_Rate,
                S_NO: 0,
                id: '',
                UN_CODE: itemmst_element.UN_CODE,
                ITEM_NAME: itemmst_element.Name,
                UNIT: itemmst_element.UnitName,
                UOM: itemmst_element.UnitName,

              }
            );
            this.VoucherDetailList.forEach(
              r => {
                if (r.IT_CODE.length == 0) {
                  this.VoucherDetailList.splice(r.ROW_NO - 1, 1);
                }
              }
            )
            this.addBlankRow();
            this.barCodeValueModel="";
            this.calcTotalBillAmt();
            //event.target.value = "";
          }
        });
       // console.log(this.VoucherDetailList);
      }
    }
  }
}
