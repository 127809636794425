<div class="container-fluid">

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row ">
                    <div class="col-md-4">
                        <form novalidate class="col-12" [formGroup]="changePasswordFormData"
                            >
                            <div class="input-group mb-3">
                                <div class="col-lg-12">
                                    <h5 class="border-bottom">Change your Password</h5>
                                </div><br><br>
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-lock"></i></span>
                                </div>
                                <input type="password" required minlength="6" formControlName="oldPassword"
                                    id="oldPassword" name="oldPassword" class="form-control form-control-lg"
                                    placeholder="Enter old password" required>
                            </div>

                            <div *ngIf="!changePasswordFormData.get('oldPassword').valid && (changePasswordFormData.get('oldPassword').dirty || changePasswordFormData.get('oldPassword').touched)"
                                class="text-danger">
                                <div *ngIf="changePasswordFormData.get('oldPassword').errors.required"> Old Password is
                                    required. </div>
                                <div *ngIf="changePasswordFormData.get('oldPassword').errors.minlength"> Old Password
                                    must be at least 6 characters long.</div>
                            </div>

                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-lock"></i></span>
                                </div>
                                <input type="password" required minlength="6" formControlName="password" id="password"
                                    name="password" class="form-control form-control-lg"
                                    placeholder="Enter new password" required>
                            </div>

                            <div *ngIf="!changePasswordFormData.get('password').valid && (changePasswordFormData.get('password').dirty || changePasswordFormData.get('password').touched)"
                                class="text-danger">
                                <div *ngIf="changePasswordFormData.get('password').errors.required"> New Password is
                                    required. </div>
                                <div *ngIf="changePasswordFormData.get('password').errors.minlength"> New Password must
                                    be at least 6 characters long.</div>
                            </div>
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text" id="basic-addon1"><i class="ti-lock"></i></span>
                                </div>
                                <input type="password" formControlName="confirmPassword" validateEqual="password"
                                    minlength="6" id="confirmPassword" name="confirmPassword"
                                    class="form-control form-control-lg" placeholder="Enter confirm password" required>
                            </div>
                            <div *ngIf="!changePasswordFormData.get('confirmPassword').valid && (changePasswordFormData.get('confirmPassword').dirty || changePasswordFormData.get('confirmPassword').touched)"
                                class="text-danger">
                                <div *ngIf="changePasswordFormData.get('confirmPassword').errors.required"> Confirm
                                    Password is required. </div>
                                <div *ngIf="changePasswordFormData.get('confirmPassword').errors.minlength">Confirm
                                    Password must be at least 6 characters long.</div>
                                <div *ngIf="!changePasswordFormData.get('confirmPassword').errors.validateEqual && changePasswordFormData.value.confirmPassword !== ''">
                                    Password Mismatch.</div>
                            </div>

                            <div>
                            </div>
                            <div class="row m-t-20">
                                <div class="col-5">
                                    <button class="btn btn-block btn-success" type="button" (click)="passwordChange()">
                                        Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-4">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>