import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

 
import { ToastrService } from 'ngx-toastr';

import { VersionControlling } from './versioning/versionControlling.service';
import { VersionCheckService } from './versioning/version-check.service';
import { environment } from '../../../environments/environment';
import { SecurityCheck } from './security/securitycheck.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SessionCheck {
    constructor(public router: Router, protected _securityCheck: SecurityCheck,
        private toaster: ToastrService
        , private _versionCheckService: VersionCheckService,
        private spinner: NgxSpinnerService) { }

    getToken() {
        var name = 'token';
        this._versionCheckService.initVersionCheck(environment.versionCheckURL);
//        return 'orR1pc1pAarr2qc7FSPUXpTD7Dm3IlJl0ywQoHgvz7mDkeOGTheBnHJrSkQbPwKB';
        return (sessionStorage.getItem(name) == null || sessionStorage.getItem(name) == '') ? '' : this._securityCheck.Decrypt(sessionStorage.getItem(name));
    }
    getLoginUserID() {''
        var name = 'userId';
        this._versionCheckService.initVersionCheck(environment.versionCheckURL);
        return (sessionStorage.getItem(name) == null || sessionStorage.getItem(name) == '') ? '' : this._securityCheck.Decrypt(sessionStorage.getItem(name));
    }
    getLoginUserName() {
        var name = 'UserFullName';
        this._versionCheckService.initVersionCheck(environment.versionCheckURL);
        const username=(sessionStorage.getItem(name) == null || sessionStorage.getItem(name) == '') ? '' : this._securityCheck.Decrypt(sessionStorage.getItem(name));
        if(username=='' || username== undefined)
        {
            this.logout('Admin');
            return "";
        }
        return username;
    }
    getLoginUser() {
        var name = 'UserName';
        this._versionCheckService.initVersionCheck(environment.versionCheckURL);
        return (sessionStorage.getItem(name) == null || sessionStorage.getItem(name) == '') ? '' : this._securityCheck.Decrypt(sessionStorage.getItem(name));
    }
    getLoginLoggedType() {
        var name = 'Role';
        this._versionCheckService.initVersionCheck(environment.versionCheckURL);
        return (sessionStorage.getItem(name) == null || sessionStorage.getItem(name) == '') ? '' : this._securityCheck.Decrypt(sessionStorage.getItem(name));
    }
    session_Check(name: string) {

        (sessionStorage.getItem('userId') == null || sessionStorage.getItem('Role') == null || sessionStorage.getItem('token') == null || sessionStorage.getItem('name') == null || sessionStorage.getItem('email') == null) ?
            name == 'Admin' ? this.clearAllSession('Admin') : this.clearAllSession(name)
            :
            (this.getSessionStorage('Role') != name) ? name == 'Admin' ? this.clearAllSession('Admin') : this.clearAllSession(name) : '';
    }
    session_Check_by_Name(name: any) {
        return (sessionStorage.getItem(name) == null) ? false : true;
    }
    getSessionStorage(name: any) {

        this._versionCheckService.initVersionCheck(environment.versionCheckURL);
        return (sessionStorage.getItem(name) == null || sessionStorage.getItem(name) == '') ? '' : this._securityCheck.Decrypt(sessionStorage.getItem(name));
    }
    setSessionStorage(id: any, name: any) {
        sessionStorage.setItem(id, this._securityCheck.Encrypt(name));
    }
    getEncryptedData(data: any) {
        return this._securityCheck.Encrypt(data);
    }
    getDecryptedData(data) {
        return this._securityCheck.Decrypt(data);
    }
    clearSession(name: any) {
        sessionStorage.removeItem(name);
    }
    clearAllSession(name: any) {
        sessionStorage.clear();
        if (name == 'Admin' || name == 'admin') {
            this.router.navigate(['/authentication/login'])
        } else if (name == 'Remote' || name == 'remote') {
            this.router.navigate(['/authentication/remoteLogin'])
        }
    }
    logout(loggedType: any) {
         
        // if (loggedType == 'Remote' || loggedType == 'remote') {

        //     this.toaster.success('You are successfully logged out', 'Logout Successfully!');
        //     this.router.navigate(['/authentication/remoteLogin']);
        // } else if (loggedType == 'Admin' || loggedType == 'admin') {

        //     this.toaster.success('You are successfully logged out', 'Logout Successfully!');
        //     this.router.navigate(['/authentication/login']);
        // } else {

        this.spinner.hide();
            this.router.navigate(['/account/login']);
//        }
        sessionStorage.clear();
        localStorage.clear();
         
        this.setSessionStorage('logout', 'Done');
    }
    logoutValidation() {
        sessionStorage.clear();
        this.router.navigate(['/authentication/login']);
    }
    logoutRemote() {
        sessionStorage.clear();
        this.router.navigate(['/authentication/remoteLogin']);
    }
    session_CheckWithQuery(name: string, query: string) {
        (sessionStorage.getItem('userId') == null || sessionStorage.getItem('Role') == null || sessionStorage.getItem('token') == null || sessionStorage.getItem('name') == null || sessionStorage.getItem('email') == null) ?
            name == 'Admin' ? this.clearAllSessionWithQuery('Admin', query) : this.clearAllSessionWithQuery('other', query)
            :
            (this.getSessionStorage('Role') != name) ? name == 'Admin' ? this.clearAllSessionWithQuery('Admin', query) : this.clearAllSessionWithQuery('other', query) : '';
    }
    clearAllSessionWithQuery(name: any, query: string) {
        sessionStorage.clear();
        if (name == 'Admin' || name == 'admin') {
            this.router.navigate(['/authentication/login'])
        } else if (name == 'Remote' || name == 'remote') {
            this.router.navigate(['/authentication/remoteLogin'])
        }
    }
    getEncryptedDataSSN(data: any){
        return this._securityCheck.EncryptSSN(data);
    }
    getEncryptedDataPWD(data: any){
        return this._securityCheck.EncryptPassword(data);
    }
    getDecryptedDataSSN(data: any){
        return this._securityCheck.DecryptSSN(data);
    }

    getEncryptedSPValues(data: any){
        return this._securityCheck.EncryptSSN(data);
    }
    getDecryptedSPValues(data: any){
        return this._securityCheck.DecryptSSN(data);
    }
}
