import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SecureAuthService } from './secureauth.service';
/// <reference types="crypto-js" />

import * as CryptoJS from 'crypto-js';

@Injectable()
export class SecurityCheck {

    constructor(public router: Router, protected _secureAuthService: SecureAuthService) {
    }


    Encrypt(data: string) {
        var e = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), CryptoJS.enc.Utf8.parse(this._secureAuthService.getKey()),
            {
                keySize: 128 / 8,
                iv: CryptoJS.enc.Utf8.parse(this._secureAuthService.getIV()),
                mode: CryptoJS.mode.CTR,
                padding: CryptoJS.pad.Pkcs7
            });
        return e.toString();
    }
    Decrypt(data: string) {
        var d = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(this._secureAuthService.getKey()), {
            keySize: 128 / 8,
            iv: CryptoJS.enc.Utf8.parse(this._secureAuthService.getIV()),
            mode: CryptoJS.mode.CTR,
            padding: CryptoJS.pad.Pkcs7
        });
        return d.toString(CryptoJS.enc.Utf8);
    }
    
    EncryptSSN(data: string) {
        var e = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(data), CryptoJS.enc.Utf8.parse(this._secureAuthService.getKey()),
            {
                keySize: 128 / 8,
                iv: CryptoJS.enc.Utf8.parse(this._secureAuthService.getIV()),
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
        return e.toString();
    }
    EncryptPassword(data: string) {
        var e = CryptoJS.AES.encrypt(data, CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(this._secureAuthService.getV())),
            {
                KeySize: 256 / 8,
                padding: CryptoJS.pad.Pkcs7,
                iv:CryptoJS.enc.Utf8.parse(this._secureAuthService.getV()),
                mode: CryptoJS.mode.CBC,
            }
            );
        return e.toString();
    }

    DecryptSSN(data: string) {
        var d = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(this._secureAuthService.getKey()), {
            keySize: 128 / 8,
            iv: CryptoJS.enc.Utf8.parse(this._secureAuthService.getIV()),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return d.toString(CryptoJS.enc.Utf8);
    }

    EncryptSPNames(data: string) {
        var e = CryptoJS.AES.encrypt(data, CryptoJS.SHA256(CryptoJS.enc.Utf8.parse(this._secureAuthService.getV())),
            {
                KeySize: 256 / 8,
                padding: CryptoJS.pad.Pkcs7,
                iv:CryptoJS.enc.Utf8.parse(this._secureAuthService.getV()),
                mode: CryptoJS.mode.CBC,
            }
            );
        return e.toString();
    }
}
