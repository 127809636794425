import { Component, OnInit, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { DecimalPipe, formatDate } from '@angular/common';
import { Observable } from 'rxjs';
//import { Table } from './SaleInvoiceList.model';
import * as _ from 'underscore';
//import { tableData } from './data';
//import { PoListService } from './SaleInvoiceList.service';
import { PoListDirective, SortEvent } from './SaleCommercialInvoiceList.directive';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { Router } from '@angular/router';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommonService } from 'src/app/core/services/CommonService.service';

@Component({
  selector: 'app-SaleCommercialInvoiceList',
  templateUrl: './SaleCommercialInvoiceList.component.html',
  styleUrls: ['./SaleCommercialInvoiceList.component.scss'],

})

/**
 * Advanced table component
 */
export class SaleCommercialInvoiceListComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];
  tableData: any[];
  worklist: any[];
  searchlist: any[];
  work: any[];
  FromDate: string;
  ToDate: Date;
  invoiceID: string;
  comments: string;
  pdfdoc:any;
  GrandTotal:any=[];
  roleName:string;
  // Table data
  //tableData2: Table[];
  searchString: string;
  pager: any = {};
  //tables$: Observable<Table[]>;
  total$: Observable<number>;
  validationform: FormGroup;
  tablesData$: Observable<any>;
  @ViewChildren(PoListDirective) headers: QueryList<PoListDirective>;
  @ViewChild(SaleCommercialInvoiceListComponent) table: SaleCommercialInvoiceListComponent[];

  constructor(private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private _router: Router,
    private _session: SessionCheck,
    private modalService: NgbModal,
    private commonservice:CommonService) {
    // this.tablesData$ = service._tableData$;
  }
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Quotation' }, { label: 'Customer Quotation', active: true }];
    this.validationform = this.formBuilder.group({
      FromDate: [''],
      ToDate: [''],
    });
    var date = new Date();
    date.setDate(date.getDate() - 60);
    this.validationform.patchValue(
      {
        FromDate: formatDate(date, "yyyy-MM-dd", "en-us"),
        ToDate: formatDate(new Date(), "yyyy-MM-dd", "en-us")
      }
    )
    //  this.FromDate = formatDate( new Date(),"yyyy-MM-dd","en-us");
    /**
     * fetch data
     */
     this.roleName=this._session.getSessionStorage('Role');
    //this._fetchData();
    this.getRegularWorklist();


  }
  getRegularWorklist() {
    try {
      this.spinner.show();
      const searchjson = {
        FromDate: this.validationform.get('FromDate').value,
        ToDate: this.validationform.get('ToDate').value

      }
      this.setup.GetRegularworkList('sp_GetSaleCommercialInvoiceList', JSON.stringify(searchjson))
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        this.work = [];
        if (data.ResultSets.length > 0) {
          this.tableData = data.ResultSets[0];
          this.worklist = data.ResultSets[0];
          this.setPage(1);
          this.spinner.hide();
          this.GrandTotal.Qty=0;
          this.GrandTotal.Amount=0;
          this.GrandTotal.ProfitAmt=0;
          this.worklist.forEach(r=> 
            {
              this.GrandTotal.Qty+= r.Qty
              this.GrandTotal.Amount+= r.Amount
              this.GrandTotal.ProfitAmt+= r.ProfitAmt
            })


        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }

  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * fetches the table value
   */
  _fetchData() {
    // this.tableData = tableData;
    // for (let i = 0; i <= this.tableData.length; i++) {
    //   this.hideme.push(true);
    // }
  }

  setPage(page: number) {
    this.pager = this.getPager(this.worklist.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    //  this.pager = this.getPager(this.worklist.length, page);
    this.work = this.worklist.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);
    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }
    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
    // create an array of pages to ng-repeat in the pager control
    const pages = _.range(startPage, endPage + 1);
    // return object with all pager properties required by the view
    return {
      totalItems: totalItems,
      currentPage: currentPage,
      pageSize: pageSize,
      totalPages: totalPages,
      startPage: startPage,
      endPage: endPage,
      startIndex: startIndex,
      endIndex: endIndex,
      pages: pages
    };
  }
  searchExistingRecords(event: any) {
    const val = event.target.value.toLowerCase();
    //const val = event.target.value;
    if (val != '') {
      const list = this.worklist.filter(function (d) {
        let ColumnsData = '';
        const columnsName = ['DocDate','DocNo','AccName','Status','CreatedBy', 'CreatedDate', 'Qty', 'Amount'];
        columnsName.forEach(col => {
          ColumnsData += ' ' + d[col];
        });
        return ColumnsData.toLowerCase().indexOf(val) !== -1 || !val;
      });
      // Whenever the filter changes, always go back to the first page.
      //this.tableData = list;
      this.table = list;
      this.work = list;
      this.setPageSearch(1);
    } else {
      this.table = this.worklist;
      this.setPageSearch(1);
    }
  }
  setPageSearch(page: number) {
    this.pager = this.getPager(this.table.length, page);
    if (page < 1 || page > this.pager.totalPages) {
      return;
    }
    if (isNaN(page)) {
      page = 1;
    }
    this.work = this.table.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }
  editRecord(item) {
    this._router.navigate(['/form/saleCommercialEntry'], { queryParams: { Id: item.ID } });
  }
  public fixBinary(bin) {
    const length = bin.length;
    const buf = new ArrayBuffer(length);
    const arr = new Uint8Array(buf);
    for (let i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }
  PrintSaleCommercialInvoice(item, largeDataModal: any) {
    try {
      this.spinner.show();
      this.setup.getPDFAsResult(item.ID, "PrintSaleCommercialInvoicePDF")
        .subscribe((result) => {
          this.spinner.hide();
          var base64string = result.toString();
          if (base64string.length > 1000) {
            const Data64ArrayBuffer = this.fixBinary(
              atob(base64string)
            );
            const blob = new Blob([Data64ArrayBuffer], {
              type: 'application/pdf'
            });
            this.openModal(largeDataModal);
            const url = window.URL.createObjectURL(blob);
            //document.querySelector("iframe").src = url;
            this.pdfdoc = url;
          }
        })

    }
    catch (error) {
      console.log(error);
    }

  }
  openPO() {
    this._router.navigate(['/form/saleCommercialEntry'], { queryParams: { Id: 0 } });
  }
  openModal(largeDataModal: any) {
    this.modalService.open(largeDataModal, { size: 'xl' });
  }
  openConfirmationModal(item, modal) {
    this.invoiceID = item.ID;
    this.modalService.open(modal, { size: 'xl' });
  }
  updateApproval() {
    try {
      this.spinner.show();
      const jsonMasterData = {
        Comments: this.comments
      }
      this.setup.addRecord(this.invoiceID, jsonMasterData, "", "sp_ApproveSaleCommercialInvoice")
        .subscribe((result) => {
          this.spinner.hide();
          if (result[0].Status == 1) {
            this.modalService.dismissAll();
            this.toaster.success(result[0].ResponseMessage);
            this.getRegularWorklist();
          }
          else {
            this.toaster.error(result[0].ResponseMessage);

          }
        })

    }
    catch (error) {
      console.log(error);
    }

  }
  getClassName(item) {
    if (item.Status == 'Approved') {
      return "{'badge-soft-success'}"
    }
    else {
      "{'badge-soft-warning'}";
    }
  }
  validSubmit() {
    this.getRegularWorklist();
  }
  downloadExcelResult(item: any) {
    try {
      this.commonservice.DownloadExcelFile(item.ID, 'SaleCommercialInv', this.spinner,'getSaleCommercialInvListExcel');
    } catch (error) {
      this.spinner.hide();
      console.log(error);
    }
  }

}
