import { AuthenticationService } from '../../../core/services/auth.service';
import { Component, AfterViewInit, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionCheck } from '../../../core/services/sessioncheck.service';
//import { CommonService } from '../../shared/services/common/commonservice.service';
import { ToastrService } from 'ngx-toastr';
//import { SecurityTabService } from '../../shared/services/securitytab/securitytab.service';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  templateUrl: './changePassword.component.html'
})
export class ChangePasswordComponent implements AfterViewInit, OnInit, OnDestroy {
  search: any = '';
  page = 1;
  pager: any = {};

  changePasswordFormData: FormGroup;

  constructor(private router: Router, 
    //private _security: SecurityTabService, 
    private _auth: AuthenticationService, 
    private toaster: ToastrService, 
    //private _commonService: CommonService,
    private _sessionCall: SessionCheck, private spinner: NgxSpinnerService,) {
  }

  ngOnInit(): void {
      this.propInit();
  }

  ngAfterViewInit() { }

  ngOnDestroy() { }

  propInit() {
    this.changePasswordFormData = new FormGroup({
      oldPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
      password: new FormControl('', [Validators.required, Validators.minLength(6)]),
      confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }

  passwordChange() {
    console.log(this.changePasswordFormData);
    this.spinner.show();

    if(this.changePasswordFormData.valid){
      if(this.changePasswordFormData.value.password === this.changePasswordFormData.value.confirmPassword){
        let type = 'change';
        this._auth.changePassword(this.changePasswordFormData.value.password, type, this.changePasswordFormData.value.oldPassword)
        .subscribe((result)=> {
           
          if(result[0].flag === 1) {
            this.changePasswordFormData.reset();
            this.toaster.success('You have successfully changed the password..!!!', 'Password Changed!');
          } else {
            this.changePasswordFormData.reset();
            this.toaster.error(result[0].Status, 'Error!');
          }
          this.spinner.hide();
        })

      } else {
        this.toaster.error('New and Confirm password should be same')
      }
    }
  }

}
