import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';

import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/core/services/CommonService.service';
import { Router } from '@angular/router';
//import { GenericAuthService } from '../common/genericAuth.service';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { AuthfakeauthenticationService } from './authfake.service';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    user: User;
	_host = '';
	userID = 0;
	token = '';
	System_IP: any = '';
	Validate: any = '';

    constructor(
        private _Http: HttpClient,
		private _commonService: CommonService,
        private _sessionCall: SessionCheck,
        private toastr: ToastrService,
		private spinner: NgxSpinnerService,
		private router: Router,
       private authFackservice : AuthfakeauthenticationService
    ) {
        this.getIpCliente().subscribe((data) => {
			console.log(data);
			this.System_IP = data;
		});

    }
    getIpCliente(): Observable<any> {

		try {
			return this._Http.get('https://api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK', { responseType: 'text' }) // ...using post request '
				.pipe(map((res: any) => {
					let ipVar = res;
					let num = ipVar.indexOf(":");
					let num2 = ipVar.indexOf("\"});");
					ipVar = ipVar.slice(num + 2, num2);
					return ipVar
				})); // ...and calling .json() on the response to return data
		} catch (error) {
			console.log(error);

		}

	}

    /**
     * Returns the current user
     */
    public currentUser(): User {
        return getFirebaseBackend().getAuthenticatedUser();
    }

    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {
        return getFirebaseBackend().loginUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }
	checkUserLock(user, pwd): Observable<any> {
		try {
			const param = {
				UserID: 0,
				UserName: user,
				Password: pwd,
				// Role: this._sessionCall.getLoginLoggedType()
			};
			return new Observable(observer => {
				this._commonService.postMethodWithToken('dbo.SP_AuthenticateUser', param)
					.subscribe((status) => {
						observer.next(status);
						observer.complete();
					})
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr)
		}
	}
	loginUser(username: any, password: any, type: any): void {
		localStorage.clear();
		localStorage.removeItem('currentUser');
debugger
		var params = {
			UserName: username,
			Password: password,
			Role: '',
			UserID: 0
		};
		var sp = this._sessionCall.getEncryptedDataSSN('dbo.SP_AuthenticateUser');
		sp = sp.replace(/[/]/g, 'sLash');
		sp = sp.replace(/[=]/g, 'equalTo');
		sp = sp.replace(/[+]/g, 'pLus');
		const _spUrl = sp + '/other/auth';
		var param = JSON.stringify(params)
		param = this._sessionCall.getEncryptedSPValues(param);
		param = param.replace(/[/]/g, 'sLash');
		param = param.replace(/[=]/g, 'equalTo');
		param = param.replace(/[+]/g, 'pLus');
		var secureParam = {
			Param: param
		}
		try {
			this._commonService.postMethodWithoutToken(_spUrl, secureParam)
				.subscribe((response) => {
					const header = response.headers;
					const body = response.body;
					if (body === 'UnAuthorized') {
						this.loginLogDetailofUser(username, 0, 'Failed')
							.subscribe((result) => {
							})
						this.toastr.error('Incorrect username password.', 'Login Error');
						this.spinner.hide();
					} else {
						if (type == 0 && body.Role.toLowerCase() === 'remote') {
							this.toastr.error('Incorrect username password.', 'Login Error');
							this.spinner.hide();
						} else if (type == 1 && body.Role.toLowerCase() !== 'remote') {
							this.toastr.error('Incorrect username password.', 'Login Error');
							this.spinner.hide();
						}
						else {
							if (body.Access === 'Authorized') {
								this.loginLogDetailofUser(username, 0, 'Success')
									.subscribe((result) => {
									})
								const tokenID = header.get('Token');
								if (tokenID !== null) {
									const toArray = tokenID.split(':');
									this.userID = toArray[1];
									this.token = toArray[0];
									const tokenExpiry = response.headers.get('TokenExpiry');

									if (this.token != null && this.userID != null && tokenExpiry != null) {
										this._sessionCall.setSessionStorage('userId', this.userID);
										this._sessionCall.setSessionStorage('token', this.token);
										this._sessionCall.setSessionStorage('menuItems', body.Menu);
										this._sessionCall.setSessionStorage('UserFullName', body.Name);
										this._sessionCall.setSessionStorage('UserName', body.UserName);
										this._sessionCall.setSessionStorage('Role', body.Role);
										this._sessionCall.setSessionStorage('email', body.EmailID);
										this._sessionCall.setSessionStorage('IsSignOffZymo', body.IsSignOffZymo);
                                        this._commonService.sessionStart();
                                        this.spinner.hide();

                                       // localStorage.setItem('currentUser', JSON.stringify(this.userID));
                                        this.router.navigate(['/']);



									//this.router.navigate(['/pages/dashBoard']);


								// 		this.ipValidate(this.System_IP)
								// 			.subscribe((result) => {
								// 				setTimeout(() => {
								// 					this.Validate = result;
								// 					this.getLicenseDetail();
								// 					this.GetAllSubscriptionHours();
								// 					this._commonService.sessionStart();
								// 					if (type === 0 && this._sessionCall.getSessionStorage('Role').toUpperCase() == 'MASTERADMIN') {
								// 						// this.spinner.hide();
								// 						this.router.navigate(['/master/dashBoard']);
								// 						this.toastr.success('Welcome to the Dashboard', 'Login Successful');
								// 					}
								// 					else if (type === 0 && this._sessionCall.getSessionStorage('Role').toUpperCase() == 'SORRENTOMASTERADMIN') {
								// 						// this.spinner.hide();
								// 						this.router.navigate(['/sorrentoMaster/dashBoard']);
								// 						this.toastr.success('Welcome to the Dashboard', 'Login Successful');
								// 					}

								// 					else if (type === 0 && this._sessionCall.getSessionStorage('Role').toUpperCase() == 'BIOINFORMATICIAN') {
								// 						// this.spinner.hide();
								// 						this.router.navigate(['/upload/zymoClinicalCSV']);
								// 						this.toastr.success('Welcome to the Dashboard', 'Login Successful');
								// 					}
								// 					else if (type === 0) {
								// 						// this.spinner.hide();
								// 						this.router.navigate(['/main/worklist']);
								// 						this.toastr.success('Welcome to the Dashboard', 'Login Successful');
								// 					}
								// 					else {
								// 						this.toastr.error('Access denied.', 'Login Error');
								// 						this.spinner.hide();
								// 					}
								// 				}, 100);
								// 			})
								 	}
								 } else {
									this.spinner.hide();
									this.toastr.error('Incorrect username password.', 'Login Error');
								}
							} else {
								this.spinner.hide();
								this.toastr.error('Incorrect username password.', 'Login Error');
							}
						}
					}
				});
		} catch (error) {
			this.spinner.hide();
			this._commonService.handleError(error, this._sessionCall, this.toastr)
		}
	}
	changePassword(Password: any,type:any, OldPassword: any): Observable<any> {

		try {
			const param = {
				"UserID": 0,
				"Password": Password,
				"Type": type,
				"OldPassword":OldPassword
			};

			return new Observable(observer => {
				this._commonService.postMethodWithTokenApiCall('dbo.SP_ChangePassword/other/auth/check/forget/change', param)
					.subscribe((list) => {

						observer.next(list.body.ResultSets[0]);
						observer.complete();
					})
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr)
		}

	}
	ipValidate(IP: any) {

		const param = {
			"IP": IP,
		};
		return new Observable(observer => {
			this._commonService.postMethodWithToken('dbo.getIPValidate', param)
				.subscribe((response) => {
					observer.next(response[0].count);
					observer.complete();
				})
		});
	}

    loginLogDetailofUser(user: any, userid: any, status: any) {
		try {

			var sBrowser, sUsrAg = navigator.userAgent;

			//The order matters here, and this may report false positives for unlisted browsers.

			if (sUsrAg.indexOf("Firefox") > -1) {
				sBrowser = "Mozilla Firefox";
				//"Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
			} else if (sUsrAg.indexOf("Opera") > -1) {
				sBrowser = "Opera";
			} else if (sUsrAg.indexOf("Trident") > -1) {
				sBrowser = "Microsoft Internet Explorer";
				//"Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
			} else if (sUsrAg.indexOf("Edge") > -1) {
				sBrowser = "Microsoft Edge";
				//"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
			} else if (sUsrAg.indexOf("Chrome") > -1) {
				sBrowser = "Google Chrome or Chromium";
				//"Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
			} else if (sUsrAg.indexOf("Safari") > -1) {
				sBrowser = "Apple Safari";
				//"Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
			} else {
				sBrowser = "unknown";
			}

			const param = {
				"UserID": userid ? userid : 0,
				"UserName": user,
				"Browser": sBrowser,
				"System_IP": this.System_IP,
				"Status": status,
				"IsActive": true,
				"CreatedDate": "",
				"CreatedUserID": userid ? userid : 0,
				"ModifiedDate": "",
				"ModifiedUserID": userid ? userid : 0
			};
			return new Observable(observer => {
				this._commonService.postMethodWithTokenParam('dbo.SP_Create_LoginLogDetail/other/auth/check/function/change/common', param)
					.subscribe((list) => {
						console.log(list);
						observer.next(list);
						observer.complete();
					})
			});
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr)
		}

	}
	getMenuList() {
		try {
			const param = {
				UserID: 0,
			};
			this._commonService.postMethodWithToken('dbo.sp_GetMenuItems', param)
				.subscribe((hours) => {
					if (hours.length > 0) {
						this._sessionCall.setSessionStorage('menuItems', hours[0].MENU);
					}
				})
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr)
		}
	  }
    GetAllSubscriptionHours() {
		try {
			const param = {
				UserID: 0,
			};
			this._commonService.postMethodWithToken('dbo.sp_GetAllSubscriptionHours', param)
				.subscribe((hours) => {
					if (hours.length > 0) {
						let daysRemain = hours[0].TimeRemaining;
						this._sessionCall.setSessionStorage('timeRemaining', daysRemain);
					}
				})
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr)
		}
	}

	getLicenseDetail() {
		try {
			const param = {
				UserID: 0,
			};
			this._commonService.postMethodWithToken('dbo.SP_GetLicenseDetail', param)
				.subscribe((license) => {
					if (license.length > 0) {
						this._sessionCall.setSessionStorage('license', license[0].SerialNo);
						this._sessionCall.setSessionStorage('serial', license[0].VersionNo);
					}
				})
		} catch (error) {
			this._commonService.handleError(error, this._sessionCall, this.toastr)
		}
	}
    /**
     * Performs the register
     * @param email email
     * @param password password
     */
    register(email: string, password: string) {
        return getFirebaseBackend().registerUser(email, password).then((response: any) => {
            const user = response;
            return user;
        });
    }

    /**
     * Reset password
     * @param email email
     */
    resetPassword(email: string) {
        return getFirebaseBackend().forgetPassword(email).then((response: any) => {
            const message = response.data;
            return message;
        });
    }

    /**
     * Logout the user
     */
    logout() {
        // logout the user
       // getFirebaseBackend().logout();
	   localStorage.removeItem('currentUser');
	   sessionStorage.clear();
	   localStorage.clear();
	 //  this.router.navigate(['/authentication/login']);

    }
}

