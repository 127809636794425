import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SessionCheck } from 'src/app/core/services/sessioncheck.service';
import { SetupService } from 'src/app/core/services/setup/setup.service';

@Component({
  selector: 'app-pagetitle',
  templateUrl: './CommonPrint.component.html',
  styleUrls: ['./CommonPrint.component.scss']
})
export class CommonPrintComponent implements OnInit {

  @Input() breadcrumbItems;
  @Input() title: string;
  backUrl: string;
  constructor(private setup: SetupService,
    private _session: SessionCheck,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
     
    if (this._session.getSessionStorage('doprint')) {
      var printvar;
      printvar = JSON.parse(this._session.getSessionStorage('doprint'));
      if (printvar.Name == 'po') {
        this.backUrl = printvar.returnurl;
        this.PrintPO(printvar.id);
      }
    }
  }
  PrintPO(item) {
    try {
      return;
      this.spinner.show();
      this.setup.GetPOPDF(item)
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        fetch(data)
          .then(response => {
            const byteArray = new Uint8Array(atob(data).split('').map(char => char.charCodeAt(0)));
            return new Blob([byteArray], { type: 'application/pdf' });
          })
          .then(blob => {
            // Here is your URL you can use
            const url = window.URL.createObjectURL(blob);

            // i.e. display the PDF content via iframe
            //document.querySelector("iframe").src = url;
          });
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  goBack() {
  //  this.router.navigate([this.backUrl]);
  }
}
