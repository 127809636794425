<div class="container-fluid">
  <div class="row">
    <div class="col-10">
      <div class="page-title-box d-flex align-items-center justify-content-between">
        <h4 class="mb-0">Bank Voucher</h4>
      </div>
    </div>
    <div class="col-2">
    </div>
  </div>
<br>
  
   <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform"> 

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-4">
                <div class="form-group">
                  <label for="BAc_Code">Bank Name</label>
                  <ng-select formControlName="BAc_Code" name="BAc_Code" id="BAc_Code"
                (change)="getVoucherDetailList()"    [items]="BankList"  
                bindLabel="Name" bindValue="Code" 
                    [(ngModel)]="selectedAccID"></ng-select>
                  <div class="invalidValidate" *ngIf="checkSupplier()">
                    Please provide Bank Name
                  </div>
                </div>

              </div>
              

              <div class="col-md-2">
                <div class="form-group">
                  <label for="V_NO">Voucher No</label>
                  <input class="form-control" formControlName="V_NO" id="V_NO"
                 disabled readonly   [ngClass]="{'is-invalid': submit && form.V_NO.errors}">
                  <div *ngIf="submit && form.V_NO.errors" class="invalid-feedback">
                    Please provide Voucher No
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group">
                  <label for="TRANS_TYPE">Voucher Type</label>
                  <ng-select formControlName="TRANS_TYPE" name="TRANS_TYPE" id="TRANS_TYPE"
                  [items]="VoucherTypeList"  bindLabel="Name" bindValue="Code" ></ng-select>
                </div>
              </div>
              <div class="col-md-2">
                <div class="form-group">
                  <label for="DATED">Payment Date</label>
                  <input type="date" class="form-control" formControlName="DATED"
                   id="DATED"
                    [ngClass]="{'is-invalid': submit && form.DATED.errors}">
                  <div *ngIf="submit && form.DATED.errors" class="invalid-feedback">
                    Please provide Date
                  </div>  
                </div>
              </div>
            </div>
                  <!-- <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="MasterRemarks">Remarks</label>
                  <input type="text" class="form-control" formControlName="MasterRemarks" id="MasterRemarks">
                </div>
              </div>
            </div> -->
              
            
          </div>
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
   </form>
    <div class="row" >
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" >
            <h4 class="card-title">Payment Detail</h4>

            <div class="table-responsive" style="min-height:250px ;">
              <table class="table table-hover mb-0">

                <thead>
                  <tr class="TableHeaderRow">
                    <th>#</th>
                    <th style="min-width:250px!important">Account Name</th>
                    <th style="min-width:250px!important">Narration</th>
                    <th>Amount</th>
                    <th>Clear Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of VoucherDetailList;let i=index">
                    <td>{{i+1}}</td>
                    <td>
                      <ng-select name="AccountList" id="AccountList + {{item.id}}"
                      [items]="AccountList"  bindLabel="Name" bindValue="Code" 
                      (change)="checkLastRow(i)" [(ngModel)]="item.a_code"></ng-select>
                      </td>
                      <td>
                        <input class="form-control" id="NARRATION + {{item.id}}" value=""
                        [(ngModel)]="item.NARRATION"></td>
                    <td>
                      <input type="number" class="form-control" id="AMOUNT + {{item.id}}" value="0"
                      [(ngModel)]="item.AMOUNT"></td>
                      <td>
                        <input type="date" class="form-control" id="Clear_Date + {{item.id}}" 
                        [(ngModel)]="item.Clear_Date"></td>
                        <td>
                      <button type="button" class="mdi mdi-18px mdi-delete text-danger"
                        (click)="DeletePrdItem(item,i)"></button>
                    </td>
                  </tr>
                  <tr class="TotalRow">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>


    </div>

 
  
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">

              <div class="col-md-8">
                <div class="form-group">
                  <!-- <label for="PaymentTerms">Remarks</label>
                  <textarea rows="4" class="form-control" formControlName="PaymentTerms" id="PaymentTerms"></textarea> -->
                </div>
              </div>
              
                <div class="col-lg-2">
                </div>
                  <div class="col-lg-2">
                  <br>
                  <div class="card">
                    <button class="btn btn-primary " (click)="validSubmit()">Save</button>
                </div>
              </div>
        

            </div>
            </div>
      
            
            
        </div>
        <!-- end card -->
      </div> <!-- end col -->


    </div>
    <!-- end row -->

  <!-- </form> -->


</div>