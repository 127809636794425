<div class="container-fluid">
  <app-pagetitle title="Product Brand Setup" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Purchase Order List</h4> -->

          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <button type="button" class="btn btn-primary tblebtn w-md" (click)="resetAndopenModal(addmstModal)"><i
                  class="mdi mdi-plus"></i>Add New</button>

              <!-- <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Show
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> entries</label></div> -->
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div class="row navbar">
                <div class="col-6 d-flex justify-content-left">
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <div class="row">
                    <div>
                      <input class="form-control" [(ngModel)]="searchString" placeholder="Search" id="searchString"
                        name="searchString" (keyup)="searchExistingRecords($event)" />
                    </div>
                    <!-- <div>
                            <button class="btn btn-primary" (click)="searchDBRecords()"><i
                                    class="ti-search m-r-5 m-l-5"></i></button>
                        </div> -->
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive">
              <table #table class="table table-hover m-t-20 table-bordered">
                <thead class="bg-info text-white">
                  <tr class="">
                    <th>Code</th>
                    <th>Name</th>
                    <th>Created By</th>
                    <th>Active</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody class="border border-info">
                  <tr *ngFor="let item of work">

                    <td>{{item.BrandCode}}</td>
                    <td>{{item.PrdBrand}}</td>
                    <td>{{item.UserName}}</td>
                    <td>{{item.IsActiveF}}</td>
                    <td>
                      <!-- <button type="button" class="btn btn-outline-info tblebtn"
                                (click)="downloadOriginalExcel(item,1)">
                                <i class="ti-download "></i> Download Excel File</button> 
                                &nbsp; <br/>-->
                      <a href="javascript:void(0);" (click)="editRecord(item.pbid,addmstModal)" class="text-primary"
                        ngbTooltip="Edit"><i class="mdi mdi-file-document-edit-outline font-size-22 "></i></a>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ngb-pagination [collectionSize]="pager.totalItems " [pageSize]="pager.pageSize "
              [(page)]="pager.currentPage " [maxSize]="10 " [rotate]="true " (pageChange)="setPage(pager.currentPage) "
              [boundaryLinks]="true ">
            </ngb-pagination>

          </div>
        </div>
      </div>
    </div>
  </div>



    <!-- Modal -->
    <ng-template #addmstModal let-modal>
      <div class="modal-header">
        <h5 class="modal-title mt-0">Brand Setup</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">
          <div class="row">
            <div class="col-lg-12">
              <div class="card o">
                <div class="card-body o">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label for="PrdBrand">Brand Name</label>
                        <input class="form-control" formControlName="PrdBrand" id="PrdBrand"
                          [ngClass]="{'is-invalid': submit && form.PrdBrand.errors}">
                        <div *ngIf="submit && form.PrdBrand.errors" class="invalid-feedback">
                          Please provide Brand Name
                        </div>
                      </div>
                    </div>
                   
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="BrandCode">Brand Code</label>
                        <input class="form-control" readonly disabled formControlName="BrandCode" id="BrandCode"
                          [ngClass]="{'is-invalid': submit && form.BrandCode.errors}">
                        <div *ngIf="submit && form.BrandCode.errors" class="invalid-feedback">
                          Please provide Brand Code
                        </div>
                      </div>
                    </div>
                  </div>




         
                <div class="row">
                  <div class="col-md-7">
                  </div>
                  <div class="col-md-5">
                    <button type="submit" class="btn btn-primary tblebtn w-md">
                      Save
                    </button>&nbsp;
                    <button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-secondary tblebtn w-md">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </form>
      </div>
    </ng-template>
  </div>