import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './POForm.mustmatch';
//import { icons } from '../data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-validation',
  templateUrl: './POForm.component.html',
  styleUrls: ['./POForm.component.scss']
})

/**
 * Forms Validation component
 */
export class POFormComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form
  tooltipvalidationform: FormGroup; // bootstrap tooltip validation form
  typeValidationForm: FormGroup; // type validation form
  rangeValidationForm: FormGroup; // range validation form
  SupplierList: any = [];
  selectedSupplierID: string;
  paymentTermsngModel:string;
  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router:Router
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;
  SearchItems: any = [];
  searchSize: string;
  selectedItemArray: any = [];
  ProductArray: any = [];
  SearchPanelVisible: boolean;
  TotalQty: any;
  TotalAmount: any;
  TotalVolume: any;
  POID: any;
  editMode:boolean;
  
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Purchase Order', active: true }];
    this.POID = 0;
    this.POID = this.route.snapshot.queryParamMap.get('POId') ? this.route.snapshot.queryParamMap.get('POId') : '';
    this.editMode=false;
    /**
     * Bootstrap validation form data
     */
    this.validationform = this.formBuilder.group({
      PONo: ['', [Validators.required]],
      PODate: ['', [Validators.required]],
      SupplierName: ['', [Validators.required]],
      PaymentTerms: [''],
      ETCDate: ['', [Validators.required]],

    });
this.getAccountList();
    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;
    if(+this.POID>0)
    {
      this.editRecord(this.POID);
    }
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }

  /**
   * Bootsrap validation form submit method
   */
  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      if (this.ProductArray.length == 0) {
        this.toaster.error('Please check Product List');
        return;
      }
      try {
        this.SearchPanelVisible = false;
        this.SearchItems = [];
        this.spinner.show();
        const JsonMaster =
        {
          SupplierCode: this.selectedSupplierID,
          SupplierName: this.validationform.controls.SupplierName.value,
          PONo: this.validationform.controls.PONo.value,
          PODate: this.validationform.controls.PODate.value,
          ETCDate: this.validationform.controls.ETCDate.value,
          PaymentTerms: this.paymentTermsngModel,
        };

        this.setup.addPOData(this.POID, JSON.stringify(JsonMaster), JSON.stringify(this.ProductArray))
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if(data[0].Status==1){
          this.toaster.success(data[0].ResponseMessage);
          this.router.navigate(['/form/polist']);
        }
        else
        {
          this.toaster.error(data[0].ResponseMessage);
        }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }


    }
  }

  /**
   * returns tooltip validation form
   */
  get formData() {
    return this.tooltipvalidationform.controls;
  }

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;

  }

  /**
   * Returns the type validation form
   */
  get type() {
    return this.typeValidationForm.controls;
  }

  /**
   * Type validation form submit data
   */
  typeSubmit() {
    this.typesubmit = true;
  }

  /**
   * Returns the range validation form
   */
  get range() {
    return this.rangeValidationForm.controls;
  }

  checkSupplier(): boolean {
    if (this.submit && !this.selectedSupplierID) {
      return true;
    }
    else {
      return false;
    }
  }
  /**
   * range validation submit data
   */
  rangeSubmit() {
    this.rangesubmit = true;
  }
  addProducts() {

    var arr_prd = this.SearchItems.filter(row => row.PurQty > 0);
    arr_prd.forEach(element => {
      this.ProductArray.push(
        {
          'PrdId': element.PrdId,
          'PrdName': element.Description,
          'Rate': element.PurPrice,
          'Qty': element.PurQty,
          'Volume': (element.PurQty / element.PrdContainerCapacity).toFixed(2),
          'Amount': (element.PurPrice * element.PurQty).toFixed(2),
          'POdId' : 0  
        }
      )
    });
    this.SearchItems = [];
    this.searchSize = '';
    this.SearchPanelVisible = false;
    this.calculateTotal();
  }
  calculateTotal() {
    this.TotalQty = 0;
    this.TotalAmount = 0;
    this.TotalVolume = 0;
    this.ProductArray.forEach(element => {
      this.TotalQty += +element.Qty;
      this.TotalAmount += +element.Amount;
      this.TotalVolume += +element.Volume;
    });
    
    this.TotalAmount = this.TotalAmount.toFixed(2);
    this.TotalVolume = this.TotalVolume.toFixed(2);
  }
  searchPrdFromDB(item) {
    try {
      this.SearchPanelVisible = false;
      this.SearchItems = [];
      this.spinner.show();
      this.setup.getSearchProducts(this.searchSize)
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.SearchItems = data;
          this.SearchPanelVisible = true;
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  DeletePrdItem(item, iRowIndex) {
    this.ProductArray.splice(iRowIndex,1);
    this.calculateTotal();
  }
  editRecord(POID) {
    try {
      this.setup.GetPORecord(this.POID)
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.fillEditRecordData(data);
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  getAccountList() {
    try {
      this.setup.GetAccountList('Supplier')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.SupplierList=data.ResultSets[0];
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  fillEditRecordData(data) {
    this.validationform.patchValue(
      {
        PONo: data[0].PONo,
        PODate: data[0].PODate,
        SupplierName:data[0].Amid,
        PaymentTerms : data[0].PaymentTerms,
        ETCDate : data[0].ETCDate
      }
    )
    this.paymentTermsngModel=data[0].PaymentTerms;
    this.editMode=true;
    this.selectedSupplierID=data[0].Amid;
    this.ProductArray=[];
    data.forEach(element => {
      this.ProductArray.push(
        {
          'PrdId': element.PrdID,
          'PrdName': element.Description,
          'Rate': element.Rate,
          'Qty': element.Qty,
          'Amount': element.Amount,
          'POdId' : element.POdId ,
          'Volume': (element.Qty / element.PrdContainerCapacity).toFixed(2),
          'PrdContainerCapacity':element.PrdContainerCapacity
        }
      )
    });
    this.calculateTotal();
  }
  changePOQty(item)
  {
item.Volume=(item.Qty / item.PrdContainerCapacity).toFixed(2);
item.Amount=(item.Qty * item.Rate).toFixed(2);
this.calculateTotal();
  }
}
