import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Component } from '@fullcalendar/core';
import { BankVoucherComponent } from './BankVoucher/BankVoucher.component';
import { BankVoucherListComponent } from './BankVoucherList/BankVoucherList.component';
import { CashVoucherListComponent } from './CashVoucherList/CashVoucherList.component';
import { CashVoucherComponent } from './CashVoucher/CashVoucher.component';
import { JournalVoucherListComponent } from './JournalVoucherList/JournalVoucherList.component';
import { JournalVoucherComponent } from './JournalVoucher/JournalVoucher.component';
import { SaleVoucherListComponent } from './SaleVoucherList/SaleVoucherList.component';
import { SaleInvoiceComponent } from './SaleInvoice/SaleInvoice.component';
import { EstimateVoucherListComponent } from './EstimateVoucherList/EstimateVoucherList.component';
import { EstimateVoucherComponent } from './EstimateVoucher/EstimateVoucher.component';
import { PurchaseVoucherListComponent } from './PurchaseVoucherList/PurchaseVoucherList.component';
import { PurchaseInvoiceComponent } from './PurchaseInvoice/PurchaseInvoice.component';

const routes: Routes = [
    {
        path: 'bankVoucher',
        component: BankVoucherListComponent
    },
    {
        path: 'bankVoucherEntry',
        component: BankVoucherComponent
    },
    {
        path: 'cashVoucher',
        component: CashVoucherListComponent
    },
    {
        path: 'cashVoucherEntry',
        component: CashVoucherComponent
    },
    {
        path: 'journalVoucher',
        component: JournalVoucherListComponent
    },
    {
        path:'journalVoucherEntry',
        component: JournalVoucherComponent
    },
    {
        path:'saleList',
        component: SaleVoucherListComponent
    },
    {
        path:'saleInvoice',
        component: SaleInvoiceComponent
    },
    {
        path:'estimateList',
        component: EstimateVoucherListComponent
    },
    {
        path:'estimateVoucher',
        component: EstimateVoucherComponent
    },
    {
        path:'purchaseList',
        component: PurchaseVoucherListComponent
    },
    {
        path:'purchaseInvoice',
        component: PurchaseInvoiceComponent
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class EntryFormRoutingModule { }
