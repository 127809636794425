import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbDatepickerModule, NgbDropdownModule, NgbPaginationModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxMaskModule } from 'ngx-mask';
import { NgSelectModule } from '@ng-select/ng-select';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ColorPickerModule } from 'ngx-color-picker';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

import { UiModule } from '../../shared/ui/ui.module';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { MasterFormRoutingModule } from './Masterform-routing.module';
import { AccountGroupMstListComponent } from './AccountGroupMstList/AccountGroupMstList.component';
import { accountMstComponent } from './accountMst/accountMst.component';
import { accountMstListComponent } from './accountMstList/accountMstList.component';
import { productMstListComponent } from './productMstList/productMstList.component';
import { productMstComponent } from './productMst/productMst.component';
import { TaxHeadRelationMstListComponent } from './TaxHeadRelationMstList/TaxHeadRelationMstList.component';
import { TaxHeadCreationComponent } from './TaxHeadCreation/TaxHeadCreation.component';
import { TaxHeadCreationListComponent } from './taxHeadCreationList/TaxHeadCreationList.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: '',
  maxFilesize: 50
};
@NgModule({
  // tslint:disable-next-line: max-line-length
    declarations: [AccountGroupMstListComponent,accountMstComponent,accountMstListComponent,
    productMstListComponent,productMstComponent,TaxHeadRelationMstListComponent,
    TaxHeadCreationListComponent,TaxHeadCreationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UiModule,
    CKEditorModule,
    ArchwizardModule,
    NgxMaskModule.forRoot(),
    NgSelectModule,
    UiSwitchModule,
    ColorPickerModule,
    NgbDatepickerModule,
    DropzoneModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgxExtendedPdfViewerModule,
    MasterFormRoutingModule
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    }
  ]
})
export class MasterFormModule { }
