import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SetupService } from 'src/app/core/services/setup/setup.service';
import { MustMatch } from './PurchasePacking.mustmatch';
//import { icons } from '../data';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-validation',
  templateUrl: './PurchasePacking.component.html',
  styleUrls: ['./PurchasePacking.component.scss']
})

/**
 * Forms Validation component
 */
export class PurchasePackingComponent implements OnInit {

  validationform: FormGroup; // bootstrap validation form
  SupplierList: any = [];
  selectedSupplierID: string;
  constructor(public formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router: Router
    //private _decimalPipe: DecimalPipe
  ) { }
  // bread crumb items
  breadCrumbItems: Array<{}>;

  // Form submition
  submit: boolean;
  formsubmit: boolean;
  typesubmit: boolean;
  rangesubmit: boolean;
  SearchItems: any = [];
  searchSize: string;
  selectedItemArray: any = [];
  ProductArray: any = [];
  SearchPanelVisible: boolean;
  TotalQty: any;
  TotalAmount: any;
  EntryID: any;
  editMode: boolean;
  grandTotal: any = [];
  productTotal: any = [];
  containerList: any = [];
  containerListGrandTotal: any = [];
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Forms' }, { label: 'Packing List', active: true }];
    this.EntryID = 0;
    this.EntryID = this.route.snapshot.queryParamMap.get('Id') ? this.route.snapshot.queryParamMap.get('Id') : '';
    this.editMode = false;
    /**
     * Bootstrap validation form data
     */
    this.validationform = this.formBuilder.group({
      PackingNo: ['', [Validators.required]],
      PackingDate: ['', [Validators.required]],
      BLNo: [''],
      ArrivalDate: [''],

    });
    //this.getAccountList();

    this.submit = false;
    this.formsubmit = false;
    this.typesubmit = false;
    this.rangesubmit = false;
    if (+this.EntryID > 0) {
      this.editRecord(this.EntryID);
    }
  }

  /**
   * Returns form
   */
  get form() {
    return this.validationform.controls;
  }

  /**
   * Bootsrap validation form submit method
   */
  validSubmit() {
    this.submit = true;
    if (this.validationform.valid) {
      if (this.ProductArray.length == 0) {
        this.toaster.error('Please check Product List');
        return;
      }
      try {
        this.SearchPanelVisible = false;
        this.SearchItems = [];
        this.spinner.show();
        var JsonMaster = (this.validationform.value)
        JsonMaster.ContainerList = this.containerList;
        this.setup.addRecord(this.EntryID, JSON.stringify(JsonMaster), JSON.stringify(this.ProductArray), 'sp_addPOPackingData')
          .subscribe({
            next(list) {
              fetchData(list);
            }
          });
        const fetchData = (data: any) => {
          this.spinner.hide();
          if (data[0].Status == 1) {
            this.toaster.success(data[0].ResponseMessage);
            this.router.navigate(['/form/packinglist']);
          }
          else {
            this.toaster.error(data[0].ResponseMessage);
          }
        };
      } catch (error) {
        this.spinner.hide();
        console.log(error);
        this.toaster.error(error);
      }


    }
  }

  /**
   * returns tooltip validation form
   */

  /**
   * Bootstrap tooltip form validation submit method
   */
  formSubmit() {
    this.formsubmit = true;

  }


  checkSupplier(): boolean {
    if (this.submit && !this.selectedSupplierID) {
      return true;
    }
    else {
      return false;
    }
  }
  addProducts() {
    this.containerList = [];

    var arr_prd = this.SearchItems.filter(row => row.Qty > 0);
    //arr_prd.forEach(element => {
    for (let i = 0; i <= arr_prd.length - 1; i++) {
      const element = arr_prd[i];
      element.Volume = (element.Qty / element.PrdContainerCapacity).toFixed(2);
      this.ProductArray.push(element);
    }
    this.SearchItems = [];
    this.searchSize = '';
    this.SearchPanelVisible = false;
    this.addContainerData();

    this.calculateTotal();
  }
  addContainerData() {
    this.containerList=[];
    //this.ProductArray.forEach(element => {
    for (let i = 0; i <= this.ProductArray.length - 1; i++) {
      const element = this.ProductArray[i];
      if (this.containerList.filter(r => r.ContainerNo == element.ContainerNo).length == 0) {
        this.containerList.push(
          {
            ContainerNo: element.ContainerNo,
            Weight: 0,
            Volume: 0,
            id: this.containerList.length
          }
        )
      }
    }
    this.calculateTotalContainerTotal();
  }
  calculateTotalContainerTotal() {
    this.containerListGrandTotal = [];
    this.containerListGrandTotal.Weight = 0;
    this.containerListGrandTotal.Volume = 0;
    this.containerList.forEach(element => {
      this.containerListGrandTotal.Weight += +element.Weight;
      this.containerListGrandTotal.Volume += +element.Volume;
    });
    this.containerListGrandTotal.Weight=this.containerListGrandTotal.Weight.toFixed(3);
    this.containerListGrandTotal.Volume=this.containerListGrandTotal.Volume.toFixed(2);
  }
  calculateTotal() {
    this.grandTotal = [];
    this.grandTotal.POQty = 0;
    this.grandTotal.ReceivedQty = 0;
    this.grandTotal.ShippedQty = 0;
    this.grandTotal.PenQty = 0;
    this.grandTotal.LoadQty = 0;
    this.grandTotal.Qty = 0;
    this.grandTotal.Volume = 0;
    //this.ProductArray.forEach(element => {
    for (let i = 0; i <= this.ProductArray.length - 1; i++) {
      const element = this.ProductArray[i];
      this.grandTotal.POQty += +element.POQty;
      this.grandTotal.ReceivedQty += +element.ReceivedQty;
      this.grandTotal.ShippedQty += +element.ShippedQty;
      this.grandTotal.PenQty += +element.PenQty;
      this.grandTotal.Qty += +element.Qty;
      this.grandTotal.Volume += +element.Volume;
      this.grandTotal.LoadQty += +element.LoadQty;
    }
    this.grandTotal.Volume = this.grandTotal.Volume.toFixed(2);

  }
  searchPrdFromDB(item) {
    try {
      this.SearchPanelVisible = false;
      this.SearchItems = [];
      this.spinner.show();
      this.setup.getSearchList(this.searchSize, 'sp_GetPurPackingPrdSearch')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.length > 0) {
          this.SearchItems = data;
          this.SearchPanelVisible = true;
          this.calculateProductTotal();
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }
  }
  calculateProductTotal() {
    this.productTotal.PackQty = 0;
    this.productTotal.Qty = 0;
    this.productTotal.PenQty = 0;
    this.productTotal.LoadQty = 0;
    this.productTotal.Volume = 0;
    this.SearchItems.forEach(element => {
      this.productTotal.PackQty += +element.PackQty;
      this.productTotal.Qty += +element.Qty;
      this.productTotal.PenQty += +element.PenQty;
      this.productTotal.LoadQty += +element.LoadQty;
      this.productTotal.Volume += +element.Volume;
    });
    this.productTotal.Volume = this.productTotal.Volume.toFixed(2);
  }
  DeletePrdItem(item, iRowIndex) {
    this.ProductArray.splice(iRowIndex, 1);
    this.addContainerData();
    this.calculateTotal();
  }
  editRecord(EntryID) {
    try {
      this.containerList = [];
      this.setup.GetAllRecordInfo(this.EntryID, 'dbo.sp_GetPoPackingRecord')
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets[1].length > 0) {
          this.containerList = data.ResultSets[1];
        }
        if (data.ResultSets[0].length > 0) {
          this.fillEditRecordData(data.ResultSets[0]);
        }
        this.calculateTotalContainerTotal();
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  getAccountList() {
    try {
      this.setup.GetAccountList()
        .subscribe({
          next(list) {
            fetchData(list);
          }
        });
      const fetchData = (data: any) => {
        this.spinner.hide();
        if (data.ResultSets.length > 0) {
          this.SupplierList = data.ResultSets[0];
        }
      };
    } catch (error) {
      this.spinner.hide();
      console.log(error);
      this.toaster.error(error);
    }

  }
  fillEditRecordData(data) {
    data.forEach(element => {
      var keys = Object.keys(element);
      keys.forEach(e => {
        try {
          this.validationform.controls[e].setValue(element[e]);
        }
        catch (error) { }
      })
    }); this.editMode = true;
    this.selectedSupplierID = data[0].AccCode;
    this.ProductArray = [];
    for (let i = 0; i <= data.length - 1; i++) {
      const element = data[i];
      this.ProductArray.push(
        element
      )
    }
    this.calculateTotal();
  }
}
