<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="large" color="#fff" type="ball-circus" [fullScreen] = "true"></ngx-spinner>

<div class="container-fluid">
  <app-pagetitle title="User Account Mapping" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Purchase Order List</h4> -->
          <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">

            <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="UserId">User</label>
                    <ng-select formControlName="UserId" name="UserId" id="UserId"
               (change)="getRoleWiseMenulist()"    [items]="UserRoleList"  bindLabel="UserName" 
                bindValue="UserId" 
                    [(ngModel)]="UserRoleSelected"></ng-select>
                      </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <br>
                    <!-- <button class="btn btn-rounded  btn-warning " type="submit">
                      <i class="fa fa-search"></i>&nbsp; Search 
                          </button>-->
                        </div>
                      </div>
  
              </div>
  
            </form>          
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <button class="btn  btn-primary" 
              (click)="saveMenuList()" type="button"> 
              Save</button>

            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div class="row navbar">
                <div class="col-6 d-flex justify-content-left">
                </div>
                <div class="col-6 d-flex justify-content-end">
                    <div class="row">
                        <div>
                            <input class="form-control" [(ngModel)]="searchString" placeholder="Search" id="searchString"
                                name="searchString" (keyup)="searchExistingRecords($event)" />
                        </div>
                    </div>
                </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table #table class="table table-hover m-t-20 table-bordered">
                <thead class="bg-info text-white">
                    <tr class="">
                        <th>Account Name</th>
                        <th>Account Code</th>
                        <th>Account Type</th>
                        <th>Select 
                         &nbsp; <input type="checkbox" id="Chk_SelectAll" [(ngModel)]="Chk_SelectAll" (change)="changeSelectAll()" />
                        </th>
                        <!-- <th>Add</th>
                        <th>Modify</th>
                        <th>Print</th> -->
                    </tr>
                </thead>
                <tbody class="border border-info">
                    <tr *ngFor="let item of work;let i=index;">
                      <td>{{item.AccName}}</td>
                      <td>{{item.AccCode}}</td>
                      <td>{{item.AccType}}</td>
                      <td style="text-align: center">
                       <input type="checkbox" id="select_{i}}" [(ngModel)]="item.select" />
                      </td>
                        <!-- <td style="text-align: center">
                        <input type="checkbox"  id="add_{i}}"  [(ngModel)]="item.AddAccess"/>
                        </td>
                          <td style="text-align: center">
                            <input type="checkbox"  id="modify_{i}}"  [(ngModel)]="item.ModifyAccess"/>
                          </td>
                          <td style="text-align: center">
                            <input type="checkbox"  id="print_{i}}"  [(ngModel)]="item.PrintAccess"/>
                      </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        <ngb-pagination [collectionSize]="pager.totalItems " [pageSize]="pager.pageSize "
        [(page)]="pager.currentPage " [maxSize]="20" [rotate]="true "
        (pageChange)="searchString != '' ?  setPageSearch(pager.currentPage)  : setPage(pager.currentPage) "
        [boundaryLinks]="true ">
    </ngb-pagination>


        </div>
      </div>
    </div>
  </div>
</div>
 <ng-template #PrintModal let-modal>
    <div class="modal-header">
      <h5 class="modal-title mt-0">Packing List</h5>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
    </div>
    <div class="modal-body">
      <!-- <iframe width="100%" height="100%"></iframe> -->
      <ngx-extended-pdf-viewer [src]="pdfdoc" [useBrowserLocale]="true" ></ngx-extended-pdf-viewer>

    </div>
  </ng-template>