<div class="container-fluid">
  <app-pagetitle title="Rate List" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>


  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Purchase Order List</h4> -->
          <div *ngIf="RoleName=='Admin'">
          <label for="TypeTrackingNumber">Upload File</label>
          <input type="file" name="fileExcel" class="form-control"  [(ngModel)]="fileUploadInputs"
                  (change)="uploadExcel($event,addmstModal)" #fileUploadInput />
          </div>

          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <!-- <button type="button" class="btn btn-primary tblebtn w-md" (click)="resetAndopenModal(addmstModal)"><i
                  class="mdi mdi-plus"></i>Add New</button> -->

            </div>
            <div class="col-sm-12 col-md-6">
              <div class="row navbar">
                <div class="col-6 d-flex justify-content-left">
                </div>
                <div class="col-6 d-flex justify-content-end">
                  <div class="row">
                    <div>
                      <input class="form-control" [(ngModel)]="searchString" placeholder="Search" id="searchString"
                        name="searchString" (keyup)="searchExistingRecords($event)" />
                    </div>
                    <!-- <div>
                            <button class="btn btn-primary" (click)="searchDBRecords()"><i
                                    class="ti-search m-r-5 m-l-5"></i></button>
                        </div> -->
                  </div>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive">
              <table #table class="table table-hover m-t-20 table-bordered">
                <thead class="bg-info text-white">
                  <tr class="">
                    <th>File Name</th>
            
                    <th>Remarks</th>
                    <th>Created By</th>
                    <th>Created Date</th>
                    <th style="width: 250px !important; ">Action</th>
                  </tr>
                </thead>
                <tbody class="border border-info">
                  <tr *ngFor="let item of work">

                    <td>{{item.FileName}}</td>
          
                    <td>{{item.Remarks}}</td>
                    <td>{{item.UserName}}</td>
                    <td>{{item.CreatedDate}}</td>
                    <td>
                      
                      <button type="button" class="btn btn-outline-info tblebtn"
                      (click)="downloadOriginalExcel(item)">
                      <i class="ti-download "></i> Download Uploaded File</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ngb-pagination [collectionSize]="pager.totalItems " [pageSize]="pager.pageSize "
              [(page)]="pager.currentPage " [maxSize]="10 " [rotate]="true " (pageChange)="setPage(pager.currentPage) "
              [boundaryLinks]="true ">
            </ngb-pagination>

          </div>
        </div>
      </div>
    </div>
  </div>



    <!-- Modal -->
    <ng-template #addmstModal let-modal> 
      <div class="modal-header">
        <h5 class="modal-title mt-0">Rate List Upload</h5>
        <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-hidden="true">×</button>
      </div>
      <div class="modal-body">
        <form class="needs-validation" (ngSubmit)="validSubmit()" [formGroup]="validationform">
          <div class="row">
            <div class="col-lg-12">
              <div class="card o">
                <div class="card-body o">
                  <div class="row">
                    <div class="col-md-8">
                      <div class="form-group">
                        <label for="Remarks">Remarks</label>
                        <textarea class="form-control" formControlName="Remarks" id="Remarks"
                          [ngClass]="{'is-invalid': submit && form.Remarks.errors}">
                        </textarea>
                        <div *ngIf="submit && form.Remarks.errors" class="invalid-feedback">
                          Please provide Remarks
                        </div>
                      </div>
                    </div>
                   
                   
                  </div>
                  <div class="row">
                     <div class="col-12">
                    </div>
                  </div>   



         
                <div class="row">
                  <div class="col-md-7">
                  </div>
                  <div class="col-md-5">
                    <button type="submit" class="btn btn-primary tblebtn w-md">
                      Save
                    </button>&nbsp;
                    <button type="button" (click)="modal.dismiss('Cross click')" class="btn btn-secondary tblebtn w-md">
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </form>
      </div>
    </ng-template>
  </div>